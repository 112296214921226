import React, { useState } from 'react';
import dpayIcon from './img/dpayIcon.svg';
import dstepintoIcon from './img/dstepintoIcon.svg';
import dstepOverIcon from './img/dstepOverIcon.svg';
import dstepOutIcon from './img/dstepOutIcon.svg';
import dreturnIcon from './img/dretunIcon.svg';
import dstopIcon from './img/DstopIcon.svg';
import dtogglebrackpointIcon from './img/dtogglebrackpointIcon.svg';
import dvariablesIcon from './img/dvariablesIcon.svg';
import dv1Icon from './img/dv1Icon.svg';
import dv2Icon from './img/dv2Icon.svg';
import dvboxIcon from './img/dvboxIcon.svg';
import dvgtIcon from './img/dvgtIcon.svg';

// close button action
const Debugger = () => {

    const [isDropdownOpen1, setIsDropdownOpen1] = useState(true);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(true);
    const [isDropdownOpen3, setIsDropdownOpen3] = useState(true);


    const toggleDropdown1 = () => {
        setIsDropdownOpen1(!isDropdownOpen1);
      };

      const toggleDropdown2 = () => {
        setIsDropdownOpen2(!isDropdownOpen2);
      };

      const toggleDropdown3 = () => {
        setIsDropdownOpen3(!isDropdownOpen3);
      };

    const [isVisible, setIsVisible] = useState(true); // State to manage visibility

    const handleClose = () => {
        setIsVisible(false); // Function to hide the debugger component
    };

    if (!isVisible) {
        return null; // Render nothing if isVisible is false
    }

    return (
        <div className="python-debugger" style={{paddingLeft:'10px', width:'250px' }}>
            <div className="python-debugger_debugger"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'hsl(0deg 0% 96.86%)',
                    padding: '7px 7px 7px 7px',
                    boxShadow:'rgb(118, 118, 118) 0px -1px 4px'
                }}
            >
                <div className="python-title"
                    style={{
                        color: 'black',
                        borderTopLeftRadius:'5%',
                        borderTopRightRadius:'5%',
                    }}
                >
                    Debugger (Beta)
                </div>
                {/* close button 
                <div className="button"
                    title="Close"
                    onClick={handleClose}
                    style={{
                        float: 'right'
                    }}
                >
                    <img src={dcancelIcon} width="21" height="21" alt="Close" />
                </div> */}
            </div>

            {/* Additional content of the debugger component */}

            <div className="debugger-controls"
                style={{
                    display: 'flex',
                    boxShadow:'rgb(118 118 118) 0px 4px 4px',
                    backgroundColor: 'hsl(0deg 0% 100%)',
                    padding: '7px 7px 7px 7px',
                    justifyContent: 'space-between',
                }}
            >
                {/* continue button */}
                <div className="button" title="Continue" disabled>
                    <img src={dpayIcon} style={{height:'25px' , width:'25px'}} alt="Continue" />
                </div>
                <div className="button" title="Step Over" disabled>
                    <img src={dstepOverIcon} style={{height:'25px' , width:'25px'}} alt="Step Over" />
                </div>
                <div className="button" title="Step Into" disabled>
                    <img src={dstepintoIcon} style={{height:'25px' , width:'25px'}} alt="Step Into" />
                </div>
                <div className="button" title="Step Out" disabled>
                    <img src={dstepOutIcon} style={{height:'25px' , width:'25px'}} alt="Step Out" />
                </div>
                <div className="button" title="Toggle Breakpoints">
                    <img src={dtogglebrackpointIcon} style={{height:'25px' , width:'25px'}} alt="Toggle Breakpoints" />
                </div>
                <div className="button" title="Rerun">
                    <img src={dreturnIcon} style={{height:'25px' , width:'25px'}} alt="Rerun" />
                </div>
                <div className="button" title="Stop" disabled>
                    <img src={dstopIcon} style={{height:'25px' , width:'25px'}} alt="Stop" />
                </div>
            </div>


            <div className="collapsible-view_container"
                style={{

                }}
            >
                <div className="collapsible-view_header"
                 onClick={toggleDropdown1}
                    style={{
                        backgroundColor: 'hsl(0deg 0% 96.86%)',
                        padding: '7px 7px 7px 7px',
                        display: 'flex',
                       boxShadow:'rgb(118 118 118) 0px 4px 4px'
                    }}
                >
                    <img src={dvariablesIcon} width="15px" height="15px" alt="Variables" />
                    <div style={{ marginLeft: '5px' }}>Variables</div>
                </div>
                {isDropdownOpen1 && (
                <div className="variables-view_variables"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow:'rgb(118 118 118) 0px 4px 4px',
                        height: '350px',
                        backgroundColor: '#e7f0ff',
                    }}
                >
                    <div className="variable_variable_3yaV-" style={{ marginLeft: '10px' }}>
                        <img src={dv1Icon} width="16" height="16" alt="Variable" />
                        {/* <span>_name_</span>
                <span> = </span>
                <span className="variable_variable-type_1ptuP">str:</span>
                <span className="variable_variable-value_2ljlx">"_main_"</span> */}
                    </div>
                    <div className="variable_variable" style={{ marginLeft: '10px' }}>
                        {/* <div className="variable_variable" style={{ marginLeft: '10px' }}> */}
                        <img src={dv2Icon} width="16" height="16" alt="Variable" />
                        {/* <span>_file_</span>
                <span> = </span>
                <span className="variable_variable-type">str:</span>
                <span className="variable_variable-value">"/ppy/Stage.py"</span> */}
                        {/* </div> */}
                    </div>
                    <div className="variable_variable_3yaV-" style={{ marginLeft: '0px' }}>
                        <img src={dvgtIcon} width="10" alt="Variable" />
                        {/* <div className="variable_variable_3yaV-"> */}
                        <img src={dvboxIcon} width="16" height="16" alt="Variable" />
                        {/* <span>sprite</span>
                <span> = </span>
                <span className="variable_variable-type_1ptuP">Sprite:</span>
                <span className="variable_variable-value_2ljlx">&lt;_main_.Sprite object at 0xf9fdf0&gt;</span> */}
                        {/* </div> */}
                    </div>
                </div>
                )}
            </div>


            <div className="collapsible-view_container"
                style={{

                }}
            >
                <div className="collapsible-view_header"
                onClick={toggleDropdown2}
                    style={{
                        backgroundColor: 'hsl(0deg 0% 96.86%)',
                        padding: '7px 7px 7px 7px',
                        display: 'flex',
                        boxShadow:'rgb(118 118 118) 0px 4px 4px'
                    }}
                >
                    <img src={dvariablesIcon} width="15" height="15px" alt="Call Stack" />
                    <div style={{ marginLeft: '5px' }}>Call Stack</div>
                </div>
                {isDropdownOpen2 && (
                <div className="call-stack_stack-frames-list"
                    style={{
                        height: '150px',
                        backgroundColor: '#e7f0ff',
                        boxShadow:'rgb(118 118 118) 0px 4px 4px'
                    }}
                >
                    {/* <span>&lt;module&gt;</span> */}
                    <div className="stack-frame_info">
                        {/* <span>Stage.py</span>
                <span>1</span> */}
                    </div>
                </div>
                )}
            </div>


            <div className="collapsible-view_container" style={{}}>
                <div className="collapsible-view_header"
                onClick={toggleDropdown3}
                    style={{
                        backgroundColor: 'hsl(0deg 0% 96.86%)',
                        padding: '7px 7px 7px 7px',
                        display: 'flex',
                       boxShadow:'rgb(118 118 118) 0px 4px 4px'
                    }}>
                    <img src={dvariablesIcon} width="15px" height="15px" alt="Breakpoints" />
                    <div style={{ marginLeft: '5px' }}>Breakpoints</div>
                </div>
                {isDropdownOpen3 && (
                <div className="breakpoints_breakpoints-container"
                    style={{
                        backgroundColor: '#e7f0ff',
                        padding: '7px 7px 7px 7px',
                        display: 'flex',
                        height: '170px',
                        borderBottomLeftRadius:'5%',
                        borderBottomRightRadius:'5%',
                        boxShadow:'rgb(118 118 118) 0px 4px 4px' 
                    }}
                >
                    <div className="checkbox_checkmark-container">
                        <input type="checkbox"  style={{ height: '15px', width: '15px' }} />
                    </div>
                    <div className="breakpoints_selected-text">0 Enabled</div>
                </div>
                )}
            </div>


        </div>
    );
};

export default Debugger;
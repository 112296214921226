// import {FormattedMessage} from 'react-intl';
// import PropTypes from 'prop-types';
// import React, {useState} from 'react';
// import classNames from 'classnames';

// import Box from '../box/box.jsx';
// import PeripheralTile from './peripheral-tile.jsx';
// import Dots from './dots.jsx';

// import radarIcon from './icons/searching.png';
// import refreshIcon from './icons/refresh.svg';

// import {handleBluetoothClick} from '../menu-bar/serialHelpers.js';

// import styles from './connection-modal.css';

// const ScanningStep = props => {
//     const [bluetoothDevices, setBluetoothDevices] = useState([]);
//     const [scanning, setScanning] = useState(false);
//     const [showBluetoothDevices, setShowBluetoothDevices] = useState(true); 
    
//     const handleBluetoothDevices = async () => {
//         setShowBluetoothDevices(prevState => !prevState);
//         setScanning(true); 
//         try {
//             const device = await handleBluetoothClick(); 
//             if (device) {
//                 setBluetoothDevices((prev) => {
//                     if (!prev.find((d) => d.peripheralId === device.peripheralId)) {
//                         return [...prev, device];
//                     }
//                     return prev;
//                 });
//             }
//         } catch (error) {
//             console.error('Error scanning for Bluetooth devices:', error);
//         }
//         setScanning(false); 
//     };

//     return (
//         <Box className={styles.body}>
//             {props.isSerialport ? (
//                 <Box className={classNames(styles.bodyHeadArea)}>
//                     <div className={styles.listAll}>
//                         <FormattedMessage
//                             defaultMessage="Show all connectable devices"
//                             description="Button in prompt for show all connectable devices"
//                             id="gui.connection.scanning.listAll"
//                         />
//                     </div>
//                     <div className={styles.checkBox}>
//                         <input
//                             type="checkbox"
//                             name="hexform"
//                             checked={props.isListAll}
//                             onClick={props.onClickListAll}
//                         />
//                     </div>
//                 </Box>
//             ) : null}

//             <Box className={styles.activityArea}>
//                 {props.scanning ? (
                    
//                     props.peripheralList.length === 0 ?  (
//                         <div className={styles.activityAreaInfo}>
//                             <div className={styles.centeredRow}>
//                                 <img
//                                     className={classNames(styles.radarSmall, styles.radarSpin)}
//                                     src={radarIcon}
//                                 />
//                                 <FormattedMessage
//                                     defaultMessage="Looking for devices"
//                                     description="Text shown while scanning for devices"
//                                     id="gui.connection.scanning.lookingforperipherals"
//                                 />
//                             </div>
//                         </div>
//                     ) : (
//                         <div className={styles.peripheralTilePane}>
//                             {props.peripheralList.map(peripheral => (
//                                 <PeripheralTile
//                                     key={peripheral.peripheralId}
//                                     name={peripheral.name}
//                                     peripheralId={peripheral.peripheralId}
//                                     rssi={peripheral.rssi}
//                                     isSerialport={props.isSerialport}
//                                     onConnecting={props.onConnecting}
//                                     receivedData={peripheral.receivedData}
//                                 />
//                             ))}
//                             {bluetoothDevices.map(device => (
//                                 <PeripheralTile
//                                     key={device.peripheralId}
//                                     name={device.name}
//                                     peripheralId={device.peripheralId}
//                                     rssi={device.rssi}
//                                     isSerialport={false}
//                                     onConnecting={props.onConnecting}
//                                     receivedData={device.receivedData}
//                                 />
//                             ))}
//                         </div>
//                     )
//                 ) : (
//                     <Box className={styles.instructions}>
//                         <FormattedMessage
//                             defaultMessage="No devices found"
//                             description="Text shown when no devices could be found"
//                             id="gui.connection.scanning.noPeripheralsFound"
//                         />
//                     </Box>
//                 )}
//             </Box>

//             {/* {showBluetoothDevices && (
//                 <Box className={styles.activityArea}>
//                     {bluetoothDevices.length === 0 ? (
//                         <div className={styles.activityAreaInfo}>
//                             <FormattedMessage
//                                 defaultMessage="No Bluetooth devices found"
//                                 description="Text shown when no Bluetooth devices could be found"
//                                 id="gui.connection.scanning.noBluetoothPeripherals"
//                             />
//                         </div>
//                     ) : (
//                         <div className={styles.peripheralTilePane}>
//                             {bluetoothDevices.map(device => (
//                                 <PeripheralTile
//                                     key={device.peripheralId}
//                                     name={device.name}
//                                     peripheralId={device.peripheralId}
//                                     rssi={device.rssi}
//                                     isSerialport={false}
//                                     onConnecting={props.onConnecting}
//                                     receivedData={device.receivedData}
//                                 />
//                             ))}
//                         </div>
//                     )}
//                 </Box> */}
//             {/* )} */}

//             <Box className={styles.bottomArea}>
//                 <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
//                     <FormattedMessage
//                         defaultMessage="Select your device in the list above."
//                         description="Prompt for choosing a device to connect to"
//                         id="gui.connection.scanning.instructions"
//                     />
//                 </Box>
//                 {/* <Dots className={styles.bottomAreaItem} counter={1} total={3} /> */}
//                 <div style={{justifyContent: 'space-between', width: '50%', display: 'flex'}}>
//                     <button
//                         className={classNames(styles.bottomAreaItem, styles.connectionButton)}
//                         onClick={props.onRefresh}
//                     >
//                         <FormattedMessage
//                             defaultMessage="Refresh"
//                             description="Button in prompt for starting a search"
//                             id="gui.connection.search"
//                         />
//                         <img
//                             className={styles.buttonIconRight}
//                             src={refreshIcon}
//                         />
//                     </button>
//                     <button
//                         className={classNames(styles.bottomAreaItem, styles.connectionButton)}
//                         onClick={handleBluetoothDevices} 
//                     >
//                         <FormattedMessage
//                             defaultMessage="Bluetooth Devices"
//                             description="Button to  Bluetooth device list visibility"
//                             id="gui.connection.Bluetooth"
//                         />
//                         <img
//                             className={styles.buttonIconRight}
//                             src={refreshIcon}
//                         />
//                     </button>
//                 </div>
//             </Box>
//         </Box>
//     );
// };

// ScanningStep.propTypes = {
//     connectionSmallIconURL: PropTypes.string,
//     isListAll: PropTypes.bool.isRequired,
//     isSerialport: PropTypes.bool,
//     onClickListAll: PropTypes.func.isRequired,
//     onConnecting: PropTypes.func,
//     onRefresh: PropTypes.func,
//     peripheralList: PropTypes.arrayOf(PropTypes.shape({
//         name: PropTypes.string,
//         rssi: PropTypes.number,
//         peripheralId: PropTypes.string
//     })),
//     scanning: PropTypes.bool.isRequired
// };

// ScanningStep.defaultProps = {
//     peripheralList: [],
//     scanning: true
// };

// export default ScanningStep;



import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Box from '../box/box.jsx';
import PeripheralTile from './peripheral-tile.jsx';
import Dots from './dots.jsx';

import radarIcon from './icons/searching.png';
import refreshIcon from './icons/refresh.svg';

import styles from './connection-modal.css';

const ScanningStep = props => (
    <Box className={styles.body}>
        {props.isSerialport ? (
            <Box className={classNames(styles.bodyHeadArea)}>
                <div className={styles.listAll}>
                    <FormattedMessage
                        defaultMessage="Show all connectable devices"
                        description="Button in prompt for show all connectable devices"
                        id="gui.connection.scanning.listAll"
                    />
                </div>
                <div className={styles.checkBox}>
                    <input
                        type="checkbox"
                        name="hexform"
                        checked={props.isListAll}
                        onClick={props.onClickListAll}
                    />
                </div>
            </Box>
        ) : null}
        <Box className={styles.activityArea}>
            {props.scanning ? (
                props.peripheralList.length === 0 ? (
                    <div className={styles.activityAreaInfo}>
                        <div className={styles.centeredRow}>
                            <img
                                className={classNames(styles.radarSmall, styles.radarSpin)}
                                src={radarIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Looking for devices"
                                description="Text shown while scanning for devices"
                                id="gui.connection.scanning.lookingforperipherals"
                            />
                        </div>
                    </div>
                ) : (
                    <div className={styles.peripheralTilePane}>
                        {props.peripheralList.map(peripheral =>
                            (<PeripheralTile
                                connectionSmallIconURL={props.connectionSmallIconURL}
                                key={peripheral.peripheralId}
                                name={peripheral.name}
                                peripheralId={peripheral.peripheralId}
                                rssi={peripheral.rssi}
                                isSerialport={props.isSerialport}
                                onConnecting={props.onConnecting}
                            />)
                        )}
                    </div>
                )
            ) : (
                <Box className={styles.instructions}>
                    <FormattedMessage
                        defaultMessage="No devices found"
                        description="Text shown when no devices could be found"
                        id="gui.connection.scanning.noPeripheralsFound"
                    />
                </Box>
            )}
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                <FormattedMessage
                    defaultMessage="Select your device in the list above."
                    description="Prompt for choosing a device to connect to"
                    id="gui.connection.scanning.instructions"
                />
            </Box>
            <Dots
                className={styles.bottomAreaItem}
                counter={0}
                total={3}
            />
            <button
                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                onClick={props.onRefresh}
            >
                <FormattedMessage
                    defaultMessage="Refresh"
                    description="Button in prompt for starting a search"
                    id="gui.connection.search"
                />
                <img
                    className={styles.buttonIconRight}
                    src={refreshIcon}
                />
            </button>
        </Box>
    </Box>
);

ScanningStep.propTypes = {
    connectionSmallIconURL: PropTypes.string,
    isListAll: PropTypes.bool.isRequired,
    isSerialport: PropTypes.bool,
    onClickListAll: PropTypes.func.isRequired,
    onConnecting: PropTypes.func,
    onRefresh: PropTypes.func,
    peripheralList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        rssi: PropTypes.number,
        peripheralId: PropTypes.string
    })),
    scanning: PropTypes.bool.isRequired
};

ScanningStep.defaultProps = {
    peripheralList: [],
    scanning: true
};

export default ScanningStep;

import React, { useState , useEffect , useRef } from 'react';
import Threedots from './img/threedots.png';

  const projectDropdown = ({ onSelectOption , copiedFile, cutFile   }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    if (item === 'Paste' && !copiedFile && !cutFile) {
      // Do nothing if no file is copied or cut
      return;
    } else {
      onSelectOption(item);
    }
    setIsOpen(false);
  };

  const handleMouseEnter = (index) => {
    setHoveredItemIndex(index);
};

const handleMouseLeave = () => {
    setHoveredItemIndex(null);
};

const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setIsOpen(false);
  }
};

useEffect(() => {
  if (isOpen) {
    document.addEventListener('mousedown', handleClickOutside);
  } else {
    document.removeEventListener('mousedown', handleClickOutside);
  }

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [isOpen]);

  const items = ['Paste', 'Create a empty python file', 'Create a empty text file'];

  const fileImg = {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    cursor: 'pointer',
  }

  const dropdown = {
    display: 'inline-block' , 
    position:'relative',

  }

  const dropdownMenu = {
    display:'block' , 
    position:'absolute', 
    backgroundColor:'#e8f0ff', 
    marginTop:'5px', 
    borderRadius:'4px', 
    zIndex:'1', 
    width:'180px',
    padding:'2px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    left: '-150px',
    border:'1px solid #00000026'
  }

  const dropdownItem = {
    padding :'10px',
    cursor :'pointer',
    fontSize:'small',
    color: '#000000'
  }

  const dropdownItemHover = {
    ...dropdownItem,
    backgroundColor: '#2b256d',
    color: 'white',
  };

  const disabledDropdownItem = {
    ...dropdownItem,
    color: '#828798',
    cursor: 'not-allowed',
  };

  const isPasteDisabled = !copiedFile && !cutFile;

  return (
    <div className="dropdown" style={dropdown} ref={dropdownRef}>
      <img src={Threedots} style={fileImg} onClick={toggleDropdown} alt="Options"/>
      {isOpen && (
        <div className="dropdownMenu" style={dropdownMenu}>
          {items.map((item, index) => (
            <div key={index} onClick={() => handleItemClick(item)} className="dropdownItem" 
            style={ item === 'Paste' && isPasteDisabled
              ? disabledDropdownItem
              : hoveredItemIndex === index
              ? dropdownItemHover
              : dropdownItem}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default projectDropdown;

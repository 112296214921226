import React, { useState, useEffect, useRef } from 'react';

const DropdownRight = ({ position, file, onClose, onSelectOption , className  }) => {
    // const [isOpen, setIsOpen] = useState(false);
    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
    // const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const dropdownRef = useRef(null);

    const handleOptionClick  = (option) => {
        onSelectOption(option , file);
        onClose();
    };

    const handleMouseEnter = (index) => {
        setHoveredItemIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredItemIndex(null);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const items = ['Cut', 'Copy', 'Rename', 'Export', 'Delete'];

    const dropdownMenu = {
        display: 'block',
        position: 'absolute',
        top: `position.y - 40px`,
        // left: '130px',
        left: position.x,
        backgroundColor: '#e8f0ff',
        marginTop: '-10px',
        borderRadius: '4px',
        zIndex: '1000',
        width: '135px',
        padding: '2px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        border: '1px solid #00000026',
    };

    const dropdownItem = {
        padding: '10px',
        cursor: 'pointer',
        fontSize: 'small',
    };

    const dropdownItemHover = {
        ...dropdownItem,
        backgroundColor: '#2b256d',
        color: 'white',
    };

    const dropdownItemDeleteHover = {
        ...dropdownItemHover,
        borderTop: '1px solid #a4a8ae',  // Add a border-top for the delete option
        backgroundColor: '#ff0000',      // Change the background color on hover
        color: 'white',                  // Change the text color
    };

    return (
        <div className={className} style={dropdownMenu} ref={dropdownRef}>
                    {items.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                handleOptionClick(item);
                                // onSelectOption(item, file);
                                // onClose();
                            }}  
                            className="dropdownItem"
                            style={hoveredItemIndex === index ? (item === 'Delete' ? dropdownItemDeleteHover : dropdownItemHover) : dropdownItem}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {item}
                        </div>
                    ))}
                </div>
    );
};

export default DropdownRight;

import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import QuestionComponent from '../components/question/question.jsx';
// import Scratch3SensingBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_sensing.js';
// import VM from 'blockzie-vm'


class Question extends React.Component {
    constructor (props) {
        super(props);
        // this.sensingblocks = new Scratch3SensingBlocks(props.vm);
        bindAll(this, [
            'handleChange',
            'handleKeyPress',
            'handleSubmit'
        ]);
        this.state = {
            answer: '' 
        };
    }
    handleChange (e) {
        this.setState({answer: e.target.value});
    }
    handleKeyPress (event) {
        if (event.key === 'Enter') this.handleSubmit();
    }
    handleSubmit () {
        this.props.onQuestionAnswered(this.state.answer);
    }
    render () {
        return (
            <QuestionComponent
                answer={this.state.answer}
                question={this.props.question}
                onChange={this.handleChange}
                onClick={this.handleSubmit}
                onKeyPress={this.handleKeyPress}
            />
        );
    }
}

Question.propTypes = {
    onQuestionAnswered: PropTypes.func.isRequired,
    question: PropTypes.string,
    // vm: PropTypes.instanceOf(VM).isRequired

};

export default Question;

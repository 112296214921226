// // import { find } from "react-intl/locale-data";
// const spriteDelay = ms => new Promise(res => setTimeout(res, ms));

// export const handleSpriteCommands = async (results) => {
//     const codeResult = results.result;
//     console.log(codeResult);
//     const [category, spriteName2, command1, args1, args2] = codeResult.split('.');
//     console.log(category);
//     console.log(spriteName2);
//     console.log(command1);
//     console.log(args1);
//     console.log(args2)

//     const command = results.command;
//     console.log("Lineeeeeeeeeeeeeeee")
//     console.log(command);
//     const spriteId = results.spriteId;
//     console.log(spriteId);
//     const spriteName = results.spriteName;
//     console.log(spriteName);

//     const lines = command.split('\n');
//     let spriteCreated = false;
//     const lineCounts = new Map();
//     let answerStore = null;

//     for (const line of lines) {
//         const trimmedLine = line.trim();
//         if (trimmedLine && !trimmedLine.startsWith('#')) { // Ignore empty lines and comments
//             lineCounts.set(trimmedLine, (lineCounts.get(trimmedLine) || 0) + 1);
//         }
//     }

//     const executeLine = async (line) => {
//         //debugger
//         line = line.trim();
//         console.log(line)
//         if (line.startsWith('#')) {
//             console.log('Your code has comments, so not showing output');
//             return;
//         }

//         if (line.startsWith('sprite.')) {
//             const sprite = line.split(".")
//             console.log(sprite)
//             if (sprite[1]) {
//                 const spriteNameInput = sprite[1]; // Extracted sprite name
//                 const index = spriteName.indexOf(spriteNameInput); // Check if spriteName exists

//                 if (index !== -1) {
//                     globalThis.targetSpriteId = spriteId[index]; // Get corresponding spriteId
//                     // debugger
//                     const method = sprite[2];
//                     const args = [sprite[3]];
//                     console.log(args);
//                     // spriteDelay(5000)

//                     switch (method) {
//                         case 'move':
//                             // debugger
//                             const X = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteMove', { detail: X }));
//                             console.log(X);

//                             // const spriteDelay = ms => new Promise(res => setTimeout(res, ms));

//                             // (async () => {
//                             //     await spriteDelay(1000);
//                             //     window.dispatchEvent(new CustomEvent('spriteMove', { detail: X }));
//                             //     console.log(X);
//                             // })();
//                             break;
//                         case 'setsize':
//                             const size = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetsize', { detail: size }));
//                             break;
//                         case 'changesize':
//                             const newSize = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangesize', { detail: newSize }));
//                             break;
//                         case 'gotoxy':
//                             const x = parseInt(args[0]);
//                             const y = args2;
//                             window.dispatchEvent(new CustomEvent('spriteGotoxy', { detail: { x, y } }));
//                             break;
//                         case 'goto':
//                             window.dispatchEvent(new CustomEvent('spriteGoto'));
//                             break;
//                         case 'setx':
//                             const newX = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetx', { detail: newX }));
//                             break;
//                         case 'sety':
//                             const newY = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSety', { detail: newY }));
//                             break;
//                         case 'changex':
//                             const deltaX = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangex', { detail: deltaX }));
//                             break;
//                         case 'changey':
//                             const deltaY = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangey', { detail: deltaY }));
//                             break;
//                         case 'setdirection':
//                             const angle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetdirection', { detail: angle }));
//                             break;
//                         case 'right':
//                             const rightAngle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteRight', { detail: rightAngle }));
//                             break;
//                         case 'left':
//                             const leftAngle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteLeft', { detail: leftAngle }));
//                             break;
//                         case 'show':
//                             window.dispatchEvent(new CustomEvent('spriteShow'));
//                             break;
//                         case 'hide':
//                             window.dispatchEvent(new CustomEvent('spriteHide'));
//                             break;
//                         case 'say':
//                             const event = new CustomEvent('spriteSay', { detail: args1 });
//                             dispatchEvent(event);
//                             break;
//                         case 'sayforsecs':
//                             let sayforsecsSeconds = args2;
//                             const sayforsecsEvent = new CustomEvent('spriteSayForSecs', { detail: { message: args1, seconds: sayforsecsSeconds } });
//                             window.dispatchEvent(sayforsecsEvent);
//                             break;
//                         case 'think':
//                             const thinkEvent = new CustomEvent('spriteThink', { detail: args1 });
//                             window.dispatchEvent(thinkEvent);
//                             break;
//                         case 'thinkforsecs':
//                             let thinkforsecsSeconds = args2;
//                             const thinkforsecsEvent = new CustomEvent('spriteThinkForSecs', { detail: { message: args1, seconds: thinkforsecsSeconds } });
//                             window.dispatchEvent(thinkforsecsEvent);
//                             break;
//                         case 'input':
//                             const inputEvent = new CustomEvent('spriteInput', { detail: args1 });
//                             window.dispatchEvent(inputEvent);
//                             break;
//                         case 'answer':
//                             console.log(" answer ................................. ")
//                             window.dispatchEvent(new CustomEvent('spriteAnswer'));
//                             break;
//                         // case 'answer':
//                             // answerStore = "Simulated answer"; // Replace with actual answer logic
//                             // console.log(`Stored answer in sprite.answer: ${answerStore}`);
//                             // break;
//                         case 'switchcostume':
//                             let costumeName = args[0].trim();
//                             if (costumeName.startsWith("'") && costumeName.endsWith("'")) {
//                                 costumeName = costumeName.substring(1, costumeName.length - 1);
//                             } else if (costumeName.startsWith('"') && costumeName.endsWith('"')) {
//                                 costumeName = costumeName.substring(1, costumeName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSwitchCostume', { detail: costumeName }));
//                             break;
//                         case 'nextcostume':
//                             window.dispatchEvent(new CustomEvent('spriteNextCostume'));
//                             // const spriteDelay = ms => new Promise(res => setTimeout(res, ms));

//                             // (async () => {
//                             //     await spriteDelay(3000);
//                             //     window.dispatchEvent(new CustomEvent('spriteNextCostume'));
//                             // })();
//                             break;
//                         case 'switchbackdrop':
//                             let backdropName = args[0].trim();
//                             if (backdropName.startsWith("'") && backdropName.endsWith("'")) {
//                                 backdropName = backdropName.substring(1, backdropName.length - 1);
//                             } else if (backdropName.startsWith('"') && backdropName.endsWith('"')) {
//                                 backdropName = backdropName.substring(1, backdropName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSwitchBackdrop', { detail: backdropName }));
//                             break;
//                         case 'nextbackdrop':
//                             window.dispatchEvent(new CustomEvent('spriteNextBackdrop'));
//                             break;
//                         case 'iskeypresed':
//                             let keyName = args[0].trim();
//                             if (keyName.startsWith("'") && keyName.endsWith("'")) {
//                                 keyName = keyName.substring(1, keyName.length - 1);
//                             } else if (keyName.startsWith('"') && keyName.endsWith('"')) {
//                                 keyName = keyName.substring(1, keyName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteIsKeyPressed', { detail: keyName }));
//                             break;
//                         case 'setrotationstyle':
//                             let StyleName = args[0].trim();
//                             if (StyleName.startsWith("'") && StyleName.endsWith("'")) {
//                                 StyleName = StyleName.substring(1, StyleName.length - 1);
//                             } else if (StyleName.startsWith('"') && StyleName.endsWith('"')) {
//                                 StyleName = StyleName.substring(1, StyleName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSetRotationStyle', { detail: StyleName }));
//                             break;
//                         case 'camera':
//                             const cameraevent = new CustomEvent('spriteCamera', { detail: {value : args1 , number : args2} });
//                             dispatchEvent(cameraevent);
//                             break;
//                         // case 'transparency':
//                         //     const cameratransparency = new CustomEvent('cameraTransparency', { detail: {value : args1} });
//                         //     dispatchEvent(cameratransparency);
//                         //     break;
//                         case 'bounceonedge':
//                             window.dispatchEvent(new CustomEvent('spriteBounceOnEdge'));
//                             break;
//                         case 'X':
//                             window.dispatchEvent(new CustomEvent('spriteX'));
//                             break;
//                         case 'Y':
//                             window.dispatchEvent(new CustomEvent('spriteY'));
//                             break;
//                         case 'gotolayer':
//                             let position = args[0].trim();
//                             if (position.startsWith("'") && position.endsWith("'")) {
//                                 position = position.substring(1, position.length - 1);
//                             } else if (position.startsWith('"') && position.endsWith('"')) {
//                                 position = position.substring(1, position.length - 1);
//                             }
//                             const gotolayerEvent = new CustomEvent('spriteGoToLayer', { detail: { style: position } });
//                             window.dispatchEvent(gotolayerEvent);
//                             break;
//                         case 'changelayer':
//                             let layer_change = args[0].trim();
//                             let layer_number = args2;
//                             if (layer_change.startsWith("'") && layer_change.endsWith("'")) {
//                                 layer_change = layer_change.substring(1, layer_change.length - 1);
//                             } else if (layer_change.startsWith('"') && layer_change.endsWith('"')) {
//                                 layer_change = layer_change.substring(1, layer_change.length - 1);
//                             }
//                             const changelayerEvent = new CustomEvent('spriteChangeLayer', { detail: { style: layer_change, number: layer_number } });
//                             window.dispatchEvent(changelayerEvent);
//                             break;
//                         case 'changeeffect':
//                             let effect_name = args2;
//                             let effect_value = args1;
//                             if (effect_name.startsWith("'") && effect_name.endsWith("'")) {
//                                 effect_name = effect_name.substring(1, effect_name.length - 1);
//                             } else if (effect_name.startsWith('"') && effect_name.endsWith('"')) {
//                                 effect_name = effect_name.substring(1, effect_name.length - 1);
//                             }
//                             const changeeffectEvent = new CustomEvent('spriteChangeEffect', { detail: { name: effect_name, value: effect_value } });
//                             window.dispatchEvent(changeeffectEvent);
//                             break;
//                         case 'seteffect':
//                             let set_effect_name = args2;
//                             console.log(set_effect_name)
//                             let set_effect_value = args1;
//                             console.log(set_effect_value)
//                             if (set_effect_name.startsWith("'") && set_effect_name.endsWith("'")) {
//                                 set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
//                             } else if (set_effect_name.startsWith('"') && set_effect_name.endsWith('"')) {
//                                 set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
//                             }
//                             const seteffectEvent = new CustomEvent('spriteSetEffect', { detail: { name: set_effect_name, value: set_effect_value } });
//                             window.dispatchEvent(seteffectEvent);
//                             break;
//                         case 'cleareffects':
//                             const cleareffectEvent = new CustomEvent('spriteClearEffect');
//                             window.dispatchEvent(cleareffectEvent);
//                             break;
//                         default:
//                             console.error(`Unknown sprite method: ${method}`);
//                     }
//                 } else {
//                     console.error(`Sprite name '${spriteNameInput}' not found.`);
//                 }
//             }
//         }
//     };

//     const executeLines = async (lines, lineCounts) => {
//         const loopStack = []; // Stack to manage nested loops
//         const state = {}; // Object to hold variable states
//         let delay = 0;
//         let isLoopActive = false; // Flag to track if we are currently processing any loop
//         console.log('LoopStack : ', loopStack);
//         console.log('state : ', state);

//         // If not in a loop, execute the line normally
//         if (!isLoopActive) {
//             console.log('only command that time code')
//             if (loopStack.length === 0) {  // <-- Only execute this if there are no active loops
//                 let findLoopCondOperator = false
//                 let combineCode = ""
//                 for (const [line, count] of lineCounts.entries()) {
//                     for (let i = 0; i < count; i++) { // Execute each line as many times as it appears
//                         // await executeLine(line);
//                         setTimeout(async () => {
//                             await executeLine(line);
//                         }, 500);
                        
//                         // await spriteDelay(2000);
//                     }
//                 }
//             }
//         }
//     };
//     // Start execution 
//     await executeLines(lines, lineCounts);
// };










// import { find } from "react-intl/locale-data";
const spriteDelay = ms => new Promise(res => setTimeout(res, ms));

export const handleSpriteCommands = async (results) => {
    const codeResult = results.result;
    console.log(codeResult);
    const [category, spriteName2, command1, args1, args2] = codeResult.split('.');
    console.log(category);
    console.log(spriteName2);
    console.log(command1);
    console.log(args1);
    console.log(args2)

    const command = results.command;
    console.log("Lineeeeeeeeeeeeeeee")
    console.log(command);
    const spriteId = results.spriteId;
    console.log(spriteId);
    const spriteName = results.spriteName;
    console.log(spriteName);

    const lines = command.split('\n');
    let spriteCreated = false;
    const lineCounts = new Map();
    let answerStore = null;

    for (const line of lines) {
        const trimmedLine = line.trim();
        if (trimmedLine && !trimmedLine.startsWith('#')) { // Ignore empty lines and comments
            lineCounts.set(trimmedLine, (lineCounts.get(trimmedLine) || 0) + 1);
        }
    }

    const executeLine = async (line) => {
        //debugger
        line = line.trim();
        console.log(line)
        if (line.startsWith('#')) {
            console.log('Your code has comments, so not showing output');
            return;
        }

        // if (line.startsWith('camera')) {
        //     console.log('Processing camera command');
        //     const cameraEvent = new CustomEvent('spriteCamera', { detail: { value: args1, number: args2 } });
        //     dispatchEvent(cameraEvent);
        // }

        if (line.startsWith('camera.')) {
            const cameraMethod = line.split(".")
            console.log(cameraMethod)
            
                    // debugger
                    switch (cameraMethod[1]) {
                        case 'on':
                            const number = cameraMethod[2];
                            console.log("in a on command number : " , number)
                            window.dispatchEvent(new CustomEvent('CameraOn', { detail: {number: Number(number)} }));
                            console.log(number);
                            break;
                        case 'off':
                            window.dispatchEvent(new CustomEvent('CameraOff'));
                            break;
                        default:
                            console.error(`Unknown camera method: ${cameraMethod}`);
                    }
                    return;
        }

        if (line.startsWith('sprite.')) {
            const sprite = line.split(".")
            console.log(sprite)
            if (sprite[1]) {
                const spriteNameInput = sprite[1]; // Extracted sprite name
                const index = spriteName.indexOf(spriteNameInput); // Check if spriteName exists

                if (index !== -1) {
                    globalThis.targetSpriteId = spriteId[index]; // Get corresponding spriteId
                    // debugger
                    const method = sprite[2];
                    const args = [sprite[3]];
                    console.log(args);
                    // spriteDelay(5000)

                    switch (method) {
                        case 'move':
                            // debugger
                            const X = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteMove', { detail: X }));
                            console.log(X);

                            // const spriteDelay = ms => new Promise(res => setTimeout(res, ms));

                            // (async () => {
                            //     await spriteDelay(1000);
                            //     window.dispatchEvent(new CustomEvent('spriteMove', { detail: X }));
                            //     console.log(X);
                            // })();
                            break;
                        case 'setsize':
                            const size = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSetsize', { detail: size }));
                            break;
                        case 'changesize':
                            const newSize = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteChangesize', { detail: newSize }));
                            break;
                        case 'gotoxy':
                            const x = parseInt(args[0]);
                            const y = args2;
                            window.dispatchEvent(new CustomEvent('spriteGotoxy', { detail: { x, y } }));
                            break;
                        case 'goto':
                            window.dispatchEvent(new CustomEvent('spriteGoto'));
                            break;
                        case 'setx':
                            const newX = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSetx', { detail: newX }));
                            break;
                        case 'sety':
                            const newY = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSety', { detail: newY }));
                            break;
                        case 'changex':
                            const deltaX = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteChangex', { detail: deltaX }));
                            break;
                        case 'changey':
                            const deltaY = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteChangey', { detail: deltaY }));
                            break;
                        case 'setdirection':
                            const angle = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteSetdirection', { detail: angle }));
                            break;
                        case 'right':
                            const rightAngle = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteRight', { detail: rightAngle }));
                            break;
                        case 'left':
                            const leftAngle = parseInt(args[0]);
                            window.dispatchEvent(new CustomEvent('spriteLeft', { detail: leftAngle }));
                            break;
                        case 'show':
                            window.dispatchEvent(new CustomEvent('spriteShow'));
                            break;
                        case 'hide':
                            window.dispatchEvent(new CustomEvent('spriteHide'));
                            break;
                        case 'say':
                            const event = new CustomEvent('spriteSay', { detail: args1 });
                            dispatchEvent(event);
                            break;
                        case 'sayforsecs':
                            let sayforsecsSeconds = args2;
                            const sayforsecsEvent = new CustomEvent('spriteSayForSecs', { detail: { message: args1, seconds: sayforsecsSeconds } });
                            window.dispatchEvent(sayforsecsEvent);
                            break;
                        case 'think':
                            const thinkEvent = new CustomEvent('spriteThink', { detail: args1 });
                            window.dispatchEvent(thinkEvent);
                            break;
                        case 'thinkforsecs':
                            let thinkforsecsSeconds = args2;
                            const thinkforsecsEvent = new CustomEvent('spriteThinkForSecs', { detail: { message: args1, seconds: thinkforsecsSeconds } });
                            window.dispatchEvent(thinkforsecsEvent);
                            break;
                        case 'input':
                            const inputEvent = new CustomEvent('spriteInput', { detail: args1 });
                            window.dispatchEvent(inputEvent);
                            break;
                        case 'answer':
                            console.log(" answer ................................. ")
                            window.dispatchEvent(new CustomEvent('spriteAnswer'));
                            break;
                        // case 'answer':
                            // answerStore = "Simulated answer"; // Replace with actual answer logic
                            // console.log(`Stored answer in sprite.answer: ${answerStore}`);
                            // break;
                        case 'switchcostume':
                            let costumeName = args[0].trim();
                            if (costumeName.startsWith("'") && costumeName.endsWith("'")) {
                                costumeName = costumeName.substring(1, costumeName.length - 1);
                            } else if (costumeName.startsWith('"') && costumeName.endsWith('"')) {
                                costumeName = costumeName.substring(1, costumeName.length - 1);
                            }
                            window.dispatchEvent(new CustomEvent('spriteSwitchCostume', { detail: costumeName }));
                            break;
                        case 'nextcostume':
                            window.dispatchEvent(new CustomEvent('spriteNextCostume'));
                            // const spriteDelay = ms => new Promise(res => setTimeout(res, ms));

                            // (async () => {
                            //     await spriteDelay(3000);
                            //     window.dispatchEvent(new CustomEvent('spriteNextCostume'));
                            // })();
                            break;
                        case 'switchbackdrop':
                            let backdropName = args[0].trim();
                            if (backdropName.startsWith("'") && backdropName.endsWith("'")) {
                                backdropName = backdropName.substring(1, backdropName.length - 1);
                            } else if (backdropName.startsWith('"') && backdropName.endsWith('"')) {
                                backdropName = backdropName.substring(1, backdropName.length - 1);
                            }
                            window.dispatchEvent(new CustomEvent('spriteSwitchBackdrop', { detail: backdropName }));
                            break;
                        case 'nextbackdrop':
                            window.dispatchEvent(new CustomEvent('spriteNextBackdrop'));
                            break;
                        case 'iskeypresed':
                            let keyName = args[0].trim();
                            if (keyName.startsWith("'") && keyName.endsWith("'")) {
                                keyName = keyName.substring(1, keyName.length - 1);
                            } else if (keyName.startsWith('"') && keyName.endsWith('"')) {
                                keyName = keyName.substring(1, keyName.length - 1);
                            }
                            window.dispatchEvent(new CustomEvent('spriteIsKeyPressed', { detail: keyName }));
                            break;
                        case 'setrotationstyle':
                            let StyleName = args[0].trim();
                            if (StyleName.startsWith("'") && StyleName.endsWith("'")) {
                                StyleName = StyleName.substring(1, StyleName.length - 1);
                            } else if (StyleName.startsWith('"') && StyleName.endsWith('"')) {
                                StyleName = StyleName.substring(1, StyleName.length - 1);
                            }
                            window.dispatchEvent(new CustomEvent('spriteSetRotationStyle', { detail: StyleName }));
                            break;
                        // case 'camera':
                        //     const cameraevent = new CustomEvent('spriteCamera', { detail: {value : args1 , number : args2} });
                        //     dispatchEvent(cameraevent);
                        //     break;
                        // case 'transparency':
                        //     const cameratransparency = new CustomEvent('cameraTransparency', { detail: {value : args1} });
                        //     dispatchEvent(cameratransparency);
                        //     break;
                        case 'bounceonedge':
                            window.dispatchEvent(new CustomEvent('spriteBounceOnEdge'));
                            break;
                        case 'X':
                            window.dispatchEvent(new CustomEvent('spriteX'));
                            break;
                        case 'Y':
                            window.dispatchEvent(new CustomEvent('spriteY'));
                            break;
                        case 'gotolayer':
                            let position = args[0].trim();
                            if (position.startsWith("'") && position.endsWith("'")) {
                                position = position.substring(1, position.length - 1);
                            } else if (position.startsWith('"') && position.endsWith('"')) {
                                position = position.substring(1, position.length - 1);
                            }
                            const gotolayerEvent = new CustomEvent('spriteGoToLayer', { detail: { style: position } });
                            window.dispatchEvent(gotolayerEvent);
                            break;
                        case 'changelayer':
                            let layer_change = args[0].trim();
                            let layer_number = args2;
                            if (layer_change.startsWith("'") && layer_change.endsWith("'")) {
                                layer_change = layer_change.substring(1, layer_change.length - 1);
                            } else if (layer_change.startsWith('"') && layer_change.endsWith('"')) {
                                layer_change = layer_change.substring(1, layer_change.length - 1);
                            }
                            const changelayerEvent = new CustomEvent('spriteChangeLayer', { detail: { style: layer_change, number: layer_number } });
                            window.dispatchEvent(changelayerEvent);
                            break;
                        case 'changeeffect':
                            let effect_name = args2;
                            let effect_value = args1;
                            if (effect_name.startsWith("'") && effect_name.endsWith("'")) {
                                effect_name = effect_name.substring(1, effect_name.length - 1);
                            } else if (effect_name.startsWith('"') && effect_name.endsWith('"')) {
                                effect_name = effect_name.substring(1, effect_name.length - 1);
                            }
                            const changeeffectEvent = new CustomEvent('spriteChangeEffect', { detail: { name: effect_name, value: effect_value } });
                            window.dispatchEvent(changeeffectEvent);
                            break;
                        case 'seteffect':
                            let set_effect_name = args2;
                            console.log(set_effect_name)
                            let set_effect_value = args1;
                            console.log(set_effect_value)
                            if (set_effect_name.startsWith("'") && set_effect_name.endsWith("'")) {
                                set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
                            } else if (set_effect_name.startsWith('"') && set_effect_name.endsWith('"')) {
                                set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
                            }
                            const seteffectEvent = new CustomEvent('spriteSetEffect', { detail: { name: set_effect_name, value: set_effect_value } });
                            window.dispatchEvent(seteffectEvent);
                            break;
                        case 'cleareffects':
                            const cleareffectEvent = new CustomEvent('spriteClearEffect');
                            window.dispatchEvent(cleareffectEvent);
                            break;
                        default:
                            console.error(`Unknown sprite method: ${method}`);
                    }
                }  else {
                    console.error(`Sprite name '${spriteNameInput}' not found.`);
                }
            }
        }
    };

    const executeLines = async (lines, lineCounts) => {
        const loopStack = []; // Stack to manage nested loops
        const state = {}; // Object to hold variable states
        let delay = 0;
        let isLoopActive = false; // Flag to track if we are currently processing any loop
        console.log('LoopStack : ', loopStack);
        console.log('state : ', state);

        // If not in a loop, execute the line normally
        if (!isLoopActive) {
            console.log('only command that time code')
            if (loopStack.length === 0) {  // <-- Only execute this if there are no active loops
                let findLoopCondOperator = false
                let combineCode = ""
                for (const [line, count] of lineCounts.entries()) {
                    for (let i = 0; i < count; i++) { // Execute each line as many times as it appears
                        // await executeLine(line);
                        setTimeout(async () => {
                            await executeLine(line);
                        }, 500);
                        
                        // await spriteDelay(2000);
                    }
                }
            }
        }
    };
    // Start execution 
    await executeLines(lines, lineCounts);
};
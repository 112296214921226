// import React, { useEffect, useState, useRef } from 'react';
// import AceEditor from 'react-ace';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { useIonLoading } from '@ionic/react';
// import ReactReplView from './ReactReplView.jsx';
// import { Tab } from 'react-tabs';
// import Sidebar from './Sidebar.jsx';
// import 'ace-builds/src-noconflict/mode-python';
// import 'ace-builds/src-noconflict/snippets/python';
// import 'ace-builds/src-noconflict/theme-nord_dark';
// import 'ace-builds/src-noconflict/ext-language_tools';
// import { loadPyodide } from 'pyodide';
// import logsIcon1 from './img/logs.svg';
// import runall from './img/runall.svg';
// import stopIcon from './img/stopIcon.svg';
// import runIcon from './img/runIcon.svg';
// import debugIcon from './img/bugIcon.svg';
// import Debugger from './Debugger.jsx';
// import expandIcon from './img/expandIcon.svg';
// import expandBig from './img/expandbig.svg';
// import { handleSpriteCommands } from './spriteCommands.js';
// import spriteCode from './runpythonsprite.js';
// // import VM from 'blockzie-vm'

// const PythonEditor = ({ vm }) => {
//   const [codeInput, setCodeInput] = useState('');
//   const [isLoading, setIsLoading] = useState(true);
//   const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
//   const [presentLoading] = useIonLoading();
//   const [outputArray, setOutputArray] = useState([{ type: '', value: '' }]);
//   const [pyodide, setPyodide] = useState(null);
//   const [showOptions1, setShowOptions1] = useState(false);
//   const [showOptions2, setShowOptions2] = useState(false);
//   const [stopRequested, setStopRequested] = useState(false);
//   const [showDebugger, setShowDebugger] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [imageSrc, setImageSrc] = useState('');
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [allFiles, setAllFiles] = useState([]);
//   console.log(codeInput)
//   console.log(outputArray)
//   useEffect(() => {
//     if (selectedFile) {
//       handleOpenEditor(selectedFile);
//     }
//   }, [selectedFile]);

//   // const selectedName = []
//   const spriteName = []
//   const spriteId = []
//   const target = vm.runtime.targets;
//   // console.log(target);
//   for (let i = 1; i < target.length; i++) {
//     // const spriteObject = {
//     //   [target[i].sprite.name]: target[i].id
//     // };
//     // selectedName.push(target[i]);
//     spriteName.push(target[i].sprite.name)
//     spriteId.push(target[i].id)
//   }
//   // console.log(selectedName);

//   const handleButtonClick1 = () => {
//     setIsExpanded(!isExpanded);
//   };


//   const handleRunAllClick = () => {
//     for (const file of allFiles) {
//       handleOpenEditor(file);
//       runPyCode();
//     }
//   };

//   const handleButtonClick = () => {
//     setShowDebugger(!showDebugger);
//   };

//   const toggleOptions1 = () => {
//     setShowOptions1(!showOptions1);
//   };

//   const toggleOptions2 = () => {
//     setShowOptions2(!showOptions2);
//   };

//   const handleStopClick = () => {
//     setStopRequested(true); // Set stopRequested to true when stop button is clicked
//   };

//   useEffect(() => {
//     const loadPythonInReact = async () => {
//       if (!isLoading) {
//         setIsLoading(true);
//         return;
//       }

//       if (!isPyodideLoaded) {
//         presentLoading('Loading... Pyodide', 2000, 'crescent');

//         const pyodideInstance = await loadPyodide({
//           indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
//           stdin: window.prompt,
//           stdout: (text) => {
//             let data = { type: 'output', value: text.toString() };
//             setOutputArray((outputArray) => [...outputArray, data]);
//           },
//           stderr: (text) => {
//             let data = { type: 'error', value: text.toString() };
//             setOutputArray((outputArray) => [...outputArray, data]);
//           },
//         });

//         // call runpythonsprite.js file
//         await pyodideInstance.runPythonAsync(spriteCode);
//         // Load additional packages
//         await pyodideInstance.loadPackage('numpy');
//         await pyodideInstance.loadPackage('pandas');
//         await pyodideInstance.loadPackage('micropip');
//         await pyodideInstance.loadPackage('opencv-python');
//         await pyodideInstance.loadPackage('matplotlib');
//         await pyodideInstance.loadPackage('distutils');
//         // await pyodideInstance.loadPackage('execjs');

//         setPyodide(pyodideInstance);
//         setIsPyodideLoaded(true);
//       }
//     };

//     loadPythonInReact();
//   }, [isLoading, isPyodideLoaded, presentLoading]);

//   // const runPyCode = async () => {
//   //   if (!pyodide) {
//   //     console.error('Pyodide is not loaded yet.');

//   //     return;
//   //   }

//   //   if (!codeInput.trim()) {
//   //     console.warn('Code input is empty.');
//   //     return;
//   //   }

//   //   try {
//   //     const result = await pyodide.runPythonAsync(codeInput);
//   //     console.log(result)
//   //     console.log(codeInput)
//   //     const outputArray = result !== undefined ? result.toString() : "Python code did not return a value.";
//   //     if (result !== undefined && result.error) {
//   //                 let data = { type: 'error', value: result.error };
//   //                 setOutputArray((outputArray) => [...outputArray, data]);
//   //               }
//   //               window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

//   //     // Extract sprite commands from the Python code
//   //     const spriteCommands = extractSpriteCommands(codeInput);
//   //     if (spriteCommands) {
//   //       handleSpriteCommands({ command: codeInput, result: outputArray });
//   //     }
//   //   } catch (error) {
//   //     console.error('Error running Python code:', error);
//   //   }
//   // };

//   const runPyCode = async () => {
//     if (!pyodide) {
//       console.error('Pyodide is not loaded yet.');

//       return;
//     }

//     if (!codeInput.trim()) {
//       console.warn('Code input is empty.');
//       return;
//     }

//     try {
//       const result = await pyodide.runPythonAsync(codeInput);
//       console.log(result)
//       console.log(codeInput)
//       const outputArray = result !== undefined ? result.toString() : "Python code did not return a value.";
//       if (result !== undefined && result.error) {
//         let data = { type: 'error', value: result.error };
//         setOutputArray((outputArray) => [...outputArray, data]);
//       }
//       window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

//       // Extract sprite commands from the Python code
//       const spriteCommands = extractSpriteCommands(codeInput);
//       if (spriteCommands) {
//         // Call the handleSpriteCommands function from the spriteCommands.js file
//         handleSpriteCommands({ command: codeInput, result: outputArray, spriteId: spriteId, spriteName: spriteName });
//       }
//     } catch (error) {
//       console.error('Error running Python code:', error);
//     }
//   };

//   // Helper function to extract sprite commands from Python code
//   const extractSpriteCommands = (code) => {
//     // Implement logic to extract sprite commands from the Python code
//     // For example, you can use regular expressions to extract lines that start with "sprite."
//     const spriteCommands = [];
//     const lines = code.split('\n');
//     for (const line of lines) {
//       if (line.trim().startsWith('sprite.')) {
//         spriteCommands.push(line.trim());
//       }
//     }
//     return spriteCommands.join('\n');
//   };

//   async function termAction(action, e) {
//     if (action === "onClear") {
//       setOutputArray([{ type: "", value: "" }]);
//     }

//     if (action === "onSubmit") {
//       if (e === "clear" || e === "clear()") {
//         setOutputArray([{ type: "", value: "" }]);
//         return;
//       }

//       if (e === "" || e === undefined || e === null) {
//         return;
//       }

//       let data = { type: "input".toString(), value: e.toString() };
//       setOutputArray((outputArray) => [...outputArray, data]);

//       if (!pyodide) {
//         console.error('Pyodide is not loaded yet.');
//         return;
//       }

//       let namespace = pyodide.globals.get("dict")();
//       pyodide.runPython(
//         `
//     import sys 
//     from pyodide.ffi import to_js
//     from pyodide.console import PyodideConsole, repr_shorten, BANNER
//     import main
//     BANNER = "Welcome to the Pyodide terminal emulator 🐍\\n" + BANNER
//     pyconsole = PyodideConsole(main.dict)
//     import builtins
//     async def await_fut(fut):
//       res = await fut
//       if res is not None:
//         builtins._ = res
//       return to_js([res], depth=1)
//     def clear_console():
//       pyconsole.buffer = []
// `,
//         { globals: namespace }
//       );

//       let await_fut = namespace.get("await_fut");
//       let pyconsole = namespace.get("pyconsole");

//       pyconsole.stdout_callback = (s) => {
//         let data = { type: "output".toString(), value: s.toString() };
//         setOutputArray((outputArray) => [...outputArray, data]);
//       };

//       pyconsole.stderr_callback = (s) => {
//         let data = { type: "error".toString(), value: s.toString() };
//         setOutputArray((outputArray) => [...outputArray, data]);
//       };

//       try {
//         let fut = pyconsole.push(e.toString());
//         let result = await await_fut(fut);
//         if (result.length > 0) {
//           if (result[0] !== undefined) {
//             let data = { type: 'output'.toString(), value: repr_shorten(result[0]) };
//             setOutputArray((outputArray) => [...outputArray, data]);
//           }
//         }
//       } catch (err) {
//         let data = { type: 'error'.toString(), value: err.toString() };
//         setOutputArray((outputArray) => [...outputArray, data]);
//       }
//     }

//     if (action === "onCopy") {
//       const lastOutput = outputArray[outputArray.length - 1];
//       if (lastOutput && lastOutput.value) {
//         navigator.clipboard.writeText(lastOutput.value).then(
//           () => console.log('Copied to clipboard'),
//           (err) => console.error('Could not copy: ', err)
//         );
//       }
//     }

//     if (action === "onSearch") {
//       const searchTerm = prompt("Enter the term to search:");
//       if (searchTerm) {
//         const results = outputArray.filter(line => line.value.includes(searchTerm));
//         if (results.length > 0) {
//           console.log('Search results:', results);
//           alert(`Found${results.length}`, results);
//           // Optionally, setFilteredResults(results); if you want to manage state
//           scrollToResult(0); // Scroll to the first result
//         } else {
//           alert('No results found');
//         }
//       }
//     }
//   }

//   const scrollToResult = (index) => {
//     if (index >= 0 && index < outputArray.length) {
//       document.querySelectorAll('.output-line')[index]?.scrollIntoView({
//         behavior: 'smooth',
//         block: 'start',
//       });
//     }
//   };

//   return (
//     <div style={{ display: 'flex', flexGrow: '1' }}>
//       <div id="python-editor-container" style={{ height: '100%', width: '100%' }}>
//         <div style={{ display: isExpanded ? 'none' : 'flex', paddingBottom: '5px', height: '65vh' }}>
//           <Sidebar
//             // style={{maxWidth:'10%', minWidth:'10%'}}
//             toggleOptions1={toggleOptions1}
//             toggleOptions2={toggleOptions2}
//             showOptions1={showOptions1}
//             showOptions2={showOptions2}
//             setCodeInput={setCodeInput}
//             getFiles={setAllFiles}
//             setImageSrc={setImageSrc}
//             codeInput={codeInput}
//           />
//           {imageSrc ? (
//             <div style={{ width: showDebugger ? '80%' : ' 100%', textAlign: 'center', marginTop: '10%' }}>
//               <img src={imageSrc} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
//             </div>
//           ) : (
//             <AceEditor id="show-file"
//               onChange={(newValue) => setCodeInput(newValue)}
//               style={{ width: showDebugger ? '80%' : '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '1%', borderBottomRightRadius: '1%', paddingBottom: '5px' }}
//               placeholder=""
//               mode="python"
//               name="code_editor"
//               height="600px"
//               fontSize={18}
//               editorProps={{ $blockScrolling: true }}
//               showPrintMargin={true}
//               showGutter={true}
//               highlightActiveLine={true}
//               value={codeInput}
//               setOptions={{
//                 enableBasicAutocompletion: true,
//                 enableLiveAutocompletion: true,
//                 enableSnippets: false,
//                 showLineNumbers: true,
//                 tabSize: 2,
//               }}>
//             </AceEditor>
//           )}
//         </div>

//         <div id="python-editor-output" style={{ width: '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }}>
//           <div className='logos' style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
//             <Tab
//               className="react-tabs_react-tabs_tab_1ktU terminal_tab_8e-XY react-tabs_react-tabs__tab--selected_2o0LJ terminal_is-selected_3neEd"
//               id="react-tabs-10"
//               style={{ borderRadius: '5%', width: '75px', backgroundColor: 'rgb(219 227 242)', marginLeft: "10px", border: '1px solid hsla(0, 0%, 0%, 0.15)', height: '35px', display: 'flex' }}
//             >
//               <img draggable={false} src={logsIcon1} style={{ height: '25px', width: '25px', marginTop: '4px' }} />
//               <div style={{ marginTop: '8px', marginLeft: '7px', color: '#2c236c' }}>Logs</div>
//             </Tab>
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//               {/* run button */}
//               <button type="button" onClick={runPyCode}
//                 style={{ borderTopLeftRadius: '5%', borderBottomLeftRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={runIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "7px", color: '#2c236c' }}>Run</div>
//               </button>
//               {/* runn all buttton  */}
//               <button className="btn-text-right" type="button" onClick={handleRunAllClick} style={{ display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={runall} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "7px", color: '#2c236c' }}>Run All</div>
//               </button>
//               {/* stop button */}
//               <button
//                 onClick={handleStopClick}
//                 style={{ borderTopRightRadius: '5%', borderBottomRightRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}  >
//                 <img draggable={false} src={stopIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "7px", color: '#2c236c' }}>Stop</div>
//               </button>
//               {/* debugger buttton  */}
//               <button onClick={handleButtonClick} style={{ marginLeft: '10px', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={debugIcon} alt="Debug Icon" style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//               </button>

//               {/* expand button  */}
//               <button onClick={handleButtonClick1} style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: 'rgb(219 227 242)', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)' }}>
//                 <img draggable={false} src={isExpanded ? expandBig : expandIcon} style={{ width: '25px', height: '25px', margin: '2px', cursor: 'pointer' }} alt="Expand Icon" />
//               </button>
//             </div>
//           </div>

//           <div style={{ marginLeft: '10px', height: '100%' }}>
//             <div style={{ marginTop: '10px', transition: 'height 0.3s ease', height: isExpanded ? '100%' : '20%', margintop: '20px', maxwidth: '900px' }}>
//               {/* terminal */}
//               <ReactReplView title={''}
//                 tabs={[]}
//                 onSubmit={(e) => termAction("onSubmit", e)}
//                 lines={outputArray }
//                 onChangeTab={(e) => termAction("onChangeTab", e)}
//                 onClear={() => termAction("onClear")}
//                 isExpanded={isExpanded} />
//             </div>
//           </div>
//         </div>
//       </div>
//       {showDebugger && <Debugger />}
//     </div >
//   );
// };

// PythonEditor.propTypes = {
//   vm: PropTypes.object.isRequired,
// };

// const mapStateToProps = state => ({
//   vm: state.scratchGui.vm // Ensure VM is available in props
// });

// export default connect(
//   mapStateToProps,
// )(PythonEditor);


import React, { useEffect, useState, useRef } from 'react';
import AceEditor from 'react-ace';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIonLoading } from '@ionic/react';
import ReactReplView from './ReactReplView.jsx';
import { Tab } from 'react-tabs';
import Sidebar from './Sidebar.jsx';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-nord_dark';
import 'ace-builds/src-noconflict/ext-language_tools';
import { loadPyodide } from 'pyodide';
import logsIcon1 from './img/logs.svg';
import runall from './img/runall.svg';
import stopIcon from './img/stopIcon.svg';
import runIcon from './img/runIcon.svg';
import debugIcon from './img/bugIcon.svg';
import Debugger from './Debugger.jsx';
import expandIcon from './img/expandIcon.svg';
import expandBig from './img/expandbig.svg';
import { handleSpriteCommands } from './spriteCommands.js';
import spriteCode from './runpythonsprite.js';
// import { text } from 'node:stream/consumers';
// import VM from 'blockzie-vm'

const PythonEditor = ({ vm }) => {
  const [codeInput, setCodeInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const [presentLoading] = useIonLoading();
  const [outputArray, setOutputArray] = useState([{ type: '', value: '' }]);
  const [pyodide, setPyodide] = useState(null);
  const [showOptions1, setShowOptions1] = useState(false);
  const [showOptions2, setShowOptions2] = useState(false);
  const [stopRequested, setStopRequested] = useState(false);
  const [showDebugger, setShowDebugger] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [allFiles, setAllFiles] = useState([]);
  const [currentInputIndex, setCurrentInputIndex] = useState(0); // State to track current input index
  // const [promptIN , setPromptIN] = useState('')
console.log(currentInputIndex)
  // console.log(codeInput)
  console.log(outputArray)

  useEffect(() => {
    if (selectedFile) {
      handleOpenEditor(selectedFile);
    }
  }, [selectedFile]);

  // useEffect(() => {
  //   // Only run if codeInput is not empty
  //   if (codeInput) {
  //     const inputStrings = [...codeInput.matchAll(/input\("([^"]+)"\)/g)].map(match => match[1]);
  //     console.log(inputStrings); // This will log the extracted input strings
  //   }
  // }, [codeInput]); // Dependency array includes codeInput

 
  // useEffect(() => {
  //    for (let i = 0; i < inputStrings.length; i++) {
  //   console.log(i);
  //   const promptInput = inputStrings[i];
  //   setPromptIN(promptInput) // Get input from the array
  //   console.log(promptInput);
  // }
  // }, [codeInput]);
 
  
  // const selectedName = []
  const spriteName = []
  const spriteId = []
  const target = vm.runtime.targets;
  // console.log(target);
  for (let i = 1; i < target.length; i++) {
    // const spriteObject = {
    //   [target[i].sprite.name]: target[i].id
    // };
    // selectedName.push(target[i]);
    spriteName.push(target[i].sprite.name)
    spriteId.push(target[i].id)
  }
  // console.log(selectedName);

  const handleButtonClick1 = () => {
    setIsExpanded(!isExpanded);
  };


  const handleRunAllClick = () => {
    for (const file of allFiles) {
      handleOpenEditor(file);
      runPyCode();
    }
  };

  const handleButtonClick = () => {
    setShowDebugger(!showDebugger);
  };

  const toggleOptions1 = () => {
    setShowOptions1(!showOptions1);
  };

  const toggleOptions2 = () => {
    setShowOptions2(!showOptions2);
  };

  const handleStopClick = () => {
    setStopRequested(true); // Set stopRequested to true when stop button is clicked
  };

  const inputStrings = [...codeInput.matchAll(/input\("([^"]+)"\)/g)].map(match => match[1]);
            console.log(inputStrings);
  // useEffect(() => {
  //   // Reset the input index when codeInput changes
  //   // setCurrentInputIndex(0);
  //   setIsPyodideLoaded(false);
  // }, [codeInput]);
  
  // useEffect(() => {
  //   const loadPythonInReact = async () => {
  //     if (!isLoading) {
  //       setIsLoading(true);
  //       return;
  //     }

  //     if (!isPyodideLoaded) {
  //       presentLoading('Loading... Pyodide', 2000, 'crescent');

  //       const pyodideInstance = await loadPyodide({
  //         indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
  //         // stdin: window.prompt,
  //         stdin: () => {
  //           const inputStrings = [...codeInput.matchAll(/input\("([^"]+)"\)/g)].map(match => match[1]);
  //           console.log(inputStrings); // This will log the extracted input strings
  //           if (currentInputIndex <= inputStrings.length) {
  //             console.log(currentInputIndex)
  //             const userInput = prompt(`${inputStrings[currentInputIndex]}`); // Get input from the array
  //             console.log(userInput);
  //             let data = { type: 'input', value: userInput };
  //             setOutputArray((outputArray) => [...outputArray, data]);
  //             const incrementIndex = currentInputIndex + 1; // Move to the next input
  //             console.log(incrementIndex)
  //             setCurrentInputIndex(incrementIndex)
  //             return userInput; // Return the user input from the array
  //           } else {
  //             console.log("if input function has not in code ")
  //             return ''; // Return an empty string if there are no more inputs
  //           }
  //         },
  //         // stdin: () => {
  //         //   for (let i = 0; i < inputStrings.length; i++) {
  //         //     console.log(i);
  //         //     const userInput = prompt(`${inputStrings[i]}`); // Get input from the array
  //         //     console.log(userInput);
  //         //     let data = { type: 'input', value: userInput };
  //         //     setOutputArray((outputArray) => [...outputArray, data]);
  //         //     // setCurrentInputIndex(i+ 1); // Correctly increment index
  //         //     return userInput; // Return the user input from the array
  //         //   }
  //         //   console.log("for input function has completed");
  //         //   return ''; // Return an empty string if there are no more inputs
  //         // },
          
  //         // stdout: (text) => {
  //         //   console.log(text)
  //         //   let data = { type: 'output', value: text.toString() };
  //         //   setOutputArray((outputArray) => [...outputArray, data]);
  //         // },
  //         stdout: (text) => {
  //           // Check for matches and remove them from the text
  //           let modifiedText = text;
            
  //           inputStrings.forEach(input => {
  //             if (modifiedText.includes(input)) {
  //               modifiedText = modifiedText.replace(input, ''); // Remove the matched string
  //             }
  //           });

  //           // Trim the modified text to avoid leading/trailing spaces
  //           modifiedText = modifiedText.trim();

  //           // Only add to output if there's remaining text
  //           if (modifiedText) {
  //             console.log(modifiedText);
  //             let data = { type: 'output', value: modifiedText };
  //             setOutputArray((outputArray) => [...outputArray, data]);
  //           }
  //         },
  //         stderr: (text) => {
  //           let data = { type: 'error', value: text.toString() };
  //           setOutputArray((outputArray) => [...outputArray, data]);
  //         },
  //       });

  useEffect(() => {
    const loadPythonInReact = async () => {
      if (!isLoading) {
        setIsLoading(true);
        return;
      }

      if (!isPyodideLoaded) {
        presentLoading('Loading... Pyodide', 2000, 'crescent');

        const pyodideInstance = await loadPyodide({
          indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
          // stdin: window.prompt,
          // stdin: () => {
          //   // Here, you can customize how to get user input
          //   const userInput = prompt(${codeInput}); // You can customize this further if needed
          //   console.log(userInput)
          //   let data = { type: 'input', value: userInput };
          //   setOutputArray((outputArray) => [...outputArray, data]);
          //   return userInput; // Return only the user input
          // },
          stdin: () => {
            if (currentInputIndex < inputStrings.length) {
              console.log(currentInputIndex)
              const userInput = prompt(`${inputStrings[currentInputIndex]}`); // Get input from the array
              console.log(userInput);
              let data = { type: 'input', value: `${inputStrings[currentInputIndex]}${userInput}` };
              setOutputArray((outputArray) => [...outputArray, data]);
              setCurrentInputIndex(currentInputIndex + 1); // Move to the next input
              return userInput; // Return the user input from the array
            } else {
              console.log("if input function has not in code ")
              return ''; // Return an empty string if there are no more inputs
            }
          },
          // stdout: (text) => {
          //   console.log(text)
          //   let data = { type: 'output', value: text.toString() };
          //   setOutputArray((outputArray) => [...outputArray, data]);
          // },
          stdout: (text) => {
            // Check for matches and remove them from the text
            let modifiedText = text;

            inputStrings.forEach(input => {
              if (modifiedText.includes(input)) {
                modifiedText = modifiedText.replace(input, ''); // Remove the matched string
              }
            });

            // Trim the modified text to avoid leading/trailing spaces
            modifiedText = modifiedText.trim();

            // Only add to output if there's remaining text
            if (modifiedText) {
              console.log(modifiedText);
              let data = { type: 'output', value: modifiedText };
              setOutputArray((outputArray) => [...outputArray, data]);
            }
          },
          stderr: (text) => {
            let data = { type: 'error', value: text.toString() };
            setOutputArray((outputArray) => [...outputArray, data]);
          },
        });

        // call runpythonsprite.js file
        await pyodideInstance.runPythonAsync(spriteCode);
        // Load additional packages
        await pyodideInstance.loadPackage('numpy');
        await pyodideInstance.loadPackage('pandas');
        await pyodideInstance.loadPackage('micropip');
        await pyodideInstance.loadPackage('opencv-python');
        await pyodideInstance.loadPackage('matplotlib');
        await pyodideInstance.loadPackage('distutils');
        // await pyodideInstance.loadPackage('execjs');

        setPyodide(pyodideInstance);
        setIsPyodideLoaded(true);
      }
    };

    loadPythonInReact();
  }, [isLoading, isPyodideLoaded, presentLoading, codeInput, currentInputIndex]);

  const runPyCode = async () => {
    if (!pyodide) {
      console.error('Pyodide is not loaded yet.');
      return;
    }

    if (!codeInput.trim()) {
      console.warn('Code input is empty.');
      return;
    }

    try {
      const result = await pyodide.runPythonAsync(codeInput);
      console.log(result)
      console.log(codeInput)
      // const outputArray = result !== undefined ? result.toString() : "Python code did not return a value.";
      const outputArray = result !== undefined ? result.toString() : "";
      console.log(outputArray)
      if (result !== undefined && result.error) {
        let data = { type: 'error', value: result.error };
        setOutputArray((outputArray) => [...outputArray, data]);
      }
      window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

      // Extract sprite commands from the Python code
      const spriteCommands = extractSpriteCommands(codeInput);
      if (spriteCommands) {
        // Call the handleSpriteCommands function from the spriteCommands.js file
        handleSpriteCommands({ command: codeInput, result: outputArray, spriteId: spriteId, spriteName: spriteName });
      }
    } catch (error) {
      console.error('Error running Python code:', error);
    }
  };

  // Helper function to extract sprite commands from Python code
  const extractSpriteCommands = (code) => {
    // Implement logic to extract sprite commands from the Python code
    // For example, you can use regular expressions to extract lines that start with "sprite."
    const spriteCommands = [];
    const lines = code.split('\n');
    for (const line of lines) {
      if (line.trim().startsWith('sprite.')) {
        spriteCommands.push(line.trim());
      }
    }
    return spriteCommands.join('\n');
  };

  async function termAction(action, e) {
    if (action === "onClear") {
      setOutputArray([{ type: "", value: "" }]);
    }

    if (action === "onSubmit") {
      if (e === "clear" || e === "clear()") {
        setOutputArray([{ type: "", value: "" }]);
        return;
      }

      if (e === "" || e === undefined || e === null) {
        return;
      }

      let data = { type: "input".toString(), value: e.toString() };
      setOutputArray((outputArray) => [...outputArray, data]);

      if (!pyodide) {
        console.error('Pyodide is not loaded yet.');
        return;
      }

      let namespace = pyodide.globals.get("dict")();
      pyodide.runPython(
        `
    import sys 
    from pyodide.ffi import to_js
    from pyodide.console import PyodideConsole, repr_shorten, BANNER
    import main
    BANNER = "Welcome to the Pyodide terminal emulator 🐍\\n" + BANNER
    pyconsole = PyodideConsole(main.dict)
    import builtins
    async def await_fut(fut):
      res = await fut
      if res is not None:
        builtins._ = res
      return to_js([res], depth=1)
    def clear_console():
      pyconsole.buffer = []
`,
        { globals: namespace }
      );

      let await_fut = namespace.get("await_fut");
      let pyconsole = namespace.get("pyconsole");

      pyconsole.stdout_callback = (s) => {
        let data = { type: "output".toString(), value: s.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      };

      pyconsole.stderr_callback = (s) => {
        let data = { type: "error".toString(), value: s.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      };

      try {
        let fut = pyconsole.push(e.toString());
        let result = await await_fut(fut);
        if (result.length > 0) {
          if (result[0] !== undefined) {
            let data = { type: 'output'.toString(), value: repr_shorten(result[0]) };
            setOutputArray((outputArray) => [...outputArray, data]);
          }
        }
      } catch (err) {
        let data = { type: 'error'.toString(), value: err.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      }
    }

    if (action === "onCopy") {
      const lastOutput = outputArray[outputArray.length - 1];
      if (lastOutput && lastOutput.value) {
        navigator.clipboard.writeText(lastOutput.value).then(
          () => console.log('Copied to clipboard'),
          (err) => console.error('Could not copy: ', err)
        );
      }
    }

    if (action === "onSearch") {
      const searchTerm = prompt("Enter the term to search:");
      if (searchTerm) {
        const results = outputArray.filter(line => line.value.includes(searchTerm));
        if (results.length > 0) {
          console.log('Search results:', results);
          alert(`Found${results.length}`, results);
          // Optionally, setFilteredResults(results); if you want to manage state
          scrollToResult(0); // Scroll to the first result
        } else {
          alert('No results found');
        }
      }
    }
  }

  const scrollToResult = (index) => {
    if (index >= 0 && index < outputArray.length) {
      document.querySelectorAll('.output-line')[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexGrow: '1' }}>
      <div id="python-editor-container" style={{ height: '100%', width: '100%' }}>
        <div style={{ display: isExpanded ? 'none' : 'flex', paddingBottom: '5px', height: '65vh' }}>
          <Sidebar
            // style={{maxWidth:'10%', minWidth:'10%'}}
            toggleOptions1={toggleOptions1}
            toggleOptions2={toggleOptions2}
            showOptions1={showOptions1}
            showOptions2={showOptions2}
            setCodeInput={setCodeInput}
            getFiles={setAllFiles}
            setImageSrc={setImageSrc}
            codeInput={codeInput}
          />
          {imageSrc ? (
            <div style={{ width: showDebugger ? '80%' : ' 100%', textAlign: 'center', marginTop: '10%' }}>
              <img src={imageSrc} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          ) : (
            <AceEditor id="show-file"
              onChange={(newValue) => setCodeInput(newValue)}
              style={{ width: showDebugger ? '80%' : '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '1%', borderBottomRightRadius: '1%', paddingBottom: '5px' }}
              placeholder=""
              mode="python"
              name="code_editor"
              height="600px"
              fontSize={18}
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={codeInput}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}>
            </AceEditor>
          )}
        </div>

        <div id="python-editor-output" style={{ width: '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }}>
          <div className='logos' style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
            <Tab
              className="react-tabs_react-tabs_tab_1ktU terminal_tab_8e-XY react-tabs_react-tabs__tab--selected_2o0LJ terminal_is-selected_3neEd"
              id="react-tabs-10"
              style={{ borderRadius: '5%', width: '75px', backgroundColor: 'rgb(219 227 242)', marginLeft: "10px", border: '1px solid hsla(0, 0%, 0%, 0.15)', height: '35px', display: 'flex' }}
            >
              <img draggable={false} src={logsIcon1} style={{ height: '25px', width: '25px', marginTop: '4px' }} />
              <div style={{ marginTop: '8px', marginLeft: '7px', color: '#2c236c' }}>Logs</div>
            </Tab>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* run button */}
              <button type="button" onClick={runPyCode}
                style={{ borderTopLeftRadius: '5%', borderBottomLeftRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={runIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "7px", color: '#2c236c' }}>Run</div>
              </button>
              {/* runn all buttton  */}
              <button className="btn-text-right" type="button" onClick={handleRunAllClick} style={{ display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={runall} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "7px", color: '#2c236c' }}>Run All</div>
              </button>
              {/* stop button */}
              <button
                onClick={handleStopClick}
                style={{ borderTopRightRadius: '5%', borderBottomRightRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}  >
                <img draggable={false} src={stopIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "7px", color: '#2c236c' }}>Stop</div>
              </button>
              {/* debugger buttton  */}
              <button onClick={handleButtonClick} style={{ marginLeft: '10px', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={debugIcon} alt="Debug Icon" style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
              </button>

              {/* expand button  */}
              <button onClick={handleButtonClick1} style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: 'rgb(219 227 242)', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)' }}>
                <img draggable={false} src={isExpanded ? expandBig : expandIcon} style={{ width: '25px', height: '25px', margin: '2px', cursor: 'pointer' }} alt="Expand Icon" />
              </button>
            </div>
          </div>

          <div style={{ marginLeft: '10px', height: '100%' }}>
            <div style={{ marginTop: '10px', transition: 'height 0.3s ease', height: isExpanded ? '100%' : '20%', margintop: '20px', maxwidth: '900px' }}>
              {/* terminal */}
              <ReactReplView title={''}
                tabs={[]}
                onSubmit={(e) => termAction("onSubmit", e)}
                lines={outputArray }
                onChangeTab={(e) => termAction("onChangeTab", e)}
                onClear={() => termAction("onClear")}
                isExpanded={isExpanded} />
            </div>
          </div>
        </div>
      </div>
      {showDebugger && <Debugger />}
    </div >
  );
};

PythonEditor.propTypes = {
  vm: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  vm: state.scratchGui.vm // Ensure VM is available in props
});

export default connect(
  mapStateToProps,
)(PythonEditor);


// import React, { useEffect, useState, useRef } from 'react';
// import AceEditor from 'react-ace';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { useIonLoading } from '@ionic/react';
// import ReactReplView from './ReactReplView.jsx';
// import { Tab } from 'react-tabs';
// import Sidebar from './Sidebar.jsx';
// import 'ace-builds/src-noconflict/mode-python';
// import 'ace-builds/src-noconflict/snippets/python';
// import 'ace-builds/src-noconflict/theme-nord_dark';
// import 'ace-builds/src-noconflict/ext-language_tools';
// import { loadPyodide } from 'pyodide';
// import logsIcon1 from './img/logs.svg';
// import runall from './img/runall.svg';
// import stopIcon from './img/stopIcon.svg';
// import runIcon from './img/runIcon.svg';
// import debugIcon from './img/bugIcon.svg';
// import Debugger from './Debugger.jsx';
// import expandIcon from './img/expandIcon.svg';
// import expandBig from './img/expandbig.svg';
// import { handleSpriteCommands } from './spriteCommands.js';
// import spriteCode from './runpythonsprite.js';
// // import VM from 'blockzie-vm'

// const PythonEditor = ({ vm }) => {
//   const [codeInput, setCodeInput] = useState('');
//   const [isLoading, setIsLoading] = useState(true);
//   const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
//   const [presentLoading] = useIonLoading();
//   const [outputArray, setOutputArray] = useState([{ type: '', value: '' }]);
//   const [pyodide, setPyodide] = useState(null);
//   const [showOptions1, setShowOptions1] = useState(false);
//   const [showOptions2, setShowOptions2] = useState(false);
//   const [stopRequested, setStopRequested] = useState(false);
//   const [showDebugger, setShowDebugger] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [imageSrc, setImageSrc] = useState('');
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [allFiles, setAllFiles] = useState([]);
  
//   console.log(codeInput)
//   console.log(outputArray)
//   useEffect(() => {
//     if (selectedFile) {
//       handleOpenEditor(selectedFile);
//     }
//   }, [selectedFile]);

//   // const selectedName = []
//   const spriteName = []
//   const spriteId = []
//   const target = vm.runtime.targets;
//   // console.log(target);
//   for (let i = 1; i < target.length; i++) {
//     // const spriteObject = {
//     //   [target[i].sprite.name]: target[i].id
//     // };
//     // selectedName.push(target[i]);
//     spriteName.push(target[i].sprite.name)
//     spriteId.push(target[i].id)
//   }
//   // console.log(selectedName);

//   const handleButtonClick1 = () => {
//     setIsExpanded(!isExpanded);
//   };


//   const handleRunAllClick = () => {
//     for (const file of allFiles) {
//       handleOpenEditor(file);
//       runPyCode();
//     }
//   };

//   const handleButtonClick = () => {
//     setShowDebugger(!showDebugger);
//   };

//   const toggleOptions1 = () => {
//     setShowOptions1(!showOptions1);
//   };

//   const toggleOptions2 = () => {
//     setShowOptions2(!showOptions2);
//   };

//   const handleStopClick = () => {
//     setStopRequested(true); // Set stopRequested to true when stop button is clicked
//   };

//   // const customInputHandler = () => {
//   //   setIsInputVisible(true); // Show the input field
//   //   return new Promise((resolve) => {
//   //     const handleInput = (event) => {
//   //       if (event.key === 'Enter') {
//   //         resolve(inputValue);
//   //         window.removeEventListener('keydown', handleInput);
//   //       }
//   //     };
//   //     window.addEventListener('keydown', handleInput);
//   //   });
//   // };

//   // const handleInputSubmit = async () => {
//   //   if (inputValue) {
//   //     // Pass the input value to Pyodide
//   //     await pyodide.runPythonAsync(`input_value = "${inputValue}"`);
//   //     setInputValue(''); // Clear the input after submission
//   //     setIsInputVisible(false); // Hide the input field
//   //   }
//   // };

//   useEffect(() => {
//     const loadPythonInReact = async () => {
//       if (!isLoading) {
//         setIsLoading(true);
//         return;
//       }

//       if (!isPyodideLoaded) {
//         presentLoading('Loading... Pyodide', 2000, 'crescent');

//         const pyodideInstance = await loadPyodide({
//           indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
//           // stdin: window.prompt,
//           // stdin: async () => {
//           //   return new Promise((resolve) => {
//           //     const inputValue = window.prompt("Enter input for Python code:");
//           //     if (inputValue !== null && inputValue !== undefined) {
//           //       resolve(String(inputValue));  // Ensure the input is returned as a string
//           //     } else {
//           //       resolve(""); // Handle case where input is cancelled or undefined
//           //     }
//           //   });
//           // },
//           stdin: async () => {
//             // Custom input handler using a prompt
//             return new Promise((resolve) => {
//               const inputValue = window.prompt("Enter input for Python code:");
//               if (inputValue !== null) {
//                 // Log only the input value
//                 let data = { type: 'output', value: inputValue };
//                 setOutputArray((outputArray) => [...outputArray, data]);
//                 resolve(inputValue); // Pass the input to Pyodide
//               } else {
//                 // Handle user canceling the prompt
//                 resolve('');
//               }
//             });
//           },
//           stdout: (text) => {
//             let data = { type: 'output', value: text.toString() };
//             console.log(data)
//             console.log(text)
//             setOutputArray((outputArray) => [...outputArray, data]);
//           },
//           stderr: (text) => {
//             let data = { type: 'error', value: text.toString() };
//             setOutputArray((outputArray) => [...outputArray, data]);
//           },
//         });

//         // call runpythonsprite.js file
//         await pyodideInstance.runPythonAsync(spriteCode);
//         // Load additional packages
//         await pyodideInstance.loadPackage('numpy');
//         await pyodideInstance.loadPackage('pandas');
//         await pyodideInstance.loadPackage('micropip');
//         await pyodideInstance.loadPackage('opencv-python');
//         await pyodideInstance.loadPackage('matplotlib');
//         await pyodideInstance.loadPackage('distutils');
//         // await pyodideInstance.loadPackage('execjs');

//         setPyodide(pyodideInstance);
//         setIsPyodideLoaded(true);
//       }
//     };

//     loadPythonInReact();
//   }, [isLoading, isPyodideLoaded, presentLoading]);

//   const runPyCode = async () => {
//     if (!pyodide) {
//       console.error('Pyodide is not loaded yet.');

//       return;
//     }

//     if (!codeInput.trim()) {
//       console.warn('Code input is empty.');
//       return;
//     }

//     try {
//       const result = await pyodide.runPythonAsync(codeInput);
//       console.log(result)
//       console.log(codeInput)
//       const outputArray = result !== undefined ? result.toString() : "Python code did not return a value.";
//       let data = { type: 'output', value: result };
//       setOutputArray((outputArray) => [...outputArray, data]);
//       if (result !== undefined && result.error) {
//         let data = { type: 'error', value: result.error };
//         setOutputArray((outputArray) => [...outputArray, data]);
//       }
//       window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

//       // Extract sprite commands from the Python code
//       const spriteCommands = extractSpriteCommands(codeInput);
//       if (spriteCommands) {
//         // Call the handleSpriteCommands function from the spriteCommands.js file
//         handleSpriteCommands({ command: codeInput, result: outputArray, spriteId: spriteId, spriteName: spriteName });
//       }
//     } catch (error) {
//       console.error('Error running Python code:', error);
//     }
//   };

//   // Helper function to extract sprite commands from Python code
//   const extractSpriteCommands = (code) => {
//     // Implement logic to extract sprite commands from the Python code
//     // For example, you can use regular expressions to extract lines that start with "sprite."
//     const spriteCommands = [];
//     const lines = code.split('\n');
//     for (const line of lines) {
//       if (line.trim().startsWith('sprite.')) {
//         spriteCommands.push(line.trim());
//       }
//     }
//     return spriteCommands.join('\n');
//   };

//   async function termAction(action, e) {
//     if (action === "onClear") {
//       setOutputArray([{ type: "", value: "" }]);
//     }

//     if (action === "onSubmit") {
//       if (e === "clear" || e === "clear()") {
//         setOutputArray([{ type: "", value: "" }]);
//         return;
//       }

//       if (e === "" || e === undefined || e === null) {
//         return;
//       }

//       let data = { type: "input".toString(), value: e.toString() };
//       console.log(data);
//       setOutputArray((outputArray) => [...outputArray, data]);

//       if (!pyodide) {
//         console.error('Pyodide is not loaded yet.');
//         return;
//       }

//       let namespace = pyodide.globals.get("dict")();
//       pyodide.runPython(
//         `
//     import sys 
//     from pyodide.ffi import to_js
//     from pyodide.console import PyodideConsole, repr_shorten, BANNER
//     import main
//     BANNER = "Welcome to the Pyodide terminal emulator 🐍\\n" + BANNER
//     pyconsole = PyodideConsole(main.dict)
//     import builtins
//     async def await_fut(fut):
//       res = await fut
//       if res is not None:
//         builtins._ = res
//       return to_js([res], depth=1)
//     def clear_console():
//       pyconsole.buffer = []
// `,
//         { globals: namespace }
//       );

//       let await_fut = namespace.get("await_fut");
//       let pyconsole = namespace.get("pyconsole");
//       console.log(pyconsole)

//       pyconsole.stdout_callback = () => {
//         let data = { type: "output".toString(), value: e.toString() };
//         console.log(data)
//         setOutputArray((outputArray) => [...outputArray, data]);
//       };

//       pyconsole.stderr_callback = (s) => {
//         let data = { type: "error".toString(), value: s.toString() };
//         setOutputArray((outputArray) => [...outputArray, data]);
//       };

//       try {
//         let fut = pyconsole.push(e.toString());
//         let result = await await_fut(fut);
//         if (result.length > 0) {
//           if (result[0] !== undefined) {
//             let data = { type: 'output'.toString(), value: repr_shorten(result[0]) };
//             setOutputArray((outputArray) => [...outputArray, data]);
//           }
//         }
//       } catch (err) {
//         let data = { type: 'error'.toString(), value: err.toString() };
//         setOutputArray((outputArray) => [...outputArray, data]);
//       }
//     }

//     if (action === "onCopy") {
//       const lastOutput = outputArray[outputArray.length - 1];
//       if (lastOutput && lastOutput.value) {
//         navigator.clipboard.writeText(lastOutput.value).then(
//           () => console.log('Copied to clipboard'),
//           (err) => console.error('Could not copy: ', err)
//         );
//       }
//     }

//     if (action === "onSearch") {
//       const searchTerm = prompt("Enter the term to search:");
//       if (searchTerm) {
//         const results = outputArray.filter(line => line.value.includes(searchTerm));
//         if (results.length > 0) {
//           console.log('Search results:', results);
//           alert(`Found${results.length}`, results);
//           // Optionally, setFilteredResults(results); if you want to manage state
//           scrollToResult(0); // Scroll to the first result
//         } else {
//           alert('No results found');
//         }
//       }
//     }
//   }

//   const scrollToResult = (index) => {
//     if (index >= 0 && index < outputArray.length) {
//       document.querySelectorAll('.output-line')[index]?.scrollIntoView({
//         behavior: 'smooth',
//         block: 'start',
//       });
//     }
//   };

//   return (
//     <div style={{ display: 'flex', flexGrow: '1' }}>
//       <div id="python-editor-container" style={{ height: '100%', width: '100%' }}>
//         <div style={{ display: isExpanded ? 'none' : 'flex', paddingBottom: '5px', height: '65vh' }}>
//           <Sidebar
//             // style={{maxWidth:'10%', minWidth:'10%'}}
//             toggleOptions1={toggleOptions1}
//             toggleOptions2={toggleOptions2}
//             showOptions1={showOptions1}
//             showOptions2={showOptions2}
//             setCodeInput={setCodeInput}
//             getFiles={setAllFiles}
//             setImageSrc={setImageSrc}
//             codeInput={codeInput}
//           />
//           {imageSrc ? (
//             <div style={{ width: showDebugger ? '80%' : ' 100%', textAlign: 'center', marginTop: '10%' }}>
//               <img src={imageSrc} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
//             </div>
//           ) : (
//             <AceEditor id="show-file"
//               onChange={(newValue) => setCodeInput(newValue)}
//               style={{ width: showDebugger ? '80%' : '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '1%', borderBottomRightRadius: '1%', paddingBottom: '5px' }}
//               placeholder=""
//               mode="python"
//               name="code_editor"
//               height="600px"
//               fontSize={18}
//               editorProps={{ $blockScrolling: true }}
//               showPrintMargin={true}
//               showGutter={true}
//               highlightActiveLine={true}
//               value={codeInput}
//               setOptions={{
//                 enableBasicAutocompletion: true,
//                 enableLiveAutocompletion: true,
//                 enableSnippets: false,
//                 showLineNumbers: true,
//                 tabSize: 2,
//               }}>
//             </AceEditor>
//           )}
//         </div>

//         <div id="python-editor-output" style={{ width: '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }}>
//           <div className='logos' style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
//             <Tab
//               className="react-tabs_react-tabs_tab_1ktU terminal_tab_8e-XY react-tabs_react-tabs__tab--selected_2o0LJ terminal_is-selected_3neEd"
//               id="react-tabs-10"
//               style={{ borderRadius: '5%', width: '75px', backgroundColor: 'rgb(219 227 242)', marginLeft: "10px", border: '1px solid hsla(0, 0%, 0%, 0.15)', height: '35px', display: 'flex' }}
//             >
//               <img draggable={false} src={logsIcon1} style={{ height: '25px', width: '25px', marginTop: '4px' }} />
//               <div style={{ marginTop: '8px', marginLeft: '7px', color: '#2c236c' }}>Logs</div>
//             </Tab>
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//               {/* run button */}
//               <button type="button" onClick={runPyCode}
//                 style={{ borderTopLeftRadius: '5%', borderBottomLeftRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={runIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "7px", color: '#2c236c' }}>Run</div>
//               </button>
//               {/* runn all buttton  */}
//               <button className="btn-text-right" type="button" onClick={handleRunAllClick} style={{ display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={runall} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "7px", color: '#2c236c' }}>Run All</div>
//               </button>
//               {/* stop button */}
//               <button
//                 onClick={handleStopClick}
//                 style={{ borderTopRightRadius: '5%', borderBottomRightRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}  >
//                 <img draggable={false} src={stopIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//                 <div style={{ marginTop: "7px", color: '#2c236c' }}>Stop</div>
//               </button>
//               {/* debugger buttton  */}
//               <button onClick={handleButtonClick} style={{ marginLeft: '10px', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
//                 <img draggable={false} src={debugIcon} alt="Debug Icon" style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
//               </button>

//               {/* expand button  */}
//               <button onClick={handleButtonClick1} style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: 'rgb(219 227 242)', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)' }}>
//                 <img draggable={false} src={isExpanded ? expandBig : expandIcon} style={{ width: '25px', height: '25px', margin: '2px', cursor: 'pointer' }} alt="Expand Icon" />
//               </button>
//             </div>
//           </div>

//           <div style={{ marginLeft: '10px', height: '100%' }}>
//             <div style={{ marginTop: '10px', transition: 'height 0.3s ease', height: isExpanded ? '100%' : '20%', margintop: '20px', maxwidth: '900px' }}>
//               {/* terminal */}
//               <ReactReplView title={''}
//                 tabs={[]}
//                 onSubmit={(e) => termAction("onSubmit", e)}
//                 lines={outputArray}
//                 onChangeTab={(e) => termAction("onChangeTab", e)}
//                 onClear={() => termAction("onClear")}
//                 isExpanded={isExpanded} />
//             </div>
//           </div>
//         </div>
//       </div>
//       {showDebugger && <Debugger />}
//     </div >
//   );
// };

// PythonEditor.propTypes = {
//   vm: PropTypes.object.isRequired,
// };

// const mapStateToProps = state => ({
//   vm: state.scratchGui.vm // Ensure VM is available in props
// });

// export default connect(
//   mapStateToProps,
// )(PythonEditor);


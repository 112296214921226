// const spriteCode = `
//     import math
//     import random 
//     import time

//     class Sprite:
//         def __init__(self, name):
//             self.name = name
//             self.x = 0
//             self.y = 0
//             self.angle = 0
//             self.size = 0
//             self.direction = 0
//             self.number = 0
//             self.secs = 0
//             self.costume_name = None
//             self.backdrop_name = None
 
//         def camera(self, message, number):
//             print(f"{message}")
//             print("sprite."+self.name+".camera."+str(message)+"."+str(number))
//             return message
        
//         def move(self, x):
//             self.x += x
//             print("sprite."+self.name+".move."+str(x))
//             return f"Moved to ({self.x})"

//         def right(self, angle):
//             self.angle += angle
//             print("sprite."+self.name+".right."+str(angle))
//             return f"Turned to {self.angle} degrees"

//         def left(self, angle):
//             self.angle -= angle
//             print("sprite."+self.name+".left."+str(angle))
//             return f"Turned to {self.angle} degrees"

//         def gotoxy(self, x, y):
//             self.x = x
//             self.y = y
//             print("sprite."+self.name+".gotoxy."+str(x)+"."+str(y))
//             return f"Moved to ({self.x}, {self.y})"

//         def goto(self):
//             self.x = random.randint(0, 260)
//             self.y = random.randint(0, 260)
//             print("sprite."+self.name+".goto."+str(0)+"."+str(0))
//             return f"Moved to ({self.x}, {self.y})"

//         def setx(self, x):
//             self.x = x 
//             print("sprite."+self.name+".setx."+str(x))
//             return f"Set X position ({self.x})"

//         def sety(self, y):
//             self.y = y
//             print("sprite."+self.name+".sety."+str(y))
//             return f"Set Y position ({self.y})"

//         def changex(self, distance):
//             self.x += distance * math.cos(math.radians(self.angle))
//             print("sprite."+self.name+".changex."+str(distance))
//             return f"Change X position ({self.x})"

//         def changey(self, distance):
//             self.y += distance * math.sin(math.radians(self.angle))
//             print("sprite."+self.name+".changey."+str(distance))
//             return f"Change X position ({self.y})"

//         def setdirection(self, angle):
//             self.angle = angle
//             print("sprite."+self.name+".setdirection."+str(angle))
//             return f"Turned to {self.angle} degrees"

//         def setsize(self, size):
//             self.size = size
//             print("sprite."+self.name+".setsize."+str(size))
//             return f"Set size to {self.size}"

//         def changesize(self, size):
//             self.size = size
//             print("sprite."+self.name+".changesize."+str(size))
//             return f"Set size to {self.size}"

//         def say(self, message):
//             print(f"{message}")
//             print("sprite."+self.name+".say."+str(message))
//             return message

//         def sayforsecs(self, message, secs):
//             self.secs = secs
//             print("sprite."+self.name+".sayforsecs."+str(message)+"."+str(secs))
//             return message

//         def show(self):
//             print("sprite."+self.name+".show")
//             return "Show sprite"

//         def hide(self):
//             print("sprite."+self.name+".hide")
//             return "Hide sprite"

//         def think(self, message):
//             print("sprite."+self.name+".think."+str(message))
//             return message

//         def thinkforsecs(self, message, secs):
//             self.secs = secs
//             print("sprite."+self.name+".thinkforsecs."+str(message)+"."+str(secs))
//             return message

//         def input(self, question):
//             print("sprite."+self.name+".input."+str(question))
//             return question

//         def switchcostume(self, costume_name):
//             new_costume_name = costume_name
//             self.costume_name = new_costume_name
//             print("sprite."+self.name+".switchcostume."+str(new_costume_name))
//             return

//         def nextcostume(self):
//             print("sprite."+self.name+".nextcostume")
//             return

//         def switchbackdrop(self, backdrop_name):
//             new_backdrop_name = backdrop_name
//             self.backdrop_name = new_backdrop_name
//             print("sprite."+self.name+".switchbackdrop."+str(new_backdrop_name))
//             return

//         def nextbackdrop(self):
//             print("sprite."+self.name+".nextbackdrop")
//             return

//         def iskeypresed(self, key_name):
//             self.key_name = key_name
//             print("sprite."+self.name+".iskeypresed."+str(key_name))
//             return 

//         def setrotationstyle(self, style_name):
//             self.style_name = style_name
//             print("sprite."+self.name+".setrotationstyle."+str(style_name))
//             return 

//         def bounceonedge(self):
//             print("sprite."+self.name+".bounceonedge")
//             return 

//         def X(self):
//             print("sprite."+self.name+".X")
//             return 

//         def Y(self):
//             print("sprite."+self.name+".Y")
//             return 

//         def gotolayer(self, layer_name):
//             self.layer_name = layer_name
//             print("sprite."+self.name+".gotolayer."+str(layer_name))
//             return 

//         def changelayer(self, layer_name, layer_number):
//             self.layer_name = layer_name
//             self.layer_number = layer_number
//             print("sprite."+self.name+".changelayer."+str(layer_name)+"."+str(layer_number))
//             return 

//         def changeeffect(self, effect_value, effect_name):
//             self.effect_name  = effect_name 
//             self.effect_value  = effect_value 
//             print("sprite."+self.name+".changeeffect."+str(effect_name)+"."+str(effect_value))
//             return 

//         def seteffect(self, effect_value, effect_name):
//             self.effect_name  = effect_name 
//             self.effect_value  = effect_value 
//             print("sprite."+self.name+".seteffect."+str(effect_name)+"."+str(effect_value))
//             return 

//         def cleareffects(self):
//             print("sprite."+self.name+".cleareffects")
//             return 

//         def answer(self):
//             print("sprite."+self.name+".answer")
//             return

//     sprite = Sprite('name')

// `;

// export default spriteCode;










const spriteCode = `
    import math
    import random 
    import time

    class Sprite:
        def __init__(self, name):
            self.name = name
            self.x = 0
            self.y = 0
            self.angle = 0
            self.size = 0
            self.direction = 0
            self.number = 0
            self.secs = 0
            self.costume_name = None
            self.backdrop_name = None
        
        def move(self, x):
            self.x += x
            print("sprite."+self.name+".move."+str(x))
            return f"Moved to ({self.x})"

        def right(self, angle):
            self.angle += angle
            print("sprite."+self.name+".right."+str(angle))
            return f"Turned to {self.angle} degrees"

        def left(self, angle):
            self.angle -= angle
            print("sprite."+self.name+".left."+str(angle))
            return f"Turned to {self.angle} degrees"

        def gotoxy(self, x, y):
            self.x = x
            self.y = y
            print("sprite."+self.name+".gotoxy."+str(x)+"."+str(y))
            return f"Moved to ({self.x}, {self.y})"

        def goto(self):
            self.x = random.randint(0, 260)
            self.y = random.randint(0, 260)
            print("sprite."+self.name+".goto."+str(0)+"."+str(0))
            return f"Moved to ({self.x}, {self.y})"

        def setx(self, x):
            self.x = x 
            print("sprite."+self.name+".setx."+str(x))
            return f"Set X position ({self.x})"

        def sety(self, y):
            self.y = y
            print("sprite."+self.name+".sety."+str(y))
            return f"Set Y position ({self.y})"

        def changex(self, distance):
            self.x += distance * math.cos(math.radians(self.angle))
            print("sprite."+self.name+".changex."+str(distance))
            return f"Change X position ({self.x})"

        def changey(self, distance):
            self.y += distance * math.sin(math.radians(self.angle))
            print("sprite."+self.name+".changey."+str(distance))
            return f"Change X position ({self.y})"

        def setdirection(self, angle):
            self.angle = angle
            print("sprite."+self.name+".setdirection."+str(angle))
            return f"Turned to {self.angle} degrees"

        def setsize(self, size):
            self.size = size
            print("sprite."+self.name+".setsize."+str(size))
            return f"Set size to {self.size}"

        def changesize(self, size):
            self.size = size
            print("sprite."+self.name+".changesize."+str(size))
            return f"Set size to {self.size}"

        def say(self, message):
            print(f"{message}")
            print("sprite."+self.name+".say."+str(message))
            return message

        def sayforsecs(self, message, secs):
            self.secs = secs
            print("sprite."+self.name+".sayforsecs."+str(message)+"."+str(secs))
            return message

        def show(self):
            print("sprite."+self.name+".show")
            return "Show sprite"

        def hide(self):
            print("sprite."+self.name+".hide")
            return "Hide sprite"

        def think(self, message):
            print("sprite."+self.name+".think."+str(message))
            return message

        def thinkforsecs(self, message, secs):
            self.secs = secs
            print("sprite."+self.name+".thinkforsecs."+str(message)+"."+str(secs))
            return message

        def input(self, question):
            print("sprite."+self.name+".input."+str(question))
            return question

        def switchcostume(self, costume_name):
            new_costume_name = costume_name
            self.costume_name = new_costume_name
            print("sprite."+self.name+".switchcostume."+str(new_costume_name))
            return

        def nextcostume(self):
            print("sprite."+self.name+".nextcostume")
            return

        def switchbackdrop(self, backdrop_name):
            new_backdrop_name = backdrop_name
            self.backdrop_name = new_backdrop_name
            print("sprite."+self.name+".switchbackdrop."+str(new_backdrop_name))
            return

        def nextbackdrop(self):
            print("sprite."+self.name+".nextbackdrop")
            return

        def iskeypresed(self, key_name):
            self.key_name = key_name
            print("sprite."+self.name+".iskeypresed."+str(key_name))
            return 

        def setrotationstyle(self, style_name):
            self.style_name = style_name
            print("sprite."+self.name+".setrotationstyle."+str(style_name))
            return 

        def bounceonedge(self):
            print("sprite."+self.name+".bounceonedge")
            return 

        def X(self):
            print("sprite."+self.name+".X")
            return 

        def Y(self):
            print("sprite."+self.name+".Y")
            return 

        def gotolayer(self, layer_name):
            self.layer_name = layer_name
            print("sprite."+self.name+".gotolayer."+str(layer_name))
            return 

        def changelayer(self, layer_name, layer_number):
            self.layer_name = layer_name
            self.layer_number = layer_number
            print("sprite."+self.name+".changelayer."+str(layer_name)+"."+str(layer_number))
            return 

        def changeeffect(self, effect_value, effect_name):
            self.effect_name  = effect_name 
            self.effect_value  = effect_value 
            print("sprite."+self.name+".changeeffect."+str(effect_name)+"."+str(effect_value))
            return 

        def seteffect(self, effect_value, effect_name):
            self.effect_name  = effect_name 
            self.effect_value  = effect_value 
            print("sprite."+self.name+".seteffect."+str(effect_name)+"."+str(effect_value))
            return 

        def cleareffects(self):
            print("sprite."+self.name+".cleareffects")
            return 

        def answer(self):
            print("sprite."+self.name+".answer")
            return

    sprite = Sprite('name')

    class Camera:
        def __init__(self):
            pass

        def on(number):
            print("camera.on." + str(number))
            return number

        def off():
            print("camera.off")
            return "camera off "

    camera = Camera()


`;

export default spriteCode;




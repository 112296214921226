// export const handleSpriteCommands = async (results) => {

//     const codeResult = results.result;
//     console.log(codeResult)
//     const command = results.command;
//     console.log(command)
//     const spriteId = results.spriteId;
//     console.log(spriteId)
//     const spriteName = results.spriteName;
//     console.log(spriteName)

//     const lines = command.split('\n');
//     let breakLoop = false;
//     let spriteCreated = false;

//     // Function to simulate async delay for sprite movement
//     const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//     const executeLine = async (line, lineNumber) => {

//         let match;
//         line = line.trim();
//         if (line.startsWith('#')) {
//             console.log('your code has commanted , so not show output')
//             return;
//         }

//         // Check if the line starts with 'if', 'elif', or 'else'
//         if (line.startsWith('if')) {
//             match = line.match(/if (.*):/);
//         } else if (line.startsWith('elif')) {
//             match = line.match(/elif (.*):/);
//         } else if (line.startsWith('else')) {
//             match = line.match(/else:/);
//         }

//         if (match) {
//             // Evaluate the condition (if it's not 'else')
//             let condition;
//             if (line.startsWith('if') || line.startsWith('elif')) {
//                 condition = match[1];
//             }

//             // Execute sprite movement if condition is true or 'else' block is reached
//             if (line.startsWith('if') || line.startsWith('elif')) {
//                 // Execute only if condition is true
//                 if (eval(condition)) {
//                     await handleSpriteMove(lineNumber);
//                     return true; // Indicate that the condition was executed
//                 }
//             } else if (line.startsWith('else')) {
//                 // Execute if no prior condition was true
//                 await handleSpriteMove(lineNumber);
//                 return true;
//             }
//         }
//         else if (line.startsWith('for')) {
//             const match = line.match(/for (\w+) in range\((\d+)\):/);
//             if (match) {
//                 const variable = match[1];
//                 const rangeLimit = parseInt(match[2]);

//                 // Check if a dynamic break condition exists in the line
//                 const breakConditionMatch = lines[lineNumber + 1]?.match(/if (\w+) == (\d+):/);
//                 let breakConditionValue = null;
//                 if (breakConditionMatch) {
//                     breakConditionValue = parseInt(breakConditionMatch[2]); // Dynamic break value
//                 }

//                 for (let x = 0; x < rangeLimit; x++) {
//                     if (breakLoop) break;
//                     globalThis[variable] = x; // Create a variable dynamically in globalThis

//                     // Move the sprite
//                     await delay(500);  // Delay to simulate sprite movement timing
//                     console.log(x);     // Print the loop variable
//                     window.dispatchEvent(new CustomEvent('spriteMove', { detail: 10 }));
//                     window.dispatchEvent(new CustomEvent('spriteNextCostume'));

//                     // Check for dynamic break condition
//                     if (breakConditionValue !== null && x === breakConditionValue) {
//                         breakLoop = true;
//                         break;
//                     }
//                 }
//             }
//         }

//         // Handle 'while' loops
//         else if (line.startsWith('while')) {
//             // Handle 'while' loops
//             const match = line.match(/while (.*):/);
//             if (match) {
//                 const condition = match[1];
//                 // Initialize i if not already defined
//                 if (typeof globalThis.i === 'undefined') {
//                     globalThis.i = 0; // or use a different scope if needed
//                 }

//                 while (eval(condition)) {
//                     // Your movement logic here
//                     window.dispatchEvent(new CustomEvent('spriteMove', { detail: 10 }));
//                     globalThis.i++; // Increment i
//                 }
//             }
//         }

//         else {
//             if (line.includes(`sprite = Sprite(`)) {
//                 // Extract the sprite name from the line
//                 const spriteMatch = line.match(/sprite = Sprite\('([^']+)'\)/);
//                 if (spriteMatch) {
//                     const spriteNameInput = spriteMatch[1]; // Extracted sprite name
//                     const index = spriteName.indexOf(spriteNameInput); // Check if spriteName exists

//                     if (index !== -1) {
//                          globalThis.targetSpriteId = spriteId[index]; // Get corresponding spriteId
//                     } else {
//                         console.error(`Sprite name '${spriteNameInput}' not found.`);
//                     }
//                 }
//                 spriteCreated = true; // Set flag indicating sprite has been created
//                 return; // Exit early since we handled this line
//             } else
//             if (spriteCreated == true && line.includes('sprite.')) {
//             // if (line.includes('sprite.')) {
//                 const match = line.match(/sprite\.(\w+)\((.*)\)/);
//                 if (match) {
//                     const method = match[1];
//                     const args = match[2].split(',');
//                     // Execute sprite command only once
//                     switch (method) {
//                         case 'move':
//                             const X = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteMove', { detail: X }));
//                             console.log(X)
//                             break;
//                         case 'setsize':
//                             const size = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetsize', { detail: size }));
//                             break;
//                         case 'changesize':
//                             const newSize = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangesize', { detail: newSize }));
//                             break;
//                         case 'gotoxy':
//                             const x = parseInt(args[0]);
//                             const y = parseInt(args[1]);
//                             window.dispatchEvent(new CustomEvent('spriteGotoxy', { detail: { x, y } }));
//                             break;
//                         case 'goto':
//                             window.dispatchEvent(new CustomEvent('spriteGoto'));
//                             break;
//                         case 'setx':
//                             const newX = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetx', { detail: newX }));
//                             break;
//                         case 'sety':
//                             const newY = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSety', { detail: newY }));
//                             break;
//                         case 'changex':
//                             const deltaX = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangex', { detail: deltaX }));
//                             break;
//                         case 'changey':
//                             const deltaY = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteChangey', { detail: deltaY }));
//                             break;
//                         case 'setdirection':
//                             const angle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteSetdirection', { detail: angle }));
//                             break;
//                         case 'right':
//                             const rightAngle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteRight', { detail: rightAngle }));
//                             break;
//                         case 'left':
//                             const leftAngle = parseInt(args[0]);
//                             window.dispatchEvent(new CustomEvent('spriteLeft', { detail: leftAngle }));
//                             break;
//                         case 'show':
//                             window.dispatchEvent(new CustomEvent('spriteShow'));
//                             break;
//                         case 'hide':
//                             window.dispatchEvent(new CustomEvent('spriteHide'));
//                             break;
//                         case 'answer':
//                             window.dispatchEvent(new CustomEvent('spriteAnswer'));
//                             break;
//                         case 'say':
//                             const event = new CustomEvent('spriteSay', { detail: codeResult  });
//                             dispatchEvent(event);
//                             break;
//                         case 'sayforsecs':
//                             let sayforsecsSeconds = parseInt(args[1].trim());
//                             const sayforsecsEvent = new CustomEvent('spriteSayForSecs', { detail: { message: codeResult, seconds: sayforsecsSeconds } });
//                             window.dispatchEvent(sayforsecsEvent);
//                             break;
//                         case 'think':
//                             const thinkEvent = new CustomEvent('spriteThink', { detail: codeResult });
//                             window.dispatchEvent(thinkEvent);
//                             break;
//                         case 'thinkforsecs':
//                             let thinkforsecsSeconds = parseInt(args[1].trim());
//                             const thinkforsecsEvent = new CustomEvent('spriteThinkForSecs', { detail: { message: codeResult, seconds: thinkforsecsSeconds } });
//                             window.dispatchEvent(thinkforsecsEvent);
//                             break;
//                         case 'input':
//                             const inputEvent = new CustomEvent('spriteInput', { detail: codeResult });
//                             window.dispatchEvent(inputEvent);
//                             break;
//                         case 'switchcostume':
//                             let costumeName = args[0].trim();
//                             if (costumeName.startsWith("'") && costumeName.endsWith("'")) {
//                                 costumeName = costumeName.substring(1, costumeName.length - 1);
//                             } else if (costumeName.startsWith('"') && costumeName.endsWith('"')) {
//                                 costumeName = costumeName.substring(1, costumeName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSwitchCostume', { detail: costumeName }));
//                             break;
//                         case 'nextcostume':
//                             window.dispatchEvent(new CustomEvent('spriteNextCostume'));
//                             break;
//                         case 'switchbackdrop':
//                             let backdropName = args[0].trim();
//                             if (backdropName.startsWith("'") && backdropName.endsWith("'")) {
//                                 backdropName = backdropName.substring(1, backdropName.length - 1);
//                             } else if (backdropName.startsWith('"') && backdropName.endsWith('"')) {
//                                 backdropName = backdropName.substring(1, backdropName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSwitchBackdrop', { detail: backdropName }));
//                             break;
//                         case 'nextbackdrop':
//                             window.dispatchEvent(new CustomEvent('spriteNextBackdrop'));
//                             break;
//                         case 'iskeypresed':
//                             let keyName = args[0].trim();
//                             if (keyName.startsWith("'") && keyName.endsWith("'")) {
//                                 keyName = keyName.substring(1, keyName.length - 1);
//                             } else if (keyName.startsWith('"') && keyName.endsWith('"')) {
//                                 keyName = keyName.substring(1, keyName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteIsKeyPressed', { detail: keyName }));
//                             break;
//                         case 'setrotationstyle':
//                             let StyleName = args[0].trim();
//                             if (StyleName.startsWith("'") && StyleName.endsWith("'")) {
//                                 StyleName = StyleName.substring(1, StyleName.length - 1);
//                             } else if (StyleName.startsWith('"') && StyleName.endsWith('"')) {
//                                 StyleName = StyleName.substring(1, StyleName.length - 1);
//                             }
//                             window.dispatchEvent(new CustomEvent('spriteSetRotationStyle', { detail: StyleName }));
//                             break;
//                         case 'bounceonedge':
//                             window.dispatchEvent(new CustomEvent('spriteBounceOnEdge'));
//                             break;
//                         case 'X':
//                             window.dispatchEvent(new CustomEvent('spriteX'));
//                             break;
//                         case 'Y':
//                             window.dispatchEvent(new CustomEvent('spriteY'));
//                             break;
//                         case 'gotolayer':
//                             let postition = args[0].trim();
//                             // let layer_number = parseInt(args[1].trim());
//                             if (postition.startsWith("'") && postition.endsWith("'")) {
//                                 postition = postition.substring(1, postition.length - 1);
//                             } else if (postition.startsWith('"') && postition.endsWith('"')) {
//                                 postition = postition.substring(1, postition.length - 1);
//                             }
//                             const gotolayerEvent = new CustomEvent('spriteGoToLayer', { detail: { style: postition } });
//                             window.dispatchEvent(gotolayerEvent);
//                             break;
//                         case 'changelayer':
//                             let layer_change = args[0].trim();
//                             let layer_number = parseInt(args[1].trim());
//                             if (layer_change.startsWith("'") && layer_change.endsWith("'")) {
//                                 layer_change = layer_change.substring(1, layer_change.length - 1);
//                             } else if (layer_change.startsWith('"') && layer_change.endsWith('"')) {
//                                 layer_change = layer_change.substring(1, layer_change.length - 1);
//                             }
//                             const changelayerEvent = new CustomEvent('spriteChangeLayer', { detail: { style: layer_change, number: layer_number } });
//                             window.dispatchEvent(changelayerEvent);
//                             break;
//                         case 'changeeffect':
//                             let effect_name = args[0].trim();
//                             let effect_value = parseInt(args[1].trim());
//                             if (effect_name.startsWith("'") && effect_name.endsWith("'")) {
//                                 effect_name = effect_name.substring(1, effect_name.length - 1);
//                             } else if (effect_name.startsWith('"') && effect_name.endsWith('"')) {
//                                 effect_name = effect_name.substring(1, effect_name.length - 1);
//                             }
//                             const changeeffectEvent = new CustomEvent('spriteChangeEffect', { detail: { name: effect_name, value: effect_value } });
//                             window.dispatchEvent(changeeffectEvent);
//                             break;
//                         case 'seteffect':
//                             let set_effect_name = args[0].trim();
//                             let set_effect_value = parseInt(args[1].trim());
//                             if (set_effect_name.startsWith("'") && set_effect_name.endsWith("'")) {
//                                 set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
//                             } else if (set_effect_name.startsWith('"') && set_effect_name.endsWith('"')) {
//                                 set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
//                             }
//                             const seteffectEvent = new CustomEvent('spriteSetEffect', { detail: { name: set_effect_name, value: set_effect_value } });
//                             window.dispatchEvent(seteffectEvent);
//                             break;
//                         case 'cleareffects':
//                             const cleareffectEvent = new CustomEvent('spriteClearEffect');
//                             window.dispatchEvent(cleareffectEvent);
//                             break;
//                         // Add other sprite methods here
//                         default:
//                             console.error(`Unknown sprite method: ${method}`);
//                     }
//                 }
//             }
//         }

//         //         else {
//         //     // Handle sprite commands
//         //     if (line.includes('sprite.')) {
//         //         // Check if sprite needs to be initialized and the command is to move
//         //         if (typeof sprite === 'undefined') {
//         //             sprite = Sprite(); // Initialize sprite instance
//         //         }

//         //         const match = line.match(/sprite\.(\w+)\((.*)\)/);
//         //         if (match) {
//         //             const method = match[1];
//         //             const args = match[2].split(',');

//         //             // Ensure both initialization and method call are processed together
//         //             if (method === 'move') {
//         //                 const X = parseInt(args[0]);
//         //                 // Now that sprite is initialized, call the move method
//         //                 sprite.move(X);  // Calls the move method on the sprite instance
//         //             }
//         //         }
//         //     }
//         // }
//     };

//     const executeLines = async (lines) => {
//         let delay = 0;

//         for (let i = 0; i < lines.length; i++) {
//             setTimeout(() => {
//                 const line = lines[i].trim();
//                 executeLine(line, i);
//             }, delay);
//             delay += 500; // Adjust delay as needed
//         }
//     };
//     // Start execution
//     await executeLines(lines);
// };



export const handleSpriteCommands = async (results) => {

    const codeResult = results.result;
    console.log(codeResult)
    const command = results.command;
    console.log(command)
    const spriteId = results.spriteId;
    console.log(spriteId)
    const spriteName = results.spriteName;
    console.log(spriteName)

    const lines = command.split('\n');
    let spriteCreated = false;

    const executeLine = async (line) => {

        // let match;
        line = line.trim();
        if (line.startsWith('#')) {
            console.log('your code has commanted , so not show output')
            return;
        }
        else {
            if (line.includes(`sprite = Sprite(`)) {
                // Extract the sprite name from the line
                const spriteMatch = line.match(/sprite = Sprite\('([^']+)'\)/);
                if (spriteMatch) {
                    const spriteNameInput = spriteMatch[1]; // Extracted sprite name
                    const index = spriteName.indexOf(spriteNameInput); // Check if spriteName exists

                    if (index !== -1) {
                        globalThis.targetSpriteId = spriteId[index]; // Get corresponding spriteId
                    } else {
                        console.error(`Sprite name '${spriteNameInput}' not found.`);
                    }
                }
                spriteCreated = true; // Set flag indicating sprite has been created
                return; // Exit early since we handled this line
            } else
                if (spriteCreated == true && line.includes('sprite.')) {
                    // if (line.includes('sprite.')) {
                    const match = line.match(/sprite\.(\w+)\((.*)\)/);
                    if (match) {
                        const method = match[1];
                        const args = match[2].split(',');
                        // Execute sprite command only once
                        switch (method) {
                            case 'move':
                                const X = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteMove', { detail: X }));
                                console.log(X)
                                break;
                            case 'setsize':
                                const size = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteSetsize', { detail: size }));
                                break;
                            case 'changesize':
                                const newSize = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteChangesize', { detail: newSize }));
                                break;
                            case 'gotoxy':
                                const x = parseInt(args[0]);
                                const y = parseInt(args[1]);
                                window.dispatchEvent(new CustomEvent('spriteGotoxy', { detail: { x, y } }));
                                break;
                            case 'goto':
                                window.dispatchEvent(new CustomEvent('spriteGoto'));
                                break;
                            case 'setx':
                                const newX = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteSetx', { detail: newX }));
                                break;
                            case 'sety':
                                const newY = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteSety', { detail: newY }));
                                break;
                            case 'changex':
                                const deltaX = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteChangex', { detail: deltaX }));
                                break;
                            case 'changey':
                                const deltaY = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteChangey', { detail: deltaY }));
                                break;
                            case 'setdirection':
                                const angle = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteSetdirection', { detail: angle }));
                                break;
                            case 'right':
                                const rightAngle = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteRight', { detail: rightAngle }));
                                break;
                            case 'left':
                                const leftAngle = parseInt(args[0]);
                                window.dispatchEvent(new CustomEvent('spriteLeft', { detail: leftAngle }));
                                break;
                            case 'show':
                                window.dispatchEvent(new CustomEvent('spriteShow'));
                                break;
                            case 'hide':
                                window.dispatchEvent(new CustomEvent('spriteHide'));
                                break;
                            case 'answer':
                                window.dispatchEvent(new CustomEvent('spriteAnswer'));
                                break;
                            case 'say':
                                const event = new CustomEvent('spriteSay', { detail: codeResult });
                                dispatchEvent(event);
                                break;
                            case 'sayforsecs':
                                let sayforsecsSeconds = parseInt(args[1].trim());
                                const sayforsecsEvent = new CustomEvent('spriteSayForSecs', { detail: { message: codeResult, seconds: sayforsecsSeconds } });
                                window.dispatchEvent(sayforsecsEvent);
                                break;
                            case 'think':
                                const thinkEvent = new CustomEvent('spriteThink', { detail: codeResult });
                                window.dispatchEvent(thinkEvent);
                                break;
                            case 'thinkforsecs':
                                let thinkforsecsSeconds = parseInt(args[1].trim());
                                const thinkforsecsEvent = new CustomEvent('spriteThinkForSecs', { detail: { message: codeResult, seconds: thinkforsecsSeconds } });
                                window.dispatchEvent(thinkforsecsEvent);
                                break;
                            case 'input':
                                const inputEvent = new CustomEvent('spriteInput', { detail: codeResult });
                                window.dispatchEvent(inputEvent);
                                break;
                            case 'switchcostume':
                                let costumeName = args[0].trim();
                                if (costumeName.startsWith("'") && costumeName.endsWith("'")) {
                                    costumeName = costumeName.substring(1, costumeName.length - 1);
                                } else if (costumeName.startsWith('"') && costumeName.endsWith('"')) {
                                    costumeName = costumeName.substring(1, costumeName.length - 1);
                                }
                                window.dispatchEvent(new CustomEvent('spriteSwitchCostume', { detail: costumeName }));
                                break;
                            case 'nextcostume':
                                window.dispatchEvent(new CustomEvent('spriteNextCostume'));
                                break;
                            case 'switchbackdrop':
                                let backdropName = args[0].trim();
                                if (backdropName.startsWith("'") && backdropName.endsWith("'")) {
                                    backdropName = backdropName.substring(1, backdropName.length - 1);
                                } else if (backdropName.startsWith('"') && backdropName.endsWith('"')) {
                                    backdropName = backdropName.substring(1, backdropName.length - 1);
                                }
                                window.dispatchEvent(new CustomEvent('spriteSwitchBackdrop', { detail: backdropName }));
                                break;
                            case 'nextbackdrop':
                                window.dispatchEvent(new CustomEvent('spriteNextBackdrop'));
                                break;
                            case 'iskeypresed':
                                let keyName = args[0].trim();
                                if (keyName.startsWith("'") && keyName.endsWith("'")) {
                                    keyName = keyName.substring(1, keyName.length - 1);
                                } else if (keyName.startsWith('"') && keyName.endsWith('"')) {
                                    keyName = keyName.substring(1, keyName.length - 1);
                                }
                                window.dispatchEvent(new CustomEvent('spriteIsKeyPressed', { detail: keyName }));
                                break;
                            case 'setrotationstyle':
                                let StyleName = args[0].trim();
                                if (StyleName.startsWith("'") && StyleName.endsWith("'")) {
                                    StyleName = StyleName.substring(1, StyleName.length - 1);
                                } else if (StyleName.startsWith('"') && StyleName.endsWith('"')) {
                                    StyleName = StyleName.substring(1, StyleName.length - 1);
                                }
                                window.dispatchEvent(new CustomEvent('spriteSetRotationStyle', { detail: StyleName }));
                                break;
                            case 'bounceonedge':
                                window.dispatchEvent(new CustomEvent('spriteBounceOnEdge'));
                                break;
                            case 'X':
                                window.dispatchEvent(new CustomEvent('spriteX'));
                                break;
                            case 'Y':
                                window.dispatchEvent(new CustomEvent('spriteY'));
                                break;
                            case 'gotolayer':
                                let postition = args[0].trim();
                                // let layer_number = parseInt(args[1].trim());
                                if (postition.startsWith("'") && postition.endsWith("'")) {
                                    postition = postition.substring(1, postition.length - 1);
                                } else if (postition.startsWith('"') && postition.endsWith('"')) {
                                    postition = postition.substring(1, postition.length - 1);
                                }
                                const gotolayerEvent = new CustomEvent('spriteGoToLayer', { detail: { style: postition } });
                                window.dispatchEvent(gotolayerEvent);
                                break;
                            case 'changelayer':
                                let layer_change = args[0].trim();
                                let layer_number = parseInt(args[1].trim());
                                if (layer_change.startsWith("'") && layer_change.endsWith("'")) {
                                    layer_change = layer_change.substring(1, layer_change.length - 1);
                                } else if (layer_change.startsWith('"') && layer_change.endsWith('"')) {
                                    layer_change = layer_change.substring(1, layer_change.length - 1);
                                }
                                const changelayerEvent = new CustomEvent('spriteChangeLayer', { detail: { style: layer_change, number: layer_number } });
                                window.dispatchEvent(changelayerEvent);
                                break;
                            case 'changeeffect':
                                let effect_name = args[0].trim();
                                let effect_value = parseInt(args[1].trim());
                                if (effect_name.startsWith("'") && effect_name.endsWith("'")) {
                                    effect_name = effect_name.substring(1, effect_name.length - 1);
                                } else if (effect_name.startsWith('"') && effect_name.endsWith('"')) {
                                    effect_name = effect_name.substring(1, effect_name.length - 1);
                                }
                                const changeeffectEvent = new CustomEvent('spriteChangeEffect', { detail: { name: effect_name, value: effect_value } });
                                window.dispatchEvent(changeeffectEvent);
                                break;
                            case 'seteffect':
                                let set_effect_name = args[0].trim();
                                let set_effect_value = parseInt(args[1].trim());
                                if (set_effect_name.startsWith("'") && set_effect_name.endsWith("'")) {
                                    set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
                                } else if (set_effect_name.startsWith('"') && set_effect_name.endsWith('"')) {
                                    set_effect_name = set_effect_name.substring(1, set_effect_name.length - 1);
                                }
                                const seteffectEvent = new CustomEvent('spriteSetEffect', { detail: { name: set_effect_name, value: set_effect_value } });
                                window.dispatchEvent(seteffectEvent);
                                break;
                            case 'cleareffects':
                                const cleareffectEvent = new CustomEvent('spriteClearEffect');
                                window.dispatchEvent(cleareffectEvent);
                                break;
                            // Add other sprite methods here
                            default:
                                console.error(`Unknown sprite method: ${method}`);
                        }
                    }
                }
        }
    };

    // const executeLines = async (lines) => {
    //     let delay = 0;

    //     for (let i = 0; i < lines.length; i++) {
    //         setTimeout(() => {
    //             const line = lines[i].trim();
    //             executeLine(line, i);
    //         }, delay);
    //         delay += 500; // Adjust delay as needed
    //     }
    // };

    const executeLines = async (lines) => {
        const loopStack = []; // Stack to manage nested loops
        const state = {}; // Object to hold variable states
        let delay = 0;
        for (let i = 0; i < lines.length; i++) {
                    setTimeout(() => {
                        const line = lines[i].trim();
                        executeLine(line, i);
                    }, delay);
                    delay += 500; // Adjust delay as needed
                }
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
    
            // Handle for loops
            if (line.startsWith('for')) {
                const loopMatch = line.match(/for\s+(\w+)\s+in\s+range\((\d+),\s*(\d+)\)/);
                if (loopMatch) {
                    const loopVar = loopMatch[1];
                    const start = parseInt(loopMatch[2]);
                    const end = parseInt(loopMatch[3]);
                    loopStack.push({ type: 'for', loopVar, start, end, current: start, lineIndex: i + 1 });
                    continue; // Skip the current line as we are handling the loop
                }
            } 
            // Handle while loops
            else if (line.startsWith('while')) {
                const conditionMatch = line.match(/while\s+(.*):/);
                if (conditionMatch) {
                    const condition = conditionMatch[1].trim();
                    loopStack.push({ type: 'while', condition, lineIndex: i + 1 });
                    continue; // Skip the current line as we are handling the loop
                }
            }
    
            // Check if we are in a loop context
            while (loopStack.length > 0) {
                const currentLoop = loopStack[loopStack.length - 1];
    
                if (currentLoop.type === 'for') {
                    if (currentLoop.current < currentLoop.end) {
                        // Assign the current loop variable to the state
                        state[currentLoop.loopVar] = currentLoop.current;
    
                        // Execute the line within the loop
                        await executeLine(lines[currentLoop.lineIndex]);
                        currentLoop.current++; // Increment the loop variable
                        continue; // Continue to the next iteration of the loop
                    } else {
                        // Exit the loop context
                        loopStack.pop();
                    }
                } else if (currentLoop.type === 'while') {
                    // Evaluate the while condition using the state object
                    const conditionResult = eval(currentLoop.condition.replace(/(\w+)/g, (match) => state[match] !== undefined ? state[match] : match));
                    if (conditionResult) {
                        // Execute the line within the loop
                        await executeLine(lines[currentLoop.lineIndex]);
                        continue; // Continue to the next iteration of the loop
                    } else {
                        // Exit the loop context
                        loopStack.pop();
                    }
                }
            }
    
            // If no active loops, execute the line normally
            await executeLine(line);
        }
    };
    // Start execution
    await executeLines(lines);
};

/* eslint-disable no-shadow */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable require-jsdoc */
// bluetooth-handler.js

// export async function handleBluetoothClick() {
//     try {
//         // Request Bluetooth device
//         const device = await navigator.bluetooth.requestDevice({
//             acceptAllDevices: true,
//             optionalServices: ['d804b643-6ce7-4e81-9f8a-ce0f699085eb'] // Replace with the appropriate UUIDs
//         });

//         console.log(`Selected device: ${device.name}`);

//         // Check if the device has GATT capabilities before trying to connect
//         if (!device.gatt) {
//             throw new Error('Device does not support GATT or has GATT unavailable.');
//         }

//         // Connect to GATT server
//         const server = await device.gatt.connect();
//         console.log('Connected to GATT server:', server);

//         // Access the primary service
//         const service = await server.getPrimaryService('d804b643-6ce7-4e81-9f8a-ce0f699085eb');
//         console.log('Service found:', service);

//         // Access the characteristic
//         const characteristic = await service.getCharacteristic('d804b644-6ce7-4e81-9f8a-ce0f699085eb');
//         console.log('Characteristic found:', characteristic);

//         // Read data from the characteristic
//         const value = await characteristic.readValue();
//         const receivedData = new TextDecoder().decode(value.buffer);
//         console.log('Received data:', receivedData);

//         // Return formatted device info
//         return {
//             name: device.name || 'Unknown Device',
//             peripheralId: device.id,
//             rssi: -50, // Placeholder for RSSI if not directly available
//             receivedData // Add received data if needed
//         };

//     } catch (error) {
//         console.error('Failed to connect to Bluetooth device:', error);
//         alert(`Could not connect to the Bluetooth device. Error: ${error.message}`);
//         return null; // Return null if no device is found or connection fails
//     }
// }



export async function handleBluetoothClick() {
    try {
        // Request a Bluetooth device
        const device = await navigator.bluetooth.requestDevice({
            acceptAllDevices: true, // Accept all devices
            optionalServices: ['c8659212-af91-4ad3-a995-a58d6fd26145','d804b643-6ce7-4e81-9f8a-ce0f699085eb'] // Your service UUID
        });

        console.log(`Selected device: ${device.name}`);

        if (!device.gatt) {
            throw new Error('Device does not support GATT or GATT unavailable.');
        }

        // Connect to the GATT server
        const server = await device.gatt.connect();
        console.log('Connected to GATT server:', server);

        // Get the primary services
        const services = await server.getPrimaryServices();
        console.log('Available services:');
        
        let targetServiceFound = false;

        // Iterate over the available services
        for (const service of services) {
            console.log(`Service UUID: ${service.uuid}`);

            // Check if this is the service you're interested in
            if (service.uuid === 'd804b643-6ce7-4e81-9f8a-ce0f699085eb') {
                targetServiceFound = true;

                // Fetch all characteristics in this service
                const characteristics = await service.getCharacteristics();
                if (characteristics.length === 0) {
                    console.log(`No characteristics found in service: ${service.uuid}`);
                } else {
                    console.log(`Characteristics in service ${service.uuid}:`);
                    characteristics.forEach(characteristic => {
                        console.log(`  Characteristic UUID: ${characteristic.uuid}`);
                    });
                }

                // If you have a characteristic UUID, replace it here and fetch the specific characteristic
                const characteristicUUID = 'c8659212-af91-4ad3-a995-a58d6fd26145'; // Replace with actual characteristic UUID
                const characteristic = await service.getCharacteristic(characteristicUUID);

                console.log('Characteristic found:', characteristic);

                // Read the value from the characteristic
                const value = await characteristic.readValue();
                const receivedData = new TextDecoder().decode(value.buffer);
                console.log('Received data:', receivedData);

                return {
                    name: device.name || 'Unknown Device',
                    peripheralId: device.id,
                    rssi: -50, // Placeholder for RSSI
                    receivedData
                };
            }
        }

        // If no service found
        if (!targetServiceFound) {
            throw new Error(`Service with UUID ${'d804b643-6ce7-4e81-9f8a-ce0f699085eb'} not found.`);
        
    } 
    }
    catch (error) {
        // Handle any errors
        console.error('Failed to connect to Bluetooth device:', error);
        alert(`Could not connect to the Bluetooth device. Error: ${error.message}`);
        return null;
    }
}

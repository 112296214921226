/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import {injectIntl, intlShape} from 'react-intl';
import VM from 'blockzie-vm';
import PythonEditor from '../components/python-editor/python-editor.jsx';
import {connect} from 'react-redux';
import errorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
 
class PythonTab extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleFileUploadClick',
            'handleCostumeUpload',
            'setFileInput'
        ]); 
        const {
            editingTarget,
            sprites,
            stage
        } = props;
        const target = editingTarget && sprites[editingTarget] ? sprites[editingTarget] : stage;
        if (target && target.currentCostume) {
            this.state = {selectedCostumeIndex: target.currentCostume};
        } else {
            this.state = {selectedCostumeIndex: 0};
        }
    }
 
    componentWillReceiveProps (nextProps) {
        const {editingTarget, sprites, stage} = nextProps;
        const target = editingTarget && sprites[editingTarget] ? sprites[editingTarget] : stage;
        if (!target || !target.costumes) {
            return;
        }
        this.setState({selectedCostumeIndex: target.currentCostume});
    }
    handleSelectCostume (costumeIndex) {
        this.props.vm.editingTarget.setCostume(costumeIndex);
        this.setState({selectedCostumeIndex: costumeIndex});
    }
    handleFileUploadClick () {
        this.fileInput.click();
    }

    handleCostumeUpload (e) {
        // Handle file upload logic here if needed
    }

    setFileInput (input) {
        this.fileInput = input;
    }

    render () {
        const {vm} = this.props;

        if (!vm.editingTarget) {
            return null;
        }

        const target = vm.editingTarget.sprite;
        const isStage = vm.editingTarget.isStage;

        const costumeData = target.costumes ? target.costumes.map(costume => ({
            name: costume.name
        })) : [];
        return (
            <div style={{border: '1px solid #e7f0ff', width: '100%',height:'100%'}}>
                <PythonEditor />
            </div>
        );
    }
}

PythonTab.propTypes = {
    editingTarget: PropTypes.string,
    intl: intlShape,
    sprites: PropTypes.shape({
        id: PropTypes.shape({
            costumes: PropTypes.arrayOf(PropTypes.shape({
                url: PropTypes.string,
                name: PropTypes.string.isRequired,
                skinId: PropTypes.number
            }))
        })
    }),
    stage: PropTypes.shape({
        sounds: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired
        }))
    }),
    vm: PropTypes.instanceOf(VM)
};
const mapStateToProps = state => ({
    editingTarget: state.scratchGui.targets.editingTarget,
    sprites: state.scratchGui.targets.sprites,
    stage: state.scratchGui.targets.stage
});

export default errorBoundaryHOC('Python Tab')(
    injectIntl(connect(mapStateToProps)(PythonTab))
);



{/* <PythonEditor selectedCostumeIndex={this.state.selectedCostumeIndex} /> */}
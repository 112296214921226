//.....................................................................//
//***************Old Method to call sprite command ********************//
//.....................................................................//

// import bindAll from 'lodash.bindall'
// import PropTypes from 'prop-types'
// import React from 'react'
// import Renderer from 'scratch-render'
// import VM from 'blockzie-vm'
// import { connect } from 'react-redux'
// import costumeJson from '../lib/libraries/costumes.json'
// // import backdropJson from '../lib/libraries/backdrops.json'
// // import monitor from '../components/monitor/default-monitor.jsx'
// // import Scratch3LooksBlocks from 'blockzie-vm'
// // import RenderedTarget from '../../node_modules/blockzie-vm/src/sprites/rendered-target';

// import { STAGE_DISPLAY_SIZES } from '../lib/layout-constants'
// import { getEventXY } from '../lib/touch-utils'
// import VideoProvider from '../lib/video/video-provider'
// import { BitmapAdapter as V2BitmapAdapter } from 'scratch-svg-renderer'
// import StageComponent from '../components/stage/stage.jsx'
// import pythonEditor from '../components/python-editor/python-editor.jsx'
// import Scratch3LooksBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_looks.js'
// import Scratch3SensingBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_sensing.js';
// import Scratch3ControlBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_control.js';
// import Scratch3MotionBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_motion.js';
// // import stageBlocks from './monitor.jsx';

// import {
//     activateColorPicker,
//     deactivateColorPicker
// } from '../reducers/color-picker';

// const colorPickerRadius = 20;
// const dragThreshold = 3; // Same as the block drag threshold
// const MathUtil = require('../../node_modules/blockzie-vm/src/util/math-util.js');

// class Stage extends React.Component {
//     constructor(props) {
//         super(props);
//         this.looksBlocks = new Scratch3LooksBlocks(props.vm); // assuming runtime is defined
//         this.sensingblocks = new Scratch3SensingBlocks(props.vm);
//         this.controlblocks = new Scratch3ControlBlocks(props.vm);
//         this.motionblocks = new Scratch3MotionBlocks(props.vm);
//         this.count = 0;
//         // this.runtime = runtime;
//         bindAll(this, [
//             'attachMouseEvents',
//             'cancelMouseDownTimeout',
//             'detachMouseEvents',
//             'handleDoubleClick',
//             'handleQuestionAnswered',
//             'onMouseUp',
//             'onMouseMove',
//             'onMouseDown',
//             'onStartDrag',
//             'onStopDrag',
//             'onWheel',
//             'updateRect',
//             'questionListener',
//             'setDragCanvas',
//             'clearDragCanvas',
//             'drawDragCanvas',
//             'positionDragCanvas'
//         ]);
//         this.state = {
//             mouseDownTimeoutId: null,
//             mouseDownPosition: null,
//             isDragging: false,
//             dragOffset: null,
//             dragId: null,
//             colorInfo: null,
//             question: null,
//             answer: ''
//         };
//         if (this.props.vm.renderer) {
//             this.renderer = this.props.vm.renderer;
//             this.canvas = this.renderer.canvas;
//         } else {
//             this.canvas = document.createElement('canvas');
//             this.renderer = new Renderer(this.canvas);
//             this.props.vm.attachRenderer(this.renderer);

//             // Only attach a video provider once because it is stateful
//             this.props.vm.setVideoProvider(new VideoProvider());

//             // Calling draw a single time before any project is loaded just makes
//             // the canvas white instead of solid black–needed because it is not
//             // possible to use CSS to style the canvas to have a different
//             // default color
//             this.props.vm.renderer.draw();
//         }
//         this.props.vm.attachV2BitmapAdapter(new V2BitmapAdapter());
//     }

//     componentDidMount() {
//         this.attachRectEvents();
//         this.attachMouseEvents(this.canvas);
//         this.updateRect();
//         this.props.vm.runtime.addListener('QUESTION', this.questionListener);
//         let count = 1;

//         window.addEventListener('spriteMove', (event) => {
//             const result = event.detail;
//             console.log(count)
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;

//                 const currentX = count * result;
//                 const x = Math.min(currentX, 260);
//                 // const x = Math.min(result, 260);
//                 const y = 0; // Set y to the current y-position or a fixed value for horizontal movement
//                 if (x === 260) {
//                     count = -count;
//                 } else {
//                     count++;
//                 }
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setXY(x, y); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteRight', (event) => {
//             const angle = event.detail;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setDirection(angle + (this.count * angle)); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                     this.count++;
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteLeft', (event) => {
//             const angle = event.detail;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setDirection(angle - (this.count * angle)); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                     this.count++;
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteGotoxy', (event) => {
//             const result = event.detail;
//             const x = result.x;
//             const y = result.y;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setXY(x, y); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteGoto', () => {
//             const x = Math.floor(Math.random() * 260);
//             const y = Math.floor(Math.random() * 260);
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setXY(x, y); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteSetx', (event) => {
//             const result = event.detail;
//             const x = Math.min(result, 260); // Limit x to 260
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     const currentY = targetSprite.y;
//                     // Update the sprite's position directly
//                     targetSprite.setXY(x, currentY); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });;

//         window.addEventListener('spriteSety', (event) => {
//             const result = event.detail;
//             const y = Math.min(result, 260); // Limit x to 260
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     const currentX = targetSprite.x;
//                     // Update the sprite's position directly
//                     targetSprite.setXY(currentX, y); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteChangex', (event) => {
//             const result = event.detail;
//             const x = Math.min(this.count * result, 260); // Limit x to 260
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     const currentY = targetSprite.y;
//                     // Update the sprite's position directly
//                     targetSprite.setXY(x, currentY); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                     this.count++;
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteChangey', (event) => {
//             const result = event.detail;
//             const y = Math.min(this.count * result, 260); // Limit x to 260
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     const currentX = targetSprite.x;
//                     // Update the sprite's position directly
//                     targetSprite.setXY(currentX, y); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                     this.count++;
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteSetdirection', (event) => {
//             const angle = event.detail;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setDirection(angle); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteSetsize', (event) => {
//             const result = event.detail;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setSize(result); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         // window.addEventListener('spriteSize', (event) => {
//         //     const size = event.detail;
//         //     this.props.vm.postSpriteInfo({ size: size });
//         // });

//         window.addEventListener('spriteChangesize', (event) => {
//             const result = event.detail;
//             const size = this.count * result;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setSize(size); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                     this.count++;
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteShow', () => {
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setVisible(true); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                     this.count++;
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteHide', () => {
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setVisible(false); // Assuming setXY is the correct method for updating position

//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                     this.count++;
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteSay', (event) => {
//             const message = event.detail;

//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     const util = { target: targetSprite };
//                     this.looksBlocks.say({ MESSAGE: message }, util);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteSayForSecs', (event) => {
//             const message = event.detail.message;
//             const seconds = event.detail.seconds;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     const util = { target: targetSprite };
//                     this.looksBlocks.sayforsecs({ SECS: seconds, MESSAGE: message }, util);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }

//         });

//         window.addEventListener('spriteThink', (event) => {
//             const message = event.detail;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     const util = { target: targetSprite };
//                     this.looksBlocks.think({ MESSAGE: message }, util);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteThinkForSecs', (event) => {
//             const message = event.detail.message;
//             const seconds = event.detail.seconds;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     const util = { target: targetSprite };
//                     this.looksBlocks.thinkforsecs({ SECS: seconds, MESSAGE: message }, util);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         // window.addEventListener('spriteInput', (event) => {
//         //     const question = event.detail;

//         //     if (globalThis.targetSpriteId) {
//         //         const targetId = globalThis.targetSpriteId;
//         //         const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//         //         if (targetSprite) {
//         //             // Update the sprite's position directly
//         //             // const target = this.props.vm.runtime.target()
//         //             // console.log(target)
//         //             const util = { target: targetSprite };
//         //             this.sensingblocks.askAndWait({ QUESTION: question }, util);
//         //             this.questionListener(question);

//         //             // this.props.vm.runtime.emit('SAY', targetSprite, 'say', '');
//         //         } else {
//         //             console.error(`Sprite with ID: ${targetId} not found.`);
//         //         }
//         //     } else {
//         //         console.error('No target sprite ID is set for movement.');
//         //     }
//         // });

//         window.addEventListener('spriteInput', (event) => {
//             const question = event.detail;

//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);

//                 if (targetSprite) {
//                     const util = { target: targetSprite };

//                     // Add the question to the _questionList and keep a reference to the resolve function
//                     const questionPromise = new Promise(resolve => {
//                         this.sensingblocks._questionList.push([question, resolve, targetSprite, true, false]);
//                     });

//                     // Call askAndWait and handle the answer
//                     this.sensingblocks.askAndWait({ QUESTION: question }, util);

//                     // Ensure that the answer will resolve the promise
//                     questionPromise.then(() => {
//                         this.questionListener(question);
//                     });

//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });


//         window.addEventListener('spriteAnswer', () => {
//             const answer = this.state.answer;
//             console.log(answer) // Assuming getAnswer fetches the answer
//             // this.handleQuestionAnswered(answer);
//             this.sensingblocks._onAnswer({ answer: answer });
//         });

//         window.addEventListener('spriteSwitchCostume', (event) => {
//             const costumeName = event.detail.trim().toLowerCase();  // Normalize the costume name

//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     let matchingCostume = [];
//                     for (let i = 0; i < costumeJson.length; i++) {
//                         const costumeBaseName = costumeJson[i].name.replace(/-.*/, '').trim().toLowerCase();  // Normalize name
//                         const clientBaseName = costumeName.replace(/-.*/, '').trim().toLowerCase();  // Normalize name
//                         if (costumeBaseName === clientBaseName) {
//                             matchingCostume.push(costumeJson[i].name);
//                         }
//                     }

//                     let matchingIndex = -1;
//                     for (let i = 0; i < matchingCostume.length; i++) {
//                         if (matchingCostume[i].trim().toLowerCase() === costumeName) {
//                             matchingIndex = i;
//                             break;
//                         }
//                     }
//                     if (matchingIndex !== -1) {
//                         const selectedCostume = matchingCostume[matchingIndex].trim();
//                         // Find the costume index by name
//                         const costumeIndex = matchingIndex
//                         if (costumeIndex !== -1) {
//                             targetSprite.setCostume(costumeIndex);
//                             console.log(`Costume switched to: ${selectedCostume}`);
//                         } else {
//                             console.log(`Costume "${selectedCostume}" not found.`);
//                         }
//                     } else {
//                         console.log(`No matching costume found for ${costumeName}`);
//                     }
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteNextCostume', () => {
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     if (targetSprite && targetSprite.sprite) {
//                         // Access costumes array directly from target or sprite object
//                         const costumes = targetSprite.sprite.costumes_;  // Try accessing costumes
//                         // Try accessing the current costume index from target
//                         const currentCostumeIndex = targetSprite.currentCostume;  // Alternative way to get current costume index
//                         // Calculate the next costume index
//                         const nextCostumeIndex = (currentCostumeIndex + 1) % costumes.length;
//                         // Set the next costume using index
//                         targetSprite.setCostume(nextCostumeIndex);
//                     } else {
//                         console.error('Target sprite not found or invalid.');
//                     }
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteSwitchBackdrop', (event) => {
//             const backdropName = event.detail.trim();  // Get the backdrop name from Python
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Get the VM (Virtual Machine) instance
//                     const vm = this.props.vm;
//                     // Access the stage directly
//                     const stage = vm.runtime.targets.find(target => target.isStage);
//                     // Get the backdrop index by name
//                     const backdropIndex = stage.getCostumeIndexByName(backdropName);
//                     if (backdropIndex !== -1) {
//                         // Valid backdrop name
//                         stage.setCostume(backdropIndex);
//                     } else {
//                         console.warn(`Backdrop "${backdropName}" not found.`);
//                     }
//                     // Trigger event for backdrop switch if needed
//                     if (this.runtime) {
//                         this.runtime.startHats('event_whenbackdropswitchesto', { BACKDROP: backdropName });
//                     }
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteNextBackdrop', () => {
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Get the VM (Virtual Machine) instance
//                     const vm = this.props.vm;
//                     // Access the stage directly
//                     const stage = vm.runtime.targets.find(target => target.isStage);
//                     const currentBackdropIndex = stage.currentCostume;
//                     const numBackdrops = stage.getCostumes().length;
//                     const nextBackdropIndex = (currentBackdropIndex + 1) % numBackdrops;
//                     stage.setCostume(nextBackdropIndex);
//                     // Handle specific backdrop name
//                     const backdropIndex = stage.getCostumeIndexByName();

//                     if (backdropIndex !== -1) {
//                         stage.setCostume(backdropIndex);
//                     }

//                     // Trigger event for backdrop switch if needed
//                     if (this.runtime) {
//                         this.runtime.startHats('event_whenbackdropswitchesto', { BACKDROP: backdropName });
//                     }
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }

//         });

//         // baki of new targeting code
//         window.addEventListener('spriteIsKeyPressed', (event) => {
//             const keyname = event.detail;  // Get the key name from Python
//             const target = this.props.vm.runtime.targets[1];  // Get the sprite target
//             // console.log(target);

//             // Mock the util object to include ioQuery as expected by getKeyPressed
//             const util = {
//                 ioQuery: (device, func, args) => {
//                     // Check if the key is pressed
//                     if (device === 'keyboard' && func === 'getKeyIsDown') {
//                         return keyname === args[0];  // Return true if the key matches the pressed key
//                     }
//                     return false;
//                 }
//             };

//             // Check if the key pressed is an arrow key to move the sprite
//             const keyPressed = this.sensingblocks.getKeyPressed({ KEY_OPTION: keyname }, util);

//             if (keyPressed) {
//                 // Perform sprite movement based on the key pressed
//                 if (keyname === 'ArrowUp') {
//                     target.setDirection(0);  // Move Up
//                     target.makeclone;
//                 } else if (keyname === 'ArrowDown') {
//                     target.setDirection(180);  // Move Down
//                     target.setSize(10)
//                 } else if (keyname === 'ArrowLeft') {
//                     target.setDirection(-90);  // Move Left
//                 } else if (keyname === 'ArrowRight') {
//                     target.setDirection(90);  // Move Right
//                 }
//                 // Update the stage to reflect the movement
//                 this.props.vm.runtime.requestRedraw();
//             }
//         });

//         window.addEventListener('spriteSetRotationStyle', (event) => {
//             const StyleName = event.detail;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     // Update the sprite's position directly
//                     targetSprite.setRotationStyle(StyleName);
//                     // Optionally, you might want to call a redraw method if the rendering does not happen automatically
//                     this.props.vm.renderer.draw(); // Force a redraw if needed
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteBounceOnEdge', () => {
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     const bounds = targetSprite.getBounds();
//                     if (!bounds) {
//                         return;
//                     }
//                     const stageWidth = this.props.vm.runtime.constructor.STAGE_WIDTH;
//                     const stageHeight = this.props.vm.runtime.constructor.STAGE_HEIGHT;
//                     const distLeft = Math.max(0, (stageWidth / 2) + bounds.left);
//                     const distTop = Math.max(0, (stageHeight / 2) - bounds.top);
//                     const distRight = Math.max(0, (stageWidth / 2) - bounds.right);
//                     const distBottom = Math.max(0, (stageHeight / 2) + bounds.bottom);
//                     // Find the nearest edge.
//                     let nearestEdge = '';
//                     let minDist = Infinity;
//                     if (distLeft < minDist) {
//                         minDist = distLeft;
//                         nearestEdge = 'left';
//                     }
//                     if (distTop < minDist) {
//                         minDist = distTop;
//                         nearestEdge = 'top';
//                     }
//                     if (distRight < minDist) {
//                         minDist = distRight;
//                         nearestEdge = 'right';
//                     }
//                     if (distBottom < minDist) {
//                         minDist = distBottom;
//                         nearestEdge = 'bottom';
//                     }
//                     if (minDist > 0) {
//                         return; // Not touching any edge.
//                     }
//                     // Point away from the nearest edge.
//                     const radians = MathUtil.degToRad(90 - targetSprite.direction);
//                     let dx = Math.cos(radians);
//                     let dy = -Math.sin(radians);
//                     if (nearestEdge === 'left') {
//                         dx = Math.max(0.2, Math.abs(dx));
//                     } else if (nearestEdge === 'top') {
//                         dy = Math.max(0.2, Math.abs(dy));
//                     } else if (nearestEdge === 'right') {
//                         dx = 0 - Math.max(0.2, Math.abs(dx));
//                     } else if (nearestEdge === 'bottom') {
//                         dy = 0 - Math.max(0.2, Math.abs(dy));
//                     }
//                     // Keep within the stage.
//                     const fencedPosition = targetSprite.keepInFence(targetSprite.x, targetSprite.y);
//                     targetSprite.setXY(fencedPosition[0], fencedPosition[1]);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         window.addEventListener('spriteGoToLayer', (event) => {
//             const style = event.detail.style.toLowerCase();
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 if (targetSprite) {
//                     if (!targetSprite.isStage) {
//                         if (style === 'front') {
//                             targetSprite.goToFront();
//                         } else if (style === 'back') {
//                             targetSprite.goToBack();
//                         } else {
//                             console.log('please enter a front or back in command')
//                         }
//                     }
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }

//         });

//         window.addEventListener('spriteChangeLayer', (event) => {
//             const style = event.detail.style.toLowerCase();
//             const number = event.detail.number;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 console.log(targetSprite)
//                 if (targetSprite) {
//                     const util = { target: targetSprite };
//                     this.looksBlocks.goForwardBackwardLayers({ FORWARD_BACKWARD: style, NUM: number }, util);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }

//         });

//         window.addEventListener('spriteChangeEffect', (event) => {
//             const name = event.detail.name;
//             const value = event.detail.value;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 console.log(targetSprite)
//                 if (targetSprite) {
//                     const util = { target: targetSprite };
//                     this.looksBlocks.changeEffect({ CHANGE: value, EFFECT: name }, util);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }

//         });

//         window.addEventListener('spriteSetEffect', (event) => {
//             const name = event.detail.name;
//             const value = event.detail.value;
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 console.log(targetSprite)
//                 if (targetSprite) {
//                     const util = { target: targetSprite };
//                     this.looksBlocks.setEffect({ VALUE: value, EFFECT: name }, util);
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }

//         });

//         window.addEventListener('spriteClearEffect', () => {
//             if (globalThis.targetSpriteId) {
//                 const targetId = globalThis.targetSpriteId;
//                 const targetSprite = this.props.vm.runtime.getTargetById(targetId);
//                 console.log(targetSprite)
//                 if (targetSprite) {
//                     targetSprite.clearEffects();
//                 } else {
//                     console.error(`Sprite with ID: ${targetId} not found.`);
//                 }
//             } else {
//                 console.error('No target sprite ID is set for movement.');
//             }
//         });

//         // window.addEventListener('spriteX', () => {
//         //     this.props.vm.postSpriteInfo({ visible: true });
//         // });

//         // window.addEventListener('spriteY', () => {
//         //     this.props.vm.postSpriteInfo({ visible: true });
//         // });
//     }
//     shouldComponentUpdate(nextProps, nextState) {
//         return this.props.stageSize !== nextProps.stageSize ||
//             this.props.isColorPicking !== nextProps.isColorPicking ||
//             this.state.colorInfo !== nextState.colorInfo ||
//             this.props.isFullScreen !== nextProps.isFullScreen ||
//             this.state.question !== nextState.question ||
//             this.props.micIndicator !== nextProps.micIndicator ||
//             this.props.isStarted !== nextProps.isStarted ||
//             this.props.isRealtimeMode !== nextProps.isRealtimeMode;
//     }
//     componentDidUpdate(prevProps) {
//         if (this.props.isColorPicking && !prevProps.isColorPicking) {
//             this.startColorPickingLoop();
//         } else if (!this.props.isColorPicking && prevProps.isColorPicking) {
//             this.stopColorPickingLoop();
//         }
//         this.updateRect();
//         this.renderer.resize(this.rect.width, this.rect.height);
//     }
//     componentWillUnmount() {
//         this.detachMouseEvents(this.canvas);
//         this.detachRectEvents();
//         this.stopColorPickingLoop();
//         this.props.vm.runtime.removeListener('QUESTION', this.questionListener);
//     }
//     questionListener(question) {
//         this.setState({ question: question });
//     }
//     handleQuestionAnswered(answer) {
//         this.setState({ question: null, answer: answer }, () => {
//             console.log(answer)
//             this.state.answer = answer
//             console.log(this.state.answer)
//             this.props.vm.runtime.emit('ANSWER', answer);
//             // this.props.vm.runtime.emit('SAY', 'say', '');

//         });
//     }
//     startColorPickingLoop() {
//         this.intervalId = setInterval(() => {
//             if (typeof this.pickX === 'number') {
//                 this.setState({ colorInfo: this.getColorInfo(this.pickX, this.pickY) });
//             }
//         }, 30);
//     }
//     stopColorPickingLoop() {
//         clearInterval(this.intervalId);
//     }
//     attachMouseEvents(canvas) {
//         document.addEventListener('mousemove', this.onMouseMove);
//         document.addEventListener('mouseup', this.onMouseUp);
//         document.addEventListener('touchmove', this.onMouseMove);
//         document.addEventListener('touchend', this.onMouseUp);
//         canvas.addEventListener('mousedown', this.onMouseDown);
//         canvas.addEventListener('touchstart', this.onMouseDown);
//         canvas.addEventListener('wheel', this.onWheel);
//     }
//     detachMouseEvents(canvas) {
//         document.removeEventListener('mousemove', this.onMouseMove);
//         document.removeEventListener('mouseup', this.onMouseUp);
//         document.removeEventListener('touchmove', this.onMouseMove);
//         document.removeEventListener('touchend', this.onMouseUp);
//         canvas.removeEventListener('mousedown', this.onMouseDown);
//         canvas.removeEventListener('touchstart', this.onMouseDown);
//         canvas.removeEventListener('wheel', this.onWheel);
//     }
//     attachRectEvents() {
//         window.addEventListener('resize', this.updateRect);
//         window.addEventListener('scroll', this.updateRect);
//     }
//     detachRectEvents() {
//         window.removeEventListener('resize', this.updateRect);
//         window.removeEventListener('scroll', this.updateRect);
//     }
//     updateRect() {
//         this.rect = this.canvas.getBoundingClientRect();
//     }
//     getScratchCoords(x, y) {
//         const nativeSize = this.renderer.getNativeSize();
//         return [
//             (nativeSize[0] / this.rect.width) * (x - (this.rect.width / 2)),
//             (nativeSize[1] / this.rect.height) * (y - (this.rect.height / 2))
//         ];
//     }
//     getColorInfo(x, y) {
//         return {
//             x: x,
//             y: y,
//             ...this.renderer.extractColor(x, y, colorPickerRadius)
//         };
//     }
//     handleDoubleClick(e) {
//         const { x, y } = getEventXY(e);
//         // Set editing target from cursor position, if clicking on a sprite.
//         const mousePosition = [x - this.rect.left, y - this.rect.top];
//         const drawableId = this.renderer.pick(mousePosition[0], mousePosition[1]);
//         if (drawableId === null) return;
//         const targetId = this.props.vm.getTargetIdForDrawableId(drawableId);
//         if (targetId === null) return;
//         this.props.vm.setEditingTarget(targetId);
//     }
//     onMouseMove(e) {
//         const { x, y } = getEventXY(e);
//         const mousePosition = [x - this.rect.left, y - this.rect.top];

//         if (this.props.isColorPicking) {
//             // Set the pickX/Y for the color picker loop to pick up
//             this.pickX = mousePosition[0];
//             this.pickY = mousePosition[1];
//         }

//         if (this.state.mouseDown && !this.state.isDragging) {
//             const distanceFromMouseDown = Math.sqrt(
//                 Math.pow(mousePosition[0] - this.state.mouseDownPosition[0], 2) +
//                 Math.pow(mousePosition[1] - this.state.mouseDownPosition[1], 2)
//             );
//             if (distanceFromMouseDown > dragThreshold) {
//                 this.cancelMouseDownTimeout();
//                 this.onStartDrag(...this.state.mouseDownPosition);
//             }
//         }
//         if (this.state.mouseDown && this.state.isDragging) {
//             // Editor drag style only updates the drag canvas, does full update at the end of drag
//             // Non-editor drag style just updates the sprite continuously.
//             if (this.props.useEditorDragStyle) {
//                 this.positionDragCanvas(mousePosition[0], mousePosition[1]);
//             } else {
//                 const spritePosition = this.getScratchCoords(mousePosition[0], mousePosition[1]);
//                 this.props.vm.postSpriteInfo({
//                     x: spritePosition[0] + this.state.dragOffset[0],
//                     y: -(spritePosition[1] + this.state.dragOffset[1]),
//                     force: true
//                 });
//             }
//         }
//         const coordinates = {
//             x: mousePosition[0],
//             y: mousePosition[1],
//             canvasWidth: this.rect.width,
//             canvasHeight: this.rect.height
//         };
//         this.props.vm.postIOData('mouse', coordinates);
//     }
//     onMouseUp(e) {
//         const { x, y } = getEventXY(e);
//         const mousePosition = [x - this.rect.left, y - this.rect.top];
//         this.cancelMouseDownTimeout();
//         this.setState({
//             mouseDown: false,
//             mouseDownPosition: null
//         });
//         const data = {
//             isDown: false,
//             x: x - this.rect.left,
//             y: y - this.rect.top,
//             canvasWidth: this.rect.width,
//             canvasHeight: this.rect.height,
//             wasDragged: this.state.isDragging
//         };
//         if (this.state.isDragging) {
//             this.onStopDrag(mousePosition[0], mousePosition[1]);
//         }
//         this.props.vm.postIOData('mouse', data);

//         if (this.props.isColorPicking &&
//             mousePosition[0] > 0 && mousePosition[0] < this.rect.width &&
//             mousePosition[1] > 0 && mousePosition[1] < this.rect.height
//         ) {
//             const { r, g, b } = this.state.colorInfo.color;
//             const componentToString = c => {
//                 const hex = c.toString(16);
//                 return hex.length === 1 ? `0${hex}` : hex;
//             };
//             const colorString = `#${componentToString(r)}${componentToString(g)}${componentToString(b)}`;
//             this.props.onDeactivateColorPicker(colorString);
//             this.setState({ colorInfo: null });
//             this.pickX = null;
//             this.pickY = null;
//         }
//     }
//     onMouseDown(e) {
//         this.updateRect();
//         const { x, y } = getEventXY(e);
//         const mousePosition = [x - this.rect.left, y - this.rect.top];
//         if (this.props.isColorPicking) {
//             // Set the pickX/Y for the color picker loop to pick up
//             this.pickX = mousePosition[0];
//             this.pickY = mousePosition[1];
//             // Immediately update the color picker info
//             this.setState({ colorInfo: this.getColorInfo(this.pickX, this.pickY) });
//         } else {
//             if (e.button === 0 || (window.TouchEvent && e instanceof TouchEvent)) {
//                 this.setState({
//                     mouseDown: true,
//                     mouseDownPosition: mousePosition,
//                     mouseDownTimeoutId: setTimeout(
//                         this.onStartDrag.bind(this, mousePosition[0], mousePosition[1]),
//                         400
//                     )
//                 });
//             }
//             const data = {
//                 isDown: true,
//                 x: mousePosition[0],
//                 y: mousePosition[1],
//                 canvasWidth: this.rect.width,
//                 canvasHeight: this.rect.height
//             };
//             this.props.vm.postIOData('mouse', data);
//             if (e.preventDefault) {
//                 // Prevent default to prevent touch from dragging page
//                 e.preventDefault();
//                 // But we do want any active input to be blurred
//                 if (document.activeElement && document.activeElement.blur) {
//                     document.activeElement.blur();
//                 }
//             }
//         }
//     }
//     onWheel(e) {
//         const data = {
//             deltaX: e.deltaX,
//             deltaY: e.deltaY
//         };
//         this.props.vm.postIOData('mouseWheel', data);
//     }
//     cancelMouseDownTimeout() {
//         if (this.state.mouseDownTimeoutId !== null) {
//             clearTimeout(this.state.mouseDownTimeoutId);
//         }
//         this.setState({ mouseDownTimeoutId: null });
//     }
//     /**
//      * Initialize the position of the "dragged sprite" canvas
//      * @param {DrawableExtraction} drawableData The data returned from renderer.extractDrawableScreenSpace
//      * @param {number} x The x position of the initial drag event
//      * @param {number} y The y position of the initial drag event
//      */
//     drawDragCanvas(drawableData, x, y) {
//         const {
//             imageData,
//             x: boundsX,
//             y: boundsY,
//             width: boundsWidth,
//             height: boundsHeight
//         } = drawableData;
//         this.dragCanvas.width = imageData.width;
//         this.dragCanvas.height = imageData.height;
//         // On high-DPI devices, the canvas size in layout-pixels is not equal to the size of the extracted data.
//         this.dragCanvas.style.width = `${boundsWidth}px`;
//         this.dragCanvas.style.height = `${boundsHeight}px`;

//         this.dragCanvas.getContext('2d').putImageData(imageData, 0, 0);
//         // Position so that pick location is at (0, 0) so that  positionDragCanvas()
//         // can use translation to move to mouse position smoothly.
//         this.dragCanvas.style.left = `${boundsX - x}px`;
//         this.dragCanvas.style.top = `${boundsY - y}px`;
//         this.dragCanvas.style.display = 'block';
//     }
//     clearDragCanvas() {
//         this.dragCanvas.width = this.dragCanvas.height = 0;
//         this.dragCanvas.style.display = 'none';
//     }
//     positionDragCanvas(mouseX, mouseY) {
//         // mouseX/Y are relative to stage top/left, and dragCanvas is already
//         // positioned so that the pick location is at (0,0).
//         this.dragCanvas.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
//     }
//     onStartDrag(x, y) {
//         if (this.state.dragId) return;
//         const drawableId = this.renderer.pick(x, y);
//         if (drawableId === null) return;
//         const targetId = this.props.vm.getTargetIdForDrawableId(drawableId);
//         if (targetId === null) return;

//         const target = this.props.vm.runtime.getTargetById(targetId);

//         // Do not start drag unless in editor drag mode or target is draggable
//         if (!(this.props.useEditorDragStyle || target.draggable)) return;

//         // Dragging always brings the target to the front
//         target.goToFront();

//         const [scratchMouseX, scratchMouseY] = this.getScratchCoords(x, y);
//         const offsetX = target.x - scratchMouseX;
//         const offsetY = -(target.y + scratchMouseY);

//         this.props.vm.startDrag(targetId);
//         this.setState({
//             isDragging: true,
//             dragId: targetId,
//             dragOffset: [offsetX, offsetY]
//         });
//         if (this.props.useEditorDragStyle) {
//             const drawableData = this.renderer.extractDrawableScreenSpace(drawableId);
//             this.drawDragCanvas(drawableData, x, y);
//             this.positionDragCanvas(x, y);
//             this.props.vm.postSpriteInfo({ visible: false });
//             this.props.vm.renderer.draw();
//         }
//     }
//     onStopDrag(mouseX, mouseY) {
//         const dragId = this.state.dragId;
//         const commonStopDragActions = () => {
//             this.props.vm.stopDrag(dragId);
//             this.setState({
//                 isDragging: false,
//                 dragOffset: null,
//                 dragId: null
//             });
//         };
//         if (this.props.useEditorDragStyle) {
//             // Need to sequence these actions to prevent flickering.
//             const spriteInfo = { visible: true };
//             // First update the sprite position if dropped in the stage.
//             if (mouseX > 0 && mouseX < this.rect.width &&
//                 mouseY > 0 && mouseY < this.rect.height) {
//                 const spritePosition = this.getScratchCoords(mouseX, mouseY);
//                 spriteInfo.x = spritePosition[0] + this.state.dragOffset[0];
//                 spriteInfo.y = -(spritePosition[1] + this.state.dragOffset[1]);
//                 spriteInfo.force = true;
//             }
//             this.props.vm.postSpriteInfo(spriteInfo);
//             // Then clear the dragging canvas and stop drag (potentially slow if selecting sprite)
//             this.clearDragCanvas();
//             commonStopDragActions();
//             this.props.vm.renderer.draw();
//         } else {
//             commonStopDragActions();
//         }
//     }
//     setDragCanvas(canvas) {
//         this.dragCanvas = canvas;
//     }
//     render() {
//         const {
//             vm, // eslint-disable-line no-unused-vars
//             onActivateColorPicker, // eslint-disable-line no-unused-vars
//             ...props
//         } = this.props;
//         return (
//             <div>
//                 <StageComponent
//                     canvas={this.canvas}
//                     colorInfo={this.state.colorInfo}
//                     dragRef={this.setDragCanvas}
//                     question={this.state.question}
//                     sayText={this.state.sayText}
//                     onDoubleClick={this.handleDoubleClick}
//                     onQuestionAnswered={this.handleQuestionAnswered}
//                     {...props}
//                 />
//                 <pythonEditor
//                     answer={this.state.answer}
//                     onQuestionAnswered={this.handleQuestionAnswered}
//                 />
//             </div>
//         );
//     }
// }

// Stage.propTypes = {
//     isColorPicking: PropTypes.bool,
//     isFullScreen: PropTypes.bool.isRequired,
//     isRealtimeMode: PropTypes.bool,
//     isStarted: PropTypes.bool,
//     micIndicator: PropTypes.bool,
//     onActivateColorPicker: PropTypes.func,
//     onDeactivateColorPicker: PropTypes.func,
//     stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
//     useEditorDragStyle: PropTypes.bool,
//     vm: PropTypes.instanceOf(VM).isRequired
// };

// Stage.defaultProps = {
//     useEditorDragStyle: true
// };

// const mapStateToProps = state => ({
//     isColorPicking: state.scratchGui.colorPicker.active,
//     isFullScreen: state.scratchGui.mode.isFullScreen,
//     isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
//     isStarted: state.scratchGui.vmStatus.started,
//     micIndicator: state.scratchGui.micIndicator,
//     // Do not use editor drag style in fullscreen or player mode.
//     useEditorDragStyle: !(state.scratchGui.mode.isFullScreen || state.scratchGui.mode.isPlayerOnly)
// });

// const mapDispatchToProps = dispatch => ({
//     onActivateColorPicker: () => dispatch(activateColorPicker()),
//     onDeactivateColorPicker: color => dispatch(deactivateColorPicker(color))
// });

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(Stage);



//.....................................................................//
//**************New method to Call Sprite Command *********************//
//.....................................................................//


import bindAll from 'lodash.bindall'
import PropTypes from 'prop-types'
import React from 'react'
import Renderer from 'scratch-render'
import VM from 'blockzie-vm'
import { connect } from 'react-redux'
import costumeJson from '../lib/libraries/costumes.json'
// import Question from './question.jsx'

import { STAGE_DISPLAY_SIZES } from '../lib/layout-constants'
import { getEventXY } from '../lib/touch-utils'
import VideoProvider from '../lib/video/video-provider'
import { BitmapAdapter as V2BitmapAdapter } from 'scratch-svg-renderer'
import StageComponent from '../components/stage/stage.jsx'
import Scratch3LooksBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_looks.js'
import Scratch3SensingBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_sensing.js';
import Scratch3ControlBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_control.js';
import Scratch3MotionBlocks from '../../node_modules/blockzie-vm/src/blocks/scratch3_motion.js';
import Scratch3VideoSensingBlocks from '../../node_modules/blockzie-vm/src/extensions/scratch3_video_sensing/index.js'
import VideoSetup from '../../node_modules/blockzie-vm/src/io/video.js'
// import { requestVideoStream, requestDisableVideo } from '../lib/video/camera.js'
// import eventEmitter from '../components/python-editor/eventEmmiter.js'; // Adjust the import

import {
    activateColorPicker,
    deactivateColorPicker
} from '../reducers/color-picker';

const colorPickerRadius = 20;
const dragThreshold = 3; // Same as the block drag threshold
const MathUtil = require('../../node_modules/blockzie-vm/src/util/math-util.js');

class Stage extends React.Component {
    constructor(props) {
        super(props);
        this.questionListener = this.questionListener.bind(this);
        this.handleSpriteMove = this.handleSpriteMove.bind(this);
        this.handleSpriteRight = this.handleSpriteRight.bind(this);
        this.handleSpriteLeft = this.handleSpriteLeft.bind(this);
        this.handleSpriteGotoxy = this.handleSpriteGotoxy.bind(this);
        this.handleSpriteGoto = this.handleSpriteGoto.bind(this);
        this.handleSpriteSetX = this.handleSpriteSetX.bind(this);
        this.handleSpriteSetY = this.handleSpriteSetY.bind(this);
        this.handleSpriteChangeX = this.handleSpriteChangeX.bind(this);
        this.handleSpriteChangeY = this.handleSpriteChangeY.bind(this);
        this.handleSpriteSetDirection = this.handleSpriteSetDirection.bind(this);
        this.handleSpriteSetSize = this.handleSpriteSetSize.bind(this);
        this.handleSpriteChangeSize = this.handleSpriteChangeSize.bind(this);
        this.handleSpriteShow = this.handleSpriteShow.bind(this);
        this.handleSpriteHide = this.handleSpriteHide.bind(this);
        this.handleSpriteSay = this.handleSpriteSay.bind(this);
        this.handleSpriteSayForSecs = this.handleSpriteSayForSecs.bind(this);
        this.handleSpriteThink = this.handleSpriteThink.bind(this);
        this.handleSpriteThinkForSecs = this.handleSpriteThinkForSecs.bind(this);
        this.handleSpriteInput = this.handleSpriteInput.bind(this);
        this.handleSpriteAnswer = this.handleSpriteAnswer.bind(this);
        this.handleSpriteSwitchCostume = this.handleSpriteSwitchCostume.bind(this);
        this.handleSpriteNextCostume = this.handleSpriteNextCostume.bind(this);
        this.handleSpriteSwitchBackdrop = this.handleSpriteSwitchBackdrop.bind(this);
        this.handleSpriteNextBackdrop = this.handleSpriteNextBackdrop.bind(this);
        this.handleSpriteIsKeyPressed = this.handleSpriteIsKeyPressed.bind(this);
        this.handleSpriteSetRotationStyle = this.handleSpriteSetRotationStyle.bind(this);
        this.handleSpriteBounceOnEdge = this.handleSpriteBounceOnEdge.bind(this);
        this.handleSpriteGoToLayer = this.handleSpriteGoToLayer.bind(this);
        this.handleSpriteChangeLayer = this.handleSpriteChangeLayer.bind(this);
        this.handleSpriteChangeEffect = this.handleSpriteChangeEffect.bind(this);
        this.handleSpriteSetEffect = this.handleSpriteSetEffect.bind(this);
        this.handleSpriteClearEffect = this.handleSpriteClearEffect.bind(this);
        this.handleCameraOn = this.handleCameraOn.bind(this);
        this.handleCameraOff = this.handleCameraOff.bind(this);
        // this.handleSpriteCamera = this.handleSpriteCamera.bind(this);
        // this.handleCameraTransparency = this.handleCameraTransparency.bind(this);
        this.looksBlocks = new Scratch3LooksBlocks(props.vm); // assuming runtime is defined
        this.sensingblocks = new Scratch3SensingBlocks(props.vm);
        this.controlblocks = new Scratch3ControlBlocks(props.vm);
        this.motionblocks = new Scratch3MotionBlocks(props.vm);
        this.videoblocks = new Scratch3VideoSensingBlocks(props.vm);
        this.VideoSetup = new VideoSetup(props.vm);
        this.VideoProvider = new VideoProvider()
        this.questionListener = this.questionListener.bind(this);
        bindAll(this, [
            'attachMouseEvents',
            'cancelMouseDownTimeout',
            'detachMouseEvents',
            'handleDoubleClick',
            'handleQuestionAnswered',
            'onMouseUp',
            'onMouseMove',
            'onMouseDown',
            'onStartDrag',
            'onStopDrag',
            'onWheel',
            'updateRect',
            'questionListener',
            'setDragCanvas',
            'clearDragCanvas',
            'drawDragCanvas',
            'positionDragCanvas'
        ]);
        this.state = {
            mouseDownTimeoutId: null,
            mouseDownPosition: null,
            isDragging: false,
            dragOffset: null,
            dragId: null,
            colorInfo: null,
            question: null,
            answer: ''
        };
        if (this.props.vm.renderer) {
            this.renderer = this.props.vm.renderer;
            this.canvas = this.renderer.canvas;
        } else {
            this.canvas = document.createElement('canvas');
            this.renderer = new Renderer(this.canvas);
            this.props.vm.attachRenderer(this.renderer);

            // Only attach a video provider once because it is stateful
            this.props.vm.setVideoProvider(new VideoProvider());

            // Calling draw a single time before any project is loaded just makes
            // the canvas white instead of solid black–needed because it is not
            // possible to use CSS to style the canvas to have a different
            // default color
            this.props.vm.renderer.draw();
        }
        this.props.vm.attachV2BitmapAdapter(new V2BitmapAdapter());
    }

    componentDidMount() {
        this.attachRectEvents();
        this.attachMouseEvents(this.canvas);
        this.updateRect();
        // this.initializeVideoProvider();
        this.attachEventListeners();
        this.props.vm.runtime.addListener('QUESTION', this.questionListener);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.stageSize !== nextProps.stageSize ||
            this.props.isColorPicking !== nextProps.isColorPicking ||
            this.state.colorInfo !== nextState.colorInfo ||
            this.props.isFullScreen !== nextProps.isFullScreen ||
            this.state.question !== nextState.question ||
            this.props.micIndicator !== nextProps.micIndicator ||
            this.props.isStarted !== nextProps.isStarted ||
            this.props.isRealtimeMode !== nextProps.isRealtimeMode;
    }
    componentDidUpdate(prevProps) {
        if (this.props.isColorPicking && !prevProps.isColorPicking) {
            this.startColorPickingLoop();
        } else if (!this.props.isColorPicking && prevProps.isColorPicking) {
            this.stopColorPickingLoop();
        }
        this.updateRect();
        this.renderer.resize(this.rect.width, this.rect.height);
    }
    componentWillUnmount() {
        this.detachMouseEvents(this.canvas);
        this.detachRectEvents();
        this.stopColorPickingLoop();
        this.detachEventListeners();
        this.props.vm.runtime.removeListener('QUESTION', this.questionListener);
    }

    attachEventListeners() {
        window.addEventListener('spriteMove', this.handleSpriteMove);
        window.addEventListener('spriteRight', this.handleSpriteRight);
        window.addEventListener('spriteLeft', this.handleSpriteLeft);
        window.addEventListener('spriteGotoxy', this.handleSpriteGotoxy);
        window.addEventListener('spriteGoto', this.handleSpriteGoto);
        window.addEventListener('spriteSetx', this.handleSpriteSetX);
        window.addEventListener('spriteSety', this.handleSpriteSetY);
        window.addEventListener('spriteChangex', this.handleSpriteChangeX);
        window.addEventListener('spriteChangey', this.handleSpriteChangeY);
        window.addEventListener('spriteSetdirection', this.handleSpriteSetDirection);
        window.addEventListener('spriteSetsize', this.handleSpriteSetSize);
        window.addEventListener('spriteChangesize', this.handleSpriteChangeSize);
        window.addEventListener('spriteShow', this.handleSpriteShow);
        window.addEventListener('spriteHide', this.handleSpriteHide);
        window.addEventListener('spriteSay', this.handleSpriteSay);
        window.addEventListener('spriteSayForSecs', this.handleSpriteSayForSecs);
        window.addEventListener('spriteThink', this.handleSpriteThink);
        window.addEventListener('spriteThinkForSecs', this.handleSpriteThinkForSecs);
        window.addEventListener('spriteInput', this.handleSpriteInput);
        window.addEventListener('spriteAnswer', this.handleSpriteAnswer);
        window.addEventListener('spriteSwitchCostume', this.handleSpriteSwitchCostume);
        window.addEventListener('spriteNextCostume', this.handleSpriteNextCostume);
        window.addEventListener('spriteSwitchBackdrop', this.handleSpriteSwitchBackdrop);
        window.addEventListener('spriteNextBackdrop', this.handleSpriteNextBackdrop);
        window.addEventListener('spriteIsKeyPressed', this.handleSpriteIsKeyPressed);
        window.addEventListener('spriteSetRotationStyle', this.handleSpriteSetRotationStyle);
        window.addEventListener('spriteBounceOnEdge', this.handleSpriteBounceOnEdge);
        window.addEventListener('spriteGoToLayer', this.handleSpriteGoToLayer);
        window.addEventListener('spriteChangeLayer', this.handleSpriteChangeLayer);
        window.addEventListener('spriteChangeEffect', this.handleSpriteChangeEffect);
        window.addEventListener('spriteSetEffect', this.handleSpriteSetEffect);
        window.addEventListener('spriteClearEffect', this.handleSpriteClearEffect);
        // window.addEventListener('spriteCamera', this.handleSpriteCamera);
        window.addEventListener('CameraOn', this.handleCameraOn);
        window.addEventListener('CameraOff', this.handleCameraOff);
        // window.addEventListener('cameraTransparency', this.handleCameraTransparency);

    }
    detachEventListeners() {
        window.removeEventListener('spriteMove', this.handleSpriteMove);
        window.removeEventListener('spriteRight', this.handleSpriteRight);
        window.removeEventListener('spriteLeft', this.handleSpriteLeft);
        window.removeEventListener('spriteGotoxy', this.handleSpriteGotoxy);
        window.removeEventListener('spriteGoto', this.handleSpriteGoto);
        window.removeEventListener('spriteSetx', this.handleSpriteSetX);
        window.removeEventListener('spriteSety', this.handleSpriteSetY);
        window.removeEventListener('spriteChangex', this.handleSpriteChangeX);
        window.removeEventListener('spriteChangey', this.handleSpriteChangeY);
        window.removeEventListener('spriteSetdirection', this.handleSpriteSetDirection);
        window.removeEventListener('spriteSetsize', this.handleSpriteSetSize);
        window.removeEventListener('spriteChangesize', this.handleSpriteChangeSize);
        window.removeEventListener('spriteShow', this.handleSpriteShow);
        window.removeEventListener('spriteHide', this.handleSpriteHide);
        window.removeEventListener('spriteSay', this.handleSpriteSay);
        window.removeEventListener('spriteSayForSecs', this.handleSpriteSayForSecs);
        window.removeEventListener('spriteThink', this.handleSpriteThink);
        window.removeEventListener('spriteThinkForSecs', this.handleSpriteThinkForSecs);
        window.removeEventListener('spriteInput', this.handleSpriteInput);
        window.removeEventListener('spriteAnswer', this.handleSpriteAnswer);
        window.removeEventListener('spriteSwitchCostume', this.handleSpriteSwitchCostume);
        window.removeEventListener('spriteNextCostume', this.handleSpriteNextCostume);
        window.removeEventListener('spriteSwitchBackdrop', this.handleSpriteSwitchBackdrop);
        window.removeEventListener('spriteNextBackdrop', this.handleSpriteNextBackdrop);
        window.removeEventListener('spriteIsKeyPressed', this.handleSpriteIsKeyPressed);
        window.removeEventListener('spriteSetRotationStyle', this.handleSpriteSetRotationStyle);
        window.removeEventListener('spriteBounceOnEdge', this.handleSpriteBounceOnEdge);
        window.removeEventListener('spriteGoToLayer', this.handleSpriteGoToLayer);
        window.removeEventListener('spriteChangeLayer', this.handleSpriteChangeLayer);
        window.removeEventListener('spriteChangeEffect', this.handleSpriteChangeEffect);
        window.removeEventListener('spriteSetEffect', this.handleSpriteSetEffect);
        window.removeEventListener('spriteClearEffect', this.handleSpriteClearEffect);
        // window.removeEventListener('spriteCamera', this.handleSpriteCamera);
        window.removeEventListener('CameraOn', this.handleCameraOn);
        window.removeEventListener('CameraOff', this.handleCameraOff);
        // window.removeEventListener('cameraTransparency', this.handleCameraTransparency);
    }

    handleSpriteMove = (event) => {
        // debugger
        const result = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            // const target = this.props.vm.runtime.target;
            console.log(targetSprite)
            if (targetSprite) {
                const steps = result;
                console.log(steps)
                const radians = MathUtil.degToRad(90 - targetSprite.direction);
                console.log(radians)
                const dx = steps * Math.cos(radians);
                console.log(dx)
                const dy = steps * Math.sin(radians);
                console.log(dy)
                // debugger
                targetSprite.setXY(targetSprite.x + dx, targetSprite.y + dy);
                // setTimeout(() => {
                //     console.log(`2-second delay after switching costume to index`);
                //     targetSprite.setXY(targetSprite.x + dx, targetSprite.y + dy);
                //                 }, 3000);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteRight = (event) => {
        const angle = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setDirection(targetSprite.direction + angle);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteLeft = (event) => {
        const angle = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setDirection(targetSprite.direction - angle);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteGotoxy = (event) => {
        const { x, y } = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setXY(x, y);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteGoto = () => {
        const x = Math.floor(Math.random() * 260);
        const y = Math.floor(Math.random() * 260);
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setXY(x, y);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSetX = (event) => {
        const result = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const currentY = targetSprite.y;
                targetSprite.setXY(result, currentY);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSetY = (event) => {
        const result = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const currentX = targetSprite.x;
                targetSprite.setXY(currentX, result);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteChangeX = (event) => {
        const result = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setXY(targetSprite.x + result, targetSprite.y);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteChangeY = (event) => {
        const result = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setXY(targetSprite.x, targetSprite.y + result);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSetDirection = (event) => {
        const angle = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setDirection(angle);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSetSize = (event) => {
        const result = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setSize(result);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteChangeSize = (event) => {
        const result = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setSize(targetSprite.setSize + result);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteShow = () => {
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setVisible(true);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteHide = () => {
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setVisible(false);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSay = (event) => {
        const message = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            console.log(targetId)
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const util = { target: targetSprite };
                this.looksBlocks.say({ MESSAGE: message }, util);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSayForSecs = (event) => {
        const message = event.detail.message;
        const seconds = event.detail.seconds;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                // Update the sprite's position directly
                const util = { target: targetSprite };
                this.looksBlocks.sayforsecs({ SECS: seconds, MESSAGE: message }, util);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteThink = (event) => {
        const message = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                // Update the sprite's position directly
                const util = { target: targetSprite };
                this.looksBlocks.think({ MESSAGE: message }, util);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    };

    handleSpriteThinkForSecs = (event) => {
        const message = event.detail.message;
        const seconds = event.detail.seconds;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                // Update the sprite's position directly
                const util = { target: targetSprite };
                this.looksBlocks.thinkforsecs({ SECS: seconds, MESSAGE: message }, util);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    };

    handleSpriteInput = (event) => {
        const question = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const util = { target: targetSprite };
                this.sensingblocks.askAndWait({ QUESTION: question }, util);
                this.questionListener();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteAnswer = () => {
        const answer = this.state.answer; // Assuming answer is stored in state
        this.sensingblocks.getAnswer();
        console.log('answer = ', answer)
    }

    handleSpriteSwitchCostume = (event) => {
        const costumeName = event.detail.trim().toLowerCase();
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                let matchingCostume = [];
                for (let i = 0; i < costumeJson.length; i++) {
                    const costumeBaseName = costumeJson[i].name.replace(/-.*/, '').trim().toLowerCase();
                    const clientBaseName = costumeName.replace(/-.*/, '').trim().toLowerCase();
                    if (costumeBaseName === clientBaseName) {
                        matchingCostume.push(costumeJson[i].name);
                    }
                }

                let matchingIndex = -1;
                for (let i = 0; i < matchingCostume.length; i++) {
                    if (matchingCostume[i].trim().toLowerCase() === costumeName) {
                        matchingIndex = i;
                        break;
                    }
                }
                if (matchingIndex !== -1) {
                    const selectedCostume = matchingCostume[matchingIndex].trim();
                    targetSprite.setCostume(matchingIndex);
                    console.log(`Costume switched to: ${selectedCostume}`);
                } else {
                    console.log(`No matching costume found for ${costumeName}`);
                }
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteNextCostume = () => {
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const costumes = targetSprite.sprite.costumes_;
                const currentCostumeIndex = targetSprite.currentCostume;
                const nextCostumeIndex = (currentCostumeIndex + 1) % costumes.length;
                targetSprite.setCostume(nextCostumeIndex);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSwitchBackdrop = (event) => {
        const backdropName = event.detail.trim();
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const vm = this.props.vm;
                const stage = vm.runtime.targets.find(target => target.isStage);
                const backdropIndex = stage.getCostumeIndexByName(backdropName);
                if (backdropIndex !== -1) {
                    stage.setCostume(backdropIndex);
                } else {
                    console.warn(`Backdrop "${backdropName}" not found.`);
                }
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteNextBackdrop = () => {
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const vm = this.props.vm;
                const stage = vm.runtime.targets.find(target => target.isStage);
                const currentBackdropIndex = stage.currentCostume;
                const numBackdrops = stage.getCostumes().length;
                const nextBackdropIndex = (currentBackdropIndex + 1) % numBackdrops;
                stage.setCostume(nextBackdropIndex);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteIsKeyPressed = (event) => {
        const keyname = event.detail;
        const target = this.props.vm.runtime.targets[1];
        const util = {
            ioQuery: (device, func, args) => {
                if (device === 'keyboard' && func === 'getKeyIsDown') {
                    return keyname === args[0];
                }
                return false;
            }
        };

        const keyPressed = this.sensingblocks.getKeyPressed({ KEY_OPTION: keyname }, util);
        if (keyPressed) {
            if (keyname === 'ArrowUp') {
                target.setDirection(0);
            } else if (keyname === 'ArrowDown') {
                target.setDirection(180);
            } else if (keyname === 'ArrowLeft') {
                target.setDirection(-90);
            } else if (keyname === 'ArrowRight') {
                target.setDirection(90);
            }
            this.props.vm.runtime.requestRedraw();
        }
    }

    handleSpriteSetRotationStyle = (event) => {
        const styleName = event.detail;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.setRotationStyle(styleName);
                this.props.vm.renderer.draw();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteBounceOnEdge = () => {
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const bounds = targetSprite.getBounds();
                console.log("bounds : ", bounds)
                if (!bounds) {
                    return;
                }
                const stageWidth = this.props.vm.runtime.constructor.STAGE_WIDTH;
                console.log("stageWidth : ", stageWidth);
                const stageHeight = this.props.vm.runtime.constructor.STAGE_HEIGHT;
                console.log("stageHeight : ", stageHeight);

                const distLeft = Math.max(0, (stageWidth / 2) + bounds.left);
                console.log("distLeft : ", distLeft);
                const distTop = Math.max(0, (stageHeight / 2) - bounds.top);
                console.log("distTop : ", distTop);
                const distRight = Math.max(0, (stageWidth / 2) - bounds.right);
                console.log("distRight : ", distRight);
                const distBottom = Math.max(0, (stageHeight / 2) + bounds.bottom);
                console.log("distBottom : ", distBottom);
                let nearestEdge = '';
                let minDist = Infinity;
                if (distLeft < minDist) {
                    console.log("If 11111111111111111111111111111111");
                    minDist = distLeft;
                    nearestEdge = 'left';
                }
                if (distTop < minDist) {
                    console.log("If 2222222222222222222222222222222222");
                    minDist = distTop;
                    nearestEdge = 'top';
                }
                if (distRight < minDist) {
                    console.log("If 333333333333333333333333333333333");
                    minDist = distRight;
                    nearestEdge = 'right';
                }
                if (distBottom < minDist) {
                    console.log("If 44444444444444444444444444444444");
                    minDist = distBottom;
                    nearestEdge = 'bottom';
                }
                if (minDist > 0) {
                    return; // Not touching any edge.
                }
                console.log("nearestEdge : ", nearestEdge);

                const radians = MathUtil.degToRad(90 - targetSprite.direction);
                console.log("radians : ", radians);
                let dx = Math.cos(radians);
                console.log("dx : ", dx);
                let dy = -Math.sin(radians);
                console.log("dy : ", dy);
                if (nearestEdge === 'left') {
                    console.log("nearestEdge left ................................");
                    dx = Math.max(0.2, Math.abs(dx));
                } else if (nearestEdge === 'top') {
                    console.log("nearestEdge top ................................");
                    dy = Math.max(0.2, Math.abs(dy));
                } else if (nearestEdge === 'right') {
                    console.log("nearestEdge right ................................");
                    dx = 0 - Math.max(0.2, Math.abs(dx));
                } else if (nearestEdge === 'bottom') {
                    console.log("nearestEdge bottom ................................");
                    dy = 0 - Math.max(0.2, Math.abs(dy));
                }
                const newDirection = MathUtil.radToDeg(Math.atan2(dy, dx)) + 90;
                console.log(newDirection)
                targetSprite.setDirection(newDirection);
                const fencedPosition = targetSprite.keepInFence(targetSprite.x, targetSprite.y);
                console.log("fencedPosition : ", fencedPosition);
                targetSprite.setXY(fencedPosition[0], fencedPosition[1]);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteGoToLayer = (event) => {
        const style = event.detail.style.toLowerCase();
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                if (!targetSprite.isStage) {
                    if (style === 'front') {
                        targetSprite.goToFront();
                    } else if (style === 'back') {
                        targetSprite.goToBack();
                    } else {
                        console.log('Please enter "front" or "back" in command');
                    }
                }
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteChangeLayer = (event) => {
        const style = event.detail.style.toLowerCase();
        const number = event.detail.number;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const util = { target: targetSprite };
                this.looksBlocks.goForwardBackwardLayers({ FORWARD_BACKWARD: style, NUM: number }, util);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteChangeEffect = (event) => {
        const name = event.detail.name;
        const value = event.detail.value;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const util = { target: targetSprite };
                this.looksBlocks.changeEffect({ CHANGE: value, EFFECT: name }, util);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteSetEffect = (event) => {
        const name = event.detail.name;
        const value = event.detail.value;
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                const util = { target: targetSprite };
                this.looksBlocks.setEffect({ VALUE: value, EFFECT: name }, util);
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    handleSpriteClearEffect = (event) => {
        const name = event.detail;
        console.log(name)
        if (globalThis.targetSpriteId) {
            const targetId = globalThis.targetSpriteId;
            const targetSprite = this.props.vm.runtime.getTargetById(targetId);
            if (targetSprite) {
                targetSprite.clearEffects();
            } else {
                console.error(`Sprite with ID: ${targetId} not found.`);
            }
        } else {
            console.error('No target sprite ID is set for movement.');
        }
    }

    // not running camera logic //

    // handleSpriteCamera = (event) => {
    //     const value = event.detail.value.toLowerCase();
    //     // console.log(value, "................................................")
    //     if (globalThis.targetSpriteId) {
    //         const targetId = globalThis.targetSpriteId;
    //         const targetSprite = this.props.vm.runtime.getTargetById(targetId);
    //         // console.log(targetSprite)
    //         if (targetSprite) {
    //             const vm = this.props.vm;
    //             const stage = vm.runtime.targets.find(target => target.isStage);
    //             console.log(stage)
    //             // const provider = vm.setVideoProvider(VideoProvider());
    //             // console.log('video provider : ' , provider)
    //             // const provider1 = vm.setVideoProvider(this.VideoProvider);
    //             // console.log('ex : ', provider1)

    //             // if (!stage.videostate) {
    //             //     stage.videostate = function (state) {
    //             //         if (state === 'on' || state === 'off') {
    //             //             this.videoState = state; // Update the videoState property
    //             //             console.log(`Video state set to: ${state}`);
    //             //         } else {
    //             //             console.error('Invalid video state. Use "on" or "off".');
    //             //         }
    //             //     };
    //             // }

    //             if (value == "on") {
    //                 // this.VideoSetup.enableVideo(this.VideoProvider)
    //                 // const videoDesc = { width: 240, height: 180 }


    //                 // this.VideoProvider.enableVideo().then(() => this.VideoSetup._setupPreview());
    //                 // this.VideoProvider._getWorkspace({
    //                 //     dimensions: [480,360],
    //                 //     mirror: true
    //                 // })

    //                 this.VideoProvider.enableVideo()
    //                     .then(() => {
    //                         // Call _getWorkspace after enableVideo
    //                         this.VideoProvider._getWorkspace({
    //                             dimensions: [480, 360],
    //                             mirror: true
    //                         });
    //                         // console.log('Workspace:', workspace);
                            
    //                         // Call _setupPreview next
    //                         this.VideoSetup._setupPreview();
    //                         this.VideoProvider.getFrame({ dimensions: [480, 360],
    //                             mirror: true , format : 'image-data' , cacheTimeout : 16})
    //                     })
    //                 // requestVideoStream(videoDesc),
    //                 // stage.videostate('on')
    //                 // this.videoblocks.VIDEO_STATE_INFO[1]
    //                 // const video = stage.videoState;
    //                 // console.log(video);
    //                 console.log("on you are ........")
    //             } else if (value == "off") {
    //                 this.VideoSetup.disableVideo()
    //                 this.VideoProvider.disableVideo()
    //                 // requestDisableVideo()
    //                 // stage.videostate('off')

    //                 // this.videoblocks.VIDEO_STATE_INFO[0]
    //                 console.log("off you are .................. ")
    //             } else {
    //                 console.error('Please enter "on" or "off" in command');
    //             }
    //         } else {
    //             console.error(`Sprite with ID: ${targetId} not found.`);
    //         }
    //     } else {
    //         console.error('No target sprite ID is set for movement.');
    //     }
    // }

    // full running camera logic //

    // handleSpriteCamera = (event) => {
    //     const value = event.detail.value.toLowerCase(); // Either "on" or "off"
    //     const vm = this.props.vm;
    
    //     if (!vm) {
    //         console.error("VM instance is missing.");
    //         return;
    //     }
    
    //     const stage = vm.runtime.targets.find(target => target.isStage);
    //     if (!stage) {
    //         console.error("Stage target not found.");
    //         return;
    //     }
    
    //     if (!stage.videoInstance) {
    //         // Initialize the Video and VideoProvider instances
    //         stage.videoProvider = new VideoProvider();
    //         stage.videoInstance = new VideoSetup(vm.runtime);
    //         stage.videoInstance.setProvider(stage.videoProvider);
    //     }
    
    //     const { videoInstance } = stage;
    
    //     if (value === "on") {
    //         // Enable video
    //         videoInstance.enableVideo().then(() => {
    //             console.log("Camera enabled.");
    //         }).catch(err => {
    //             console.error("Failed to enable camera:", err);
    //         });
    //     } else if (value === "off") {
    //         // Disable video
    //         videoInstance.disableVideo();
    //         console.log("Camera disabled.");
    //     } else {
    //         console.warn(`Invalid camera state: ${value}. Expected "on" or "off".`);
    //     }
    // };

    // camera running with transparancy //

    // handleSpriteCamera = (event) => {
    //     const value = event.detail.value.toLowerCase(); // Either "on" or "off"
    //     const number = event.detail.number;
    //     const vm = this.props.vm;
    
    //     if (!vm) {
    //         console.error("VM instance is missing.");
    //         return;
    //     }
    
    //     const stage = vm.runtime.targets.find(target => target.isStage);
    //     if (!stage) {
    //         console.error("Stage target not found.");
    //         return;
    //     }
    
    //     if (!stage.videoInstance) {
    //         // Initialize the Video and VideoProvider instances
    //         stage.videoProvider = new VideoProvider();
    //         stage.videoInstance = new VideoSetup(vm.runtime); // Assuming 'Video' was meant instead of 'VideoSetup'
    //         stage.videoInstance.setProvider(stage.videoProvider);
    //     }
    
    //     const { videoInstance } = stage;
    
    //     if (value === "on") {
    //         // Enable video and optionally set transparency
    //         videoInstance.enableVideo()
    //             .then(() => {
    //                 console.log("Camera enabled.");
    //                 // if (typeof number === "number") {
    //                     // Apply the transparency effect
    //                     console.log("your video trancparancy set has you given ...............................")
    //                     videoInstance.setPreviewGhost(number);
    //                 // }
    //             })
    //             .catch(err => {
    //                 console.error("Error enabling video:", err);
    //             });
    //     } else if (value === "off") {
    //         // Disable the video
    //         videoInstance.disableVideo();
    //         console.log("Camera disabled.");
    //     } else {
    //         console.error(`Invalid value for camera action: ${value}`);
    //     }
    // };

    handleCameraOn = (event) => {
        const number = event.detail?.number; // Transparency value
        console.log(number)
        const vm = this.props.vm;
    
        if (!vm) {
            console.error("VM instance is missing.");
            return;
        }
    
        const stage = vm.runtime.targets.find(target => target.isStage);
        if (!stage) {
            console.error("Stage target not found.");
            return;
        }
    
        if (!stage.videoInstance) {
            // Initialize the Video and VideoProvider instances
            stage.videoProvider = new VideoProvider();
            stage.videoInstance = new VideoSetup(vm.runtime); // Assuming 'VideoSetup' is correct
            stage.videoInstance.setProvider(stage.videoProvider);
        }
    
        const { videoInstance } = stage;
    
        videoInstance.enableVideo()
            .then(() => {
                console.log("Camera enabled.");
                // if (typeof number === "number") {
                    console.log(`Setting video transparency to: ${number}`);
                    videoInstance.setPreviewGhost(number);
                // } else {
                //     console.warn("Transparency value is not a valid number.");
                // }
            })
            .catch(err => {
                console.error("Error enabling video:", err);
            });
    };
    
    handleCameraOff = () => {
        // const value = event.detail.value.toLowerCase(); // Either "on" or "off"
        // const number = event.detail.number;
        const vm = this.props.vm;
    
        if (!vm) {
            console.error("VM instance is missing.");
            return;
        }
    
        const stage = vm.runtime.targets.find(target => target.isStage);
        if (!stage) {
            console.error("Stage target not found.");
            return;
        }
    
        if (!stage.videoInstance) {
            // Initialize the Video and VideoProvider instances
            stage.videoProvider = new VideoProvider();
            stage.videoInstance = new VideoSetup(vm.runtime); // Assuming 'Video' was meant instead of 'VideoSetup'
            stage.videoInstance.setProvider(stage.videoProvider);
        }
    
        const { videoInstance } = stage;
    
            // Disable the video
            videoInstance.disableVideo();
            console.log("Camera disabled.");
        
    };

    questionListener(question) {
        this.setState({ question: question });
    }
    handleQuestionAnswered(answer) {
        this.setState({ question: null, answer: answer }, () => {
            console.log(answer)
            this.props.vm.runtime.emit('ANSWER', answer);
            this.sensingblocks._onAnswer(answer);
            // this.props.vm.runtime.emit('SAY', 'say', '');
            // eventEmitter.emit('questionAnswered', answer);
        });
    }
    startColorPickingLoop() {
        this.intervalId = setInterval(() => {
            if (typeof this.pickX === 'number') {
                this.setState({ colorInfo: this.getColorInfo(this.pickX, this.pickY) });
            }
        }, 30);
    }
    stopColorPickingLoop() {
        clearInterval(this.intervalId);
    }
    attachMouseEvents(canvas) {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('touchmove', this.onMouseMove);
        document.addEventListener('touchend', this.onMouseUp);
        canvas.addEventListener('mousedown', this.onMouseDown);
        canvas.addEventListener('touchstart', this.onMouseDown);
        canvas.addEventListener('wheel', this.onWheel);
    }
    detachMouseEvents(canvas) {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('touchmove', this.onMouseMove);
        document.removeEventListener('touchend', this.onMouseUp);
        canvas.removeEventListener('mousedown', this.onMouseDown);
        canvas.removeEventListener('touchstart', this.onMouseDown);
        canvas.removeEventListener('wheel', this.onWheel);
    }
    // initializeVideoProvider() {
    //     if (!this.VideoProvider) {
    //         this.VideoProvider = new VideoProvider();
    //         const vm = this.props.vm;
    //         vm.setVideoProvider(this.VideoProvider);
    //     }
    // }

    attachRectEvents() {
        window.addEventListener('resize', this.updateRect);
        window.addEventListener('scroll', this.updateRect);
    }
    detachRectEvents() {
        window.removeEventListener('resize', this.updateRect);
        window.removeEventListener('scroll', this.updateRect);
    }
    updateRect() {
        this.rect = this.canvas.getBoundingClientRect();
    }
    getScratchCoords(x, y) {
        const nativeSize = this.renderer.getNativeSize();
        return [
            (nativeSize[0] / this.rect.width) * (x - (this.rect.width / 2)),
            (nativeSize[1] / this.rect.height) * (y - (this.rect.height / 2))
        ];
    }
    getColorInfo(x, y) {
        return {
            x: x,
            y: y,
            ...this.renderer.extractColor(x, y, colorPickerRadius)
        };
    }
    handleDoubleClick(e) {
        const { x, y } = getEventXY(e);
        // Set editing target from cursor position, if clicking on a sprite.
        const mousePosition = [x - this.rect.left, y - this.rect.top];
        const drawableId = this.renderer.pick(mousePosition[0], mousePosition[1]);
        if (drawableId === null) return;
        const targetId = this.props.vm.getTargetIdForDrawableId(drawableId);
        if (targetId === null) return;
        this.props.vm.setEditingTarget(targetId);
    }
    onMouseMove(e) {
        const { x, y } = getEventXY(e);
        const mousePosition = [x - this.rect.left, y - this.rect.top];

        if (this.props.isColorPicking) {
            // Set the pickX/Y for the color picker loop to pick up
            this.pickX = mousePosition[0];
            this.pickY = mousePosition[1];
        }

        if (this.state.mouseDown && !this.state.isDragging) {
            const distanceFromMouseDown = Math.sqrt(
                Math.pow(mousePosition[0] - this.state.mouseDownPosition[0], 2) +
                Math.pow(mousePosition[1] - this.state.mouseDownPosition[1], 2)
            );
            if (distanceFromMouseDown > dragThreshold) {
                this.cancelMouseDownTimeout();
                this.onStartDrag(...this.state.mouseDownPosition);
            }
        }
        if (this.state.mouseDown && this.state.isDragging) {
            // Editor drag style only updates the drag canvas, does full update at the end of drag
            // Non-editor drag style just updates the sprite continuously.
            if (this.props.useEditorDragStyle) {
                this.positionDragCanvas(mousePosition[0], mousePosition[1]);
            } else {
                const spritePosition = this.getScratchCoords(mousePosition[0], mousePosition[1]);
                this.props.vm.postSpriteInfo({
                    x: spritePosition[0] + this.state.dragOffset[0],
                    y: -(spritePosition[1] + this.state.dragOffset[1]),
                    force: true
                });
            }
        }
        const coordinates = {
            x: mousePosition[0],
            y: mousePosition[1],
            canvasWidth: this.rect.width,
            canvasHeight: this.rect.height
        };
        this.props.vm.postIOData('mouse', coordinates);
    }
    onMouseUp(e) {
        const { x, y } = getEventXY(e);
        const mousePosition = [x - this.rect.left, y - this.rect.top];
        this.cancelMouseDownTimeout();
        this.setState({
            mouseDown: false,
            mouseDownPosition: null
        });
        const data = {
            isDown: false,
            x: x - this.rect.left,
            y: y - this.rect.top,
            canvasWidth: this.rect.width,
            canvasHeight: this.rect.height,
            wasDragged: this.state.isDragging
        };
        if (this.state.isDragging) {
            this.onStopDrag(mousePosition[0], mousePosition[1]);
        }
        this.props.vm.postIOData('mouse', data);

        if (this.props.isColorPicking &&
            mousePosition[0] > 0 && mousePosition[0] < this.rect.width &&
            mousePosition[1] > 0 && mousePosition[1] < this.rect.height
        ) {
            const { r, g, b } = this.state.colorInfo.color;
            const componentToString = c => {
                const hex = c.toString(16);
                return hex.length === 1 ? `0${hex}` : hex;
            };
            const colorString = `#${componentToString(r)}${componentToString(g)}${componentToString(b)}`;
            this.props.onDeactivateColorPicker(colorString);
            this.setState({ colorInfo: null });
            this.pickX = null;
            this.pickY = null;
        }
    }
    onMouseDown(e) {
        this.updateRect();
        const { x, y } = getEventXY(e);
        const mousePosition = [x - this.rect.left, y - this.rect.top];
        if (this.props.isColorPicking) {
            // Set the pickX/Y for the color picker loop to pick up
            this.pickX = mousePosition[0];
            this.pickY = mousePosition[1];
            // Immediately update the color picker info
            this.setState({ colorInfo: this.getColorInfo(this.pickX, this.pickY) });
        } else {
            if (e.button === 0 || (window.TouchEvent && e instanceof TouchEvent)) {
                this.setState({
                    mouseDown: true,
                    mouseDownPosition: mousePosition,
                    mouseDownTimeoutId: setTimeout(
                        this.onStartDrag.bind(this, mousePosition[0], mousePosition[1]),
                        400
                    )
                });
            }
            const data = {
                isDown: true,
                x: mousePosition[0],
                y: mousePosition[1],
                canvasWidth: this.rect.width,
                canvasHeight: this.rect.height
            };
            this.props.vm.postIOData('mouse', data);
            if (e.preventDefault) {
                // Prevent default to prevent touch from dragging page
                e.preventDefault();
                // But we do want any active input to be blurred
                if (document.activeElement && document.activeElement.blur) {
                    document.activeElement.blur();
                }
            }
        }
    }
    onWheel(e) {
        const data = {
            deltaX: e.deltaX,
            deltaY: e.deltaY
        };
        this.props.vm.postIOData('mouseWheel', data);
    }
    cancelMouseDownTimeout() {
        if (this.state.mouseDownTimeoutId !== null) {
            clearTimeout(this.state.mouseDownTimeoutId);
        }
        this.setState({ mouseDownTimeoutId: null });
    }
    /**
     * Initialize the position of the "dragged sprite" canvas
     * @param {DrawableExtraction} drawableData The data returned from renderer.extractDrawableScreenSpace
     * @param {number} x The x position of the initial drag event
     * @param {number} y The y position of the initial drag event
     */
    drawDragCanvas(drawableData, x, y) {
        const {
            imageData,
            x: boundsX,
            y: boundsY,
            width: boundsWidth,
            height: boundsHeight
        } = drawableData;
        this.dragCanvas.width = imageData.width;
        this.dragCanvas.height = imageData.height;
        // On high-DPI devices, the canvas size in layout-pixels is not equal to the size of the extracted data.
        this.dragCanvas.style.width = `${boundsWidth}px`;
        this.dragCanvas.style.height = `${boundsHeight}px`;

        this.dragCanvas.getContext('2d').putImageData(imageData, 0, 0);
        // Position so that pick location is at (0, 0) so that  positionDragCanvas()
        // can use translation to move to mouse position smoothly.
        this.dragCanvas.style.left = `${boundsX - x}px`;
        this.dragCanvas.style.top = `${boundsY - y}px`;
        this.dragCanvas.style.display = 'block';
    }
    clearDragCanvas() {
        this.dragCanvas.width = this.dragCanvas.height = 0;
        this.dragCanvas.style.display = 'none';
    }
    positionDragCanvas(mouseX, mouseY) {
        // mouseX/Y are relative to stage top/left, and dragCanvas is already
        // positioned so that the pick location is at (0,0).
        this.dragCanvas.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
    }
    onStartDrag(x, y) {
        if (this.state.dragId) return;
        const drawableId = this.renderer.pick(x, y);
        if (drawableId === null) return;
        const targetId = this.props.vm.getTargetIdForDrawableId(drawableId);
        if (targetId === null) return;

        const target = this.props.vm.runtime.getTargetById(targetId);

        // Do not start drag unless in editor drag mode or target is draggable
        if (!(this.props.useEditorDragStyle || target.draggable)) return;

        // Dragging always brings the target to the front
        target.goToFront();

        const [scratchMouseX, scratchMouseY] = this.getScratchCoords(x, y);
        const offsetX = target.x - scratchMouseX;
        const offsetY = -(target.y + scratchMouseY);

        this.props.vm.startDrag(targetId);
        this.setState({
            isDragging: true,
            dragId: targetId,
            dragOffset: [offsetX, offsetY]
        });
        if (this.props.useEditorDragStyle) {
            const drawableData = this.renderer.extractDrawableScreenSpace(drawableId);
            this.drawDragCanvas(drawableData, x, y);
            this.positionDragCanvas(x, y);
            this.props.vm.postSpriteInfo({ visible: false });
            this.props.vm.renderer.draw();
        }
    }
    onStopDrag(mouseX, mouseY) {
        const dragId = this.state.dragId;
        const commonStopDragActions = () => {
            this.props.vm.stopDrag(dragId);
            this.setState({
                isDragging: false,
                dragOffset: null,
                dragId: null
            });
        };
        if (this.props.useEditorDragStyle) {
            // Need to sequence these actions to prevent flickering.
            const spriteInfo = { visible: true };
            // First update the sprite position if dropped in the stage.
            if (mouseX > 0 && mouseX < this.rect.width &&
                mouseY > 0 && mouseY < this.rect.height) {
                const spritePosition = this.getScratchCoords(mouseX, mouseY);
                spriteInfo.x = spritePosition[0] + this.state.dragOffset[0];
                spriteInfo.y = -(spritePosition[1] + this.state.dragOffset[1]);
                spriteInfo.force = true;
            }
            this.props.vm.postSpriteInfo(spriteInfo);
            // Then clear the dragging canvas and stop drag (potentially slow if selecting sprite)
            this.clearDragCanvas();
            commonStopDragActions();
            this.props.vm.renderer.draw();
        } else {
            commonStopDragActions();
        }
    }
    setDragCanvas(canvas) {
        this.dragCanvas = canvas;
    }
    render() {
        const {
            vm, // eslint-disable-line no-unused-vars
            onActivateColorPicker, // eslint-disable-line no-unused-vars
            ...props
        } = this.props;
        return (
            <div>
                <StageComponent
                    canvas={this.canvas}
                    colorInfo={this.state.colorInfo}
                    dragRef={this.setDragCanvas}
                    question={this.state.question}
                    sayText={this.state.sayText}
                    onDoubleClick={this.handleDoubleClick}
                    onQuestionAnswered={this.handleQuestionAnswered}
                    {...props}
                />
            </div>
        );
    }
}

Stage.propTypes = {
    isColorPicking: PropTypes.bool,
    isFullScreen: PropTypes.bool.isRequired,
    isRealtimeMode: PropTypes.bool,
    isStarted: PropTypes.bool,
    micIndicator: PropTypes.bool,
    onActivateColorPicker: PropTypes.func,
    onDeactivateColorPicker: PropTypes.func,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    useEditorDragStyle: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

Stage.defaultProps = {
    useEditorDragStyle: true
};

const mapStateToProps = state => ({
    isColorPicking: state.scratchGui.colorPicker.active,
    isFullScreen: state.scratchGui.mode.isFullScreen,
    isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
    isStarted: state.scratchGui.vmStatus.started,
    micIndicator: state.scratchGui.micIndicator,
    // Do not use editor drag style in fullscreen or player mode.
    useEditorDragStyle: !(state.scratchGui.mode.isFullScreen || state.scratchGui.mode.isPlayerOnly)
});

const mapDispatchToProps = dispatch => ({
    onActivateColorPicker: () => dispatch(activateColorPicker()),
    onDeactivateColorPicker: color => dispatch(deactivateColorPicker(color))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stage);
import React from 'react';
import {FormattedMessage} from 'react-intl';
import defaultsDeep from 'lodash.defaultsdeep';
import log from '../../log';
import {DeviceType} from '../../device';

import arduinoBaseToolBox from './baseToolbox/arduino';
import microbitBaseToolBox from './baseToolbox/microbit';

import unselectDeviceIconURL from './unselectDevice/unselectDevice.png';

import arduinoUnoIconURL from './arduinoUno/arduinoUno.png';
import arduinoUnoConnectionIconURLL from './arduinoUno/arduinoUno-illustration.svg';
import arduinoUnoConnectionSmallIconURL from './arduinoUno/arduinoUno-small.svg';

import arduinoNanoIconURL from './arduinoNano/arduinoNano.png';
import arduinoNanoConnectionIconURLL from './arduinoNano/arduinoNano-illustration.svg';
import arduinoNanoConnectionSmallIconURL from './arduinoNano/arduinoNano-small.svg';

import arduinoNanoIconURLOLD from './arduinoNano1/arduinoNano.png';
import arduinoNanoConnectionIconURLLOLD from './arduinoNano1/arduinoNano-illustration.svg';
import arduinoNanoConnectionSmallIconURLOLD from './arduinoNano1/arduinoNano-small.svg';

import arduinoMiniIconURL from './arduinoMini/arduinoMini.png';
import arduinoMiniConnectionIconURLL from './arduinoMini/arduinoMini-illustration.svg';
import arduinoMiniConnectionSmallIconURL from './arduinoMini/arduinoMini-small.svg';

import arduinoLeonardoIconURL from './arduinoLeonardo/arduinoLeonardo.png';
import arduinoLeonardoConnectionIconURLL from './arduinoLeonardo/arduinoLeonardo-illustration.svg';
import arduinoLeonardoConnectionSmallIconURL from './arduinoLeonardo/arduinoLeonardo-small.svg';

import arduinoMega2560IconURL from './arduinoMega2560/arduinoMega2560.png';
import arduinoMega2560ConnectionIconURLL from './arduinoMega2560/arduinoMega2560-illustration.svg';
import arduinoMega2560ConnectionSmallIconURL from './arduinoMega2560/arduinoMega2560-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitConnectionIconURLL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import microbitV2IconURL from './microbitV2/microbitV2.png';
import microbitV2ConnectionIconURLL from './microbitV2/microbitV2-illustration.svg';
import microbitV2ConnectionSmallIconURL from './microbitV2/microbitV2-small.svg';

import esp32IconURL from './esp32/esp32.png';
import esp32ConnectionIconURLL from './esp32/esp32-illustration.svg';
import esp32ConnectionSmallIconURL from './esp32/esp32-small.svg';

import esp8266IconURL from './esp8266/esp8266.png';
import esp8266ConnectionIconURL from './esp8266/esp8266-illustration.svg';
import esp8266ConnectionSmallIconURL from './esp8266/esp8266-small.svg';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyConnectionIconURL from './makeymakey/makeymakey-illustration.svg';
import makeymakeyConnectionSmallIconURL from './makeymakey/makeymakey-small.svg';

import intermediateKitIconURL from './intermediateKit/intermediateKit.png';
import intermediateKitConnectionIconURL from './intermediateKit/Intermediate kit - illustration.svg';
import intermediateKitConnectionSmallIconURL from './intermediateKit/Intermediate kit-small.svg';

import iotAiKitIconURL from './iotAiKit/iotAiKit.png';
import iotAiKitConnectionIconURL from './iotAiKit/iotAiKit-illustration.svg';
import iotAiKitConnectionSmallIconURL from './iotAiKit/iotAiKit-small.svg';


import raspberrypiIconURL from './raspberrypi/raspberryPi.png';
import raspberrypiConnectionIconURL from './raspberrypi/raspberry-pi-illustration.svg';
import raspberrypiConnectionSmallIconURL from './raspberrypi/raspberry-pi-small.svg';

import raspberryPiPicoIconURL from './raspberryPiPico/raspberryPiPico.png';
import raspberryPiPicoConnectionIconURL from './raspberryPiPico/raspberryPiPico-illustration.svg';
import raspberryPiPicoConnectionSmallIconURL from './raspberryPiPico/raspberryPiPico-small.svg';

import ottoRobotIconURL from './ottoRobot/ottoRobot.png';
import ottoRobotConnectionIconURL from './ottoRobot/ottoRobot-illustration.svg';
import ottoRobotConnectionSmallIconURL from './ottoRobot/ottoRobot-small.svg';

const deviceData = [
    /**
     * Unselect the deivce back to pure scratch mode
     */
    {
        name: (
            <FormattedMessage
                defaultMessage="Unselect deivce"
                description="Name for the unselect deivce"
                id="gui.device.unselectDevice.name"
            />
        ),
        deviceId: 'unselectDevice',
        iconURL: unselectDeviceIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Unselect the deivce, return to pure realtime programming mode."
                description="Description for the unselect deivce"
                id="gui.device.unselectDevice.description"
            />
        ),
        featured: true,
        // hide: true,
        programMode: ['realtime'],
        programLanguage: ['block'],
        tags: ['realtime']
    },
    {
        name: 'Otto Robot',
        deviceId: 'ottoRobot',
        manufactor: 'arduino.cc',
        leanMore: 'https://store.arduino.cc/usa/arduino-uno-rev3',
        type: 'arduino',
        iconURL: ottoRobotIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Otto Robot, get started with robot project."
                description="Description for the Otto Robot"
                id="gui.device.OttoRobot.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ottoRobotConnectionIconURL,
        connectionSmallIconURL: ottoRobotConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their arduino."
                id="gui.device.arduino.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['realtime', 'upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['robot'],
        helpLink: 'https://store.arduino.cc/usa/arduino-uno-rev3'
    },
    {
        name: 'Robotics & AI kit',
        deviceId: 'intermediateKit',
        manufactor: 'Shriji Technoaspire Pvt ltd',
        learnMore: 'https://example.com/intermediate-kit', // Replace with actual link
        type: 'arduino', // Adjust as necessary
        iconURL: intermediateKitIconURL, // Replace with actual icon URL
        description: (
            <FormattedMessage
                defaultMessage="A versatile kit for advancing your electronics and programming skills."
                description="Description for the Intermediate Kit device"
                id="gui.device.intermediateKit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false, // Adjust as necessary
        serialportRequired: true, // Adjust as necessary
        defaultBaudRate: '9600', // Replace with actual baud rate if different
        internetConnectionRequired: false, // Adjust as necessary
        launchPeripheralConnectionFlow: true,
        useAutoScan: false, // Adjust as necessary
        connectionIconURL: intermediateKitConnectionIconURL, // Replace with actual connection icon URL
        connectionSmallIconURL: intermediateKitConnectionSmallIconURL, // Replace with actual small icon URL
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Intermediate Kit."
                id="gui.device.intermediateKit.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox, // Replace with actual toolbox XML
        programMode: ['realtime', 'upload'], // Adjust as necessary
        programLanguage: ['block', 'c', 'cpp'], // Adjust as necessary
        tags: ['kit', 'electronics'], // Adjust as necessary
        helpLink: 'https://example.com/intermediate-kit-help' // Replace with actual help link
    },
    {
        name: 'IOT & AI Kit',
        deviceId: 'iotAiKit',
        manufactor: 'Shriji Technoaspire Pvt ltd', // Replace with actual manufacturer
        learnMore: 'https://example.com/iot-ai-kit', // Replace with actual link
        type: 'arduino', // Adjust as necessary
        iconURL: iotAiKitIconURL, // Replace with actual icon URL
        description: (
            <FormattedMessage
                defaultMessage="A comprehensive kit for learning IoT and AI technologies."
                description="Description for the IOT & AI Kit device"
                id="gui.device.iotAiKit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false, // Adjust as necessary
        serialportRequired: true, // Adjust as necessary
        defaultBaudRate: '115200', // Replace with actual baud rate if different
        internetConnectionRequired: false, // Adjust as necessary
        launchPeripheralConnectionFlow: true,
        useAutoScan: false, // Adjust as necessary
        connectionIconURL: iotAiKitConnectionIconURL, // Replace with actual connection icon URL
        connectionSmallIconURL: iotAiKitConnectionSmallIconURL, // Replace with actual small icon URL
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their IOT & AI Kit."
                id="gui.device.iotAiKit.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox, // Replace with actual toolbox XML
        programMode: ['realtime', 'upload'], // Adjust as necessary
        programLanguage: ['block', 'c', 'cpp'], // Adjust as necessary
        tags: ['iot', 'ai', 'kit'], // Adjust as necessary
        helpLink: 'https://example.com/iot-ai-kit-help' // Replace with actual help link
    },
    // {
    //     name: 'HumanoidRobot',
    //     deviceId: 'humanoidRobot',
    //     manufactor: 'Shriji Technoaspire Pvt ltd', // replace with actual manufacturer
    //     learnMore: 'https://link-to-humanoidRobot-details', // replace with actual URL
    //     type: DeviceType.arduino,
    //     iconURL: humanoidRobotIconURL, // replace with actual icon URL
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="An advanced humanoid robot for learning and exploration."
    //             description="Description for the HumanoidRobot device"
    //             id="gui.device.humanoidRobot.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     serialportRequired: true,
    //     defaultBaudRate: '115200', // replace with HumanoidRobot's baud rate
    //     internetConnectionRequired: false,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: false,
    //     connectionIconURL: humanoidRobotConnectionIconURL, // replace with actual connection icon URL
    //     connectionSmallIconURL: humanoidRobotConnectionSmallIconURL, // replace with actual small icon URL
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="Connecting"
    //             description="Message to help people connect to their HumanoidRobot."
    //             id="gui.device.humanoidRobot.connectingMessage"
    //         />
    //     ),
    //     baseToolBoxXml: arduinoBaseToolBox, // replace with HumanoidRobot's toolbox XML
    //     programMode: ['realtime', 'upload'],
    //     programLanguage: ['block', 'c', 'cpp'], // replace with applicable languages
    //     tags: ['robot'],
    //     helpLink: 'https://link-to-humanoidRobot-help' // replace with actual help link
    // },
    
    
    {
        name: 'Robotics & AI kit',
        deviceId: 'intermediateKit',
        manufactor: 'Shriji Technoaspire Pvt ltd',  // Replace with actual manufacturer
        learnMore: 'https://example.com/intermediate-kit',  // Replace with actual link
        type: DeviceType.arduino,  // Adjust as necessary
        iconURL: intermediateKitIconURL,  // Replace with actual icon URL
        description: (
            <FormattedMessage
                defaultMessage="A versatile kit for advancing your electronics and programming skills."
                description="Description for the Intermediate Kit device"
                id="gui.device.intermediateKit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,  // Adjust as necessary
        serialportRequired: true,  // Adjust as necessary
        defaultBaudRate: '9600',  // Replace with actual baud rate if different
        internetConnectionRequired: false,  // Adjust as necessary
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,  // Adjust as necessary
        connectionIconURL: intermediateKitConnectionIconURL,  // Replace with actual connection icon URL
        connectionSmallIconURL: intermediateKitConnectionSmallIconURL,  // Replace with actual small icon URL
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Intermediate Kit."
                id="gui.device.intermediateKit.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,  // Replace with actual toolbox XML
        programMode: ['realtime', 'upload'],  // Adjust as necessary
        programLanguage: ['block', 'c', 'cpp'],  // Adjust as necessary
        tags: ['kit', 'electronics'],  // Adjust as necessary
        helpLink: 'https://example.com/intermediate-kit-help'  // Replace with actual help link
    },
    
    {
        name: 'IOT & AI Kit',
        deviceId: 'iotAiKit',
        manufactor: 'Shriji Technoaspire Pvt ltd',  // Replace with actual manufacturer
        learnMore: 'https://example.com/iot-ai-kit',  // Replace with actual link
        type: DeviceType.arduino,  // Adjust as necessary
        iconURL: iotAiKitIconURL,  // Replace with actual icon URL
        description: (
            <FormattedMessage
                defaultMessage="A comprehensive kit for learning IoT and AI technologies."
                description="Description for the IOT & AI Kit device"
                id="gui.device.iotAiKit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,  // Adjust as necessary
        serialportRequired: true,  // Adjust as necessary
        defaultBaudRate: '115200',  // Replace with actual baud rate if different
        internetConnectionRequired: false,  // Adjust as necessary
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,  // Adjust as necessary
        connectionIconURL: iotAiKitConnectionIconURL,  // Replace with actual connection icon URL
        connectionSmallIconURL: iotAiKitConnectionSmallIconURL,  // Replace with actual small icon URL
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their IOT & AI Kit."
                id="gui.device.iotAiKit.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,  // Replace with actual toolbox XML
        programMode: ['realtime', 'upload'],  // Adjust as necessary
        programLanguage: ['block', 'c', 'cpp'],  // Adjust as necessary
        tags: ['iot', 'ai', 'kit'],  // Adjust as necessary
        helpLink: 'https://example.com/iot-ai-kit-help'  // Replace with actual help link
    },
    
    {
        name: 'Raspberry Pi',
        deviceId: 'raspberryPi',
        manufactor: 'raspberrypi.org',
        learnMore: 'https://www.raspberrypi.org/products/raspberry-pi-4-model-b/',
        type: DeviceType.raspberrypi,
        iconURL: raspberrypiIconURL,
        description: (
          <FormattedMessage
            defaultMessage="A low-cost, high-performance computer for learning and innovation."
            description="Description for the Raspberry Pi device"
            id="gui.device.raspberryPi.description"
          />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '115200',
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: raspberrypiConnectionIconURL,
        connectionSmallIconURL: raspberrypiConnectionSmallIconURL,
        connectingMessage: (
          <FormattedMessage
            defaultMessage="Connecting"
            description="Message to help people connect to their Raspberry Pi."
            id="gui.device.raspberryPi.connectingMessage"
          />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['realtime', 'upload'],
        programLanguage: ['python', 'java', 'c'],
        tags: ['raspberrypi'],
        helpLink: 'https://www.raspberrypi.org/help/'
    },
    {
        name: 'Raspberry Pi Pico',
        deviceId: 'arduinoRaspberryPiPico',
        manufactor: 'Raspberry Pi Foundation',
        learnMore: 'https://www.raspberrypi.com/',
        type: 'arduino',
        iconURL: raspberryPiPicoIconURL,
        description: (
            <FormattedMessage
                defaultMessage="The powerful, flexible microcontroller board."
                description="Description for the Raspberry Pi Pico device"
                id="gui.device.raspberryPiPicoIconURL.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: raspberryPiPicoConnectionIconURL,
        connectionSmallIconURL: raspberryPiPicoConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their device."
                id="gui.device.raspberryPiPicoIconURL.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://wiki.openblock.cc/general-hardware-guidelines/boards/raspberry-pi-pico'
    },
    
   
    {
        name: 'Arduino Uno',
        deviceId: 'arduinoUno',
        manufactor: 'arduino.cc',
        learnMore: 'https://store.arduino.cc/usa/arduino-uno-rev3',
        type: DeviceType.arduino,
        iconURL: arduinoUnoIconURL,
        description: (
            <FormattedMessage
                defaultMessage="A great board to get started with electronics and coding."
                description="Description for the Arduino Uno device"
                id="gui.device.arduinoUno.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: arduinoUnoConnectionIconURLL,
        connectionSmallIconURL: arduinoUnoConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their device."
                id="gui.device.arduinoUno.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['realtime', 'upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://store.arduino.cc/usa/arduino-uno-rev3'
    },
    {
        name: 'Arduino Nano',
        deviceId: 'arduinoNano',
        manufactor: 'arduino.cc',
        learnMore: 'https://store.arduino.cc/usa/arduino-nano',
        type: DeviceType.arduino,
        iconURL: arduinoNanoIconURL,
        description: (
            <FormattedMessage
                defaultMessage="The Arduino Nano is a classic small board to build your projects with."
                description="Description for the Arduino Nano device"
                id="gui.device.arduinoNano.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: arduinoNanoConnectionIconURLL,
        connectionSmallIconURL: arduinoNanoConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their device."
                id="gui.device.arduinoNano.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['realtime', 'upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://store.arduino.cc/usa/arduino-nano'
    },
    {
        name: 'Arduino Nano OLD',
        deviceId: 'arduinoNano_arduinoUno',
        manufactor: 'arduino.cc',
        learnMore: 'https://store.arduino.cc/usa/arduino-nano',
        type: DeviceType.arduino,
        iconURL: arduinoNanoIconURLOLD,
        description: (
            <FormattedMessage
                defaultMessage="The Arduino Nano is a classic small board to build your projects with."
                description="Description for the Arduino Nano device"
                id="gui.device.arduinoNano.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: arduinoNanoConnectionIconURLLOLD,
        connectionSmallIconURL: arduinoNanoConnectionSmallIconURLOLD,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their device."
                id="gui.device.arduinoNano.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['realtime', 'upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://store.arduino.cc/usa/arduino-nano'
    },
    {
        name: 'Arduino Mini',
        deviceId: 'arduinoMini_arduinoUno',
        deviceExtensionsCompatible: 'arduinoUno',
        manufactor: 'arduino.cc',
        // Discontinued board
        // learnMore: 'https://store.arduino.cc/usa/arduino/boards-modules',
        type: DeviceType.arduino,
        iconURL: arduinoMiniIconURL,
        description: (
            <FormattedMessage
                defaultMessage="The Arduino Mini a classic smaller board to build your projects with."
                description="Description for the Arduino Mini device"
                id="gui.device.arduinoMini.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: arduinoMiniConnectionIconURLL,
        connectionSmallIconURL: arduinoMiniConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their arduino."
                id="gui.device.arduino.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['realtime', 'upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino']
        // helpLink: 'https://store.arduino.cc/usa/arduino-nano'
    },
    {
        name: 'Arduino Leonardo',
        deviceId: 'arduinoLeonardo',
        manufactor: 'arduino.cc',
        learnMore: 'https://store.arduino.cc/usa/leonardo',
        type: DeviceType.arduino,
        iconURL: arduinoLeonardoIconURL,
        description: (
            <FormattedMessage
                defaultMessage="The classic Arduino board that can act as a mouse or keyboard."
                description="Description for the Arduino Leonardo device"
                id="gui.device.arduinoLeonardo.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: arduinoLeonardoConnectionIconURLL,
        connectionSmallIconURL: arduinoLeonardoConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their device."
               id="gui.device.arduinoLeonardo.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['upload'], // due to the software serilport realtim mode is unstable
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://store.arduino.cc/usa/leonardo'
    },
    {
        name: 'Arduino Mega 2560',
        deviceId: 'arduinoMega2560',
        manufactor: 'arduino.cc',
        learnMore: 'https://store.arduino.cc/usa/mega-2560-r3',
        type: DeviceType.arduino,
        iconURL: arduinoMega2560IconURL,
        description: (
            <FormattedMessage
                defaultMessage="The 8-bit board with 54 digital pins, 16 analog inputs, and 4 serial ports."
                description="Description for the Arduino Mega 2560 device"
                id="gui.device.arduinoMega2560.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '9600',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: arduinoMega2560ConnectionIconURLL,
        connectionSmallIconURL: arduinoMega2560ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their device."
                id="gui.device.arduinoMega2560.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['realtime', 'upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://store.arduino.cc/usa/mega-2560-r3'
    },
    {
        name: 'ESP32',
        deviceId: 'arduinoEsp32',
        manufactor: 'espressif',
        learnMore: 'https://www.espressif.com/',
        type: DeviceType.arduino,
        iconURL: esp32IconURL,
        description: (
            <FormattedMessage
                defaultMessage="Wi-Fi & Bluetooth control board with rich functions."
                description="Description for the esp32 device"
                id="gui.device.esp32.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '115200',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: esp32ConnectionIconURLL,
        connectionSmallIconURL: esp32ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their esp32."
                id="gui.device.esp32.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://docs.espressif.com/projects/esp-idf/zh_CN/latest/esp32/hw-reference/esp32/get-started-devkitc.html'
    },
    {
        name: 'ESP8266',
        deviceId: 'arduinoEsp8266',
        manufactor: 'espressif',
        learnMore: 'https://www.espressif.com/',
        type: DeviceType.arduino,
        iconURL: esp8266IconURL,
        description: (
            <FormattedMessage
                defaultMessage="Low-cost Wi-Fi SOC control board."
                description="Description for the esp8266 device"
                id="gui.device.arduinoEsp8266.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '76800',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: esp8266ConnectionIconURL,
        connectionSmallIconURL: esp8266ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their device."
                id="gui.device.esp8266.connectingMessage"
            />
        ),
        // baseToolBoxXml: arduinoBaseToolBox,
        deviceExtensionsCompatible: 'arduinoEsp8266',
        programMode: ['upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://arduino-esp8266.readthedocs.io/en/3.0.0/index.html'
    },
    {
        name: 'Micro:bit',
        deviceId: 'microbit',
        manufactor: 'microbit.org',
        learnMore: 'https://microbit.org/',
        type: DeviceType.microbit,
        iconURL: microbitIconURL,
        description: (
            <FormattedMessage
                defaultMessage="The pocket-sized computer transforming digital skills learning."
                description="Description for the 'micro:bit' device"
                id="gui.device.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '115200',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURLL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their microbit."
                id="gui.device.microbit.connectingMessage"
            />
        ),
        baseToolBoxXml: microbitBaseToolBox,
        programMode: ['upload'],
        programLanguage: ['block', 'python'],
        tags: ['microPython'],
        helpLink: 'https://microbit.org/get-started/first-steps/introduction/'
    },
    {
        name: 'Micro:bit V2',
        deviceId: 'microbitV2',
        manufactor: 'microbit.org',
        learnMore: 'https://microbit.org/',
        type: DeviceType.microbit,
        iconURL: microbitV2IconURL,
        description: (
            <FormattedMessage
                defaultMessage="Upgraded processor, built-In speaker and microphone, touch sensitive logo."
                description="Description for the 'micro:bit V2' device"
                id="gui.device.microbitV2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '115200',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitV2ConnectionIconURLL,
        connectionSmallIconURL: microbitV2ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their microbit."
                id="gui.device.microbit.connectingMessage"
            />
        ),
        baseToolBoxXml: microbitBaseToolBox,
        programMode: ['upload'],
        programLanguage: ['block', 'python'],
        tags: ['microPython'],
        helpLink: 'https://microbit.org/get-started/first-steps/introduction/'
    },
    {
        name: 'Makey Makey',
        deviceId: 'makeyMakey',
        manufactor: 'makeymakey.com',
        learnMore: 'https://makeymakey.com/',
        type: DeviceType.arduino,
        iconURL: makeymakeyIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything into a key."
                description="Description for the Makey Makey device"
                id="gui.device.makeymakey.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        serialportRequired: true,
        defaultBaudRate: '115200',
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: makeymakeyConnectionIconURL,
        connectionSmallIconURL: makeymakeyConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their arduino."
                id="gui.device.arduino.connectingMessage"
            />
        ),
        baseToolBoxXml: arduinoBaseToolBox,
        programMode: ['upload'],
        programLanguage: ['block', 'c', 'cpp'],
        tags: ['arduino'],
        helpLink: 'https://makeymakey.com'
    },
    /**
     * For those parent devices that exist in VM but are not displayed in GUI
     */
    {
        deviceId: 'arduinoUnoUltra',
        type: 'arduino',
        featured: true,
        disabled: false,
        hide: true,
        baseToolBoxXml: arduinoBaseToolBox
    },
    {
        deviceId: 'arduinoSE',
        type: DeviceType.arduino,
        featured: true,
        disabled: false,
        hide: true
    },
    {
        deviceId: 'arduinoEsp8266',
        type: DeviceType.arduino,
        featured: true,
        disabled: false,
        hide: true
    }
];

/**
 * Unique event blocks under different programming frameworks.
 */
const eventBlock = {
    [DeviceType.arduino]: '<block type="event_whenarduinobegin"/>',
    [DeviceType.microPython]: '<block type="event_whenmicropythonbegin"/>',
    [DeviceType.microbit]: `<block type="event_whenmicrobitbegin"/>
                                <block type="event_whenmicrobitbuttonpressed"/>
                                <block type="event_whenmicrobitpinbeingtouched"/>
                                <block type="event_whenmicrobitgesture"/>`
};

/**
 * To get real device id. eg: the third party id like ironKit_arduinoUno.
 * @param {string} deviceId - the id of the device.
 * @return {string} deviceId - the real device id.
 */
const analysisRealDeviceId = deviceId => {
    if (deviceId){
        // if the id contain '_' use the string afer the '_'.
        if (deviceId.indexOf('_') !== -1) {
            deviceId = deviceId.split('_')[1];
        }
    }
    return deviceId;
};

/**
 * Make device data from the input data. If it is a buid-in device, return the buid-in
 * data. If it is a third party device, find it's parent device, and overwrite its attributes
 * with the input data.
 * @param {string} data - the data of devices.
 * @return {string} fullData - processed data of devices.
 */
const makeDeviceLibrary = (deviceList = null) => {
    let regeneratedDeviceData = [];

    if (deviceList) {
        if (deviceList[0].isOrdered) { // External resources customize the device arrangement
            regeneratedDeviceData.push(deviceData[0]);
        } else {
            deviceList = deviceData.concat(deviceList);
        }

        deviceList.forEach(dev => {
            const realDeviceId = analysisRealDeviceId(dev.deviceId);
            const matchedDevice = deviceData.find(item => realDeviceId === item.deviceId);
            if (matchedDevice) {
                if (realDeviceId !== dev.deviceId) {
                    return regeneratedDeviceData.push(defaults({}, dev, {hide: false}, matchedDevice));
                }
                return regeneratedDeviceData.push(matchedDevice);
            }
            log.warn('Unable to find the corresponding built-in device:', dev.deviceId);
            return;
        });
    } else {
        regeneratedDeviceData = deviceData;
    }

    return regeneratedDeviceData;
};

export {
    deviceData as default,
    eventBlock,
    makeDeviceLibrary
};

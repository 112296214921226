import React, { useState, useEffect } from 'react';
import PreviousMatchIcon from './img/PreviousmatchIcon.svg';
import NextMatchIcon from './img/NextmatchIcon.svg';
import CloseIcon from './img/CloseIcon.svg';

const SearchBox = ({ text = '' , onClose  }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedText, setHighlightedText] = useState([]);

  useEffect(() => {
    // Initialize highlightedText with plain text if no search is active
    setHighlightedText([text]);
  }, [text]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      performSearch(searchTerm);
    }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const performSearch = (term) => {
    if (!term) {
      setHighlightedText([text]);
      return;
    }

    const regex = new RegExp(`(${term})`, 'gi');
    const newHighlightedText = text.split(regex).map((part, index) =>
      regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
    );

    setHighlightedText(newHighlightedText);
  };

  return (
    <div  style={{
            display:'flex',
      backgroundColor:'rgb(220 227 242)'
            }}>
      <div className="search-box" style={{
        margin:'10px'
      }}>
        <input
          type="text"
          placeholder="Find"
          className="search-input"
          value={searchTerm}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          style={{ height:'25px'}}
        />
        </div>
        <div className="button" disabled 
        title="Previous match"
         style={{
                  margin:'10px'
                }}>
          <img
            className="search-box-icon"
            src={PreviousMatchIcon}
            alt="Previous match"
            style={{ rotate:'180deg' ,height:'15px', width:'15px' }}
          />
        </div>
        <div className="button" disabled title="Next match" 
        style={{
                  margin:'10px'
                }}>
          <img
            className="search-box-icon"
            src={NextMatchIcon}
            alt="Next match"
            style={{height:'15px', width:'15px'}}
          />
        </div>
        <div className="button" onClick={onClose} title="Close"
        style={{
                  margin:'10px'
                }}>
          <img
            className="search-box-icon"
            src={CloseIcon}
            alt="Close"
            style={{height:'15px', width:'15px'}}
          />
        </div>
      <div className="text-output">
        {highlightedText.map((part, index) => (
          <React.Fragment key={index}>{part}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SearchBox;
const spriteCode = `
    import math
    import random 
    import time

    class Sprite:
        def __init__(self, name):
            self.name = name
            self.x = 0
            self.y = 0
            self.angle = 0
            self.size = 0
            self.direction = 0
            self.number = 0
            self.secs = 0
            self.costume_name = None
            self.backdrop_name = None

        def move(self, x):
            self.x += x
            return f"Moved to ({self.x})"

        def right(self, angle):
            self.angle += angle
            return f"Turned to {self.angle} degrees"

        def left(self, angle):
            self.angle -= angle
            return f"Turned to {self.angle} degrees"

        def gotoxy(self, x, y):
            self.x = x
            self.y = y
            return f"Moved to ({self.x}, {self.y})"

        def goto(self):
            self.x = random.randint(0, 260)
            self.y = random.randint(0, 260)
            return f"Moved to ({self.x}, {self.y})"

        def setx(self, x):
            self.x = x 
            return f"Set X position ({self.x})"

        def sety(self, y):
            self.y = y
            return f"Set Y position ({self.y})"

        def changex(self, distance):
            self.x += distance * math.cos(math.radians(self.angle))
            return f"Change X position ({self.x})"

        def changey(self, distance):
            self.y += distance * math.sin(math.radians(self.angle))
            return f"Change X position ({self.y})"

        def setdirection(self, angle):
            self.angle = angle
            return f"Turned to {self.angle} degrees"

        def setsize(self, size):
            self.size = size
            return f"Set size to {self.size}"

        def changesize(self, size):
            self.size = size
            return f"Set size to {self.size}"

        def say(self, message):
            print(f"{message}")
            return message

        def sayforsecs(self, message, secs):
            self.secs = secs
            return message

        def show(self):
            return "Show sprite"

        def hide(self):
            return "Hide sprite"

        def think(self, message):
            return message

        def thinkforsecs(self, message, secs):
            self.secs = secs
            return message

        def input(self, question):
            return question

        def switchcostume(self, costume_name):
            new_costume_name = costume_name
            self.costume_name = new_costume_name
            return

        def nextcostume(self):
            return

        def switchbackdrop(self, backdrop_name):
            new_backdrop_name = backdrop_name
            self.backdrop_name = new_backdrop_name
            return

        def nextbackdrop(self):
            return

        def iskeypresed(self, key_name):
            self.key_name = key_name
            return f"pressed a {key_name} key"

        def setrotationstyle(self, style_name):
            self.style_name = style_name
            return f"rotate a {style_name} side"

        def bounceonedge(self):
            return f"sprite bounce on edge"

        def X(self):
            return f"sprite x position"

        def Y(self):
            return f"sprite y position"

        def gotolayer(self, layer_name):
            self.layer_name = layer_name
            return f"sprite set a layer"

        def changelayer(self, layer_name, layer_number):
            self.layer_name = layer_name
            self.layer_number = layer_number
            return f"sprite layer has change according to a you change"

        def changeeffect(self, effect_value, effect_name):
            self.effect_name  = effect_name 
            self.effect_value  = effect_value 
            return f"sprite effect change"

        def seteffect(self, effect_value, effect_name):
            self.effect_name  = effect_name 
            self.effect_value  = effect_value 
            return f"sprite effect set"

        def cleareffects(self):
            return f"clear all sprite effect"

        def answer(self):
            return "Show answer"

    sprite = Sprite('name')

`;

export default spriteCode;

// const spriteCode = `
//     import math
//     import random 
//     import time
//     import json

//     json_file_path = '/lib/libraries/sprites.json'

//     with open(json_file_path, 'r') as f:
//     sprites = json.load(f)
//     sprite_names = sprites["name"]

//     class Sprite:
//         def __init__(self , name):
//             self.name = name
//             self.x = 0
//             self.y = 0
//             self.angle = 0
//             self.size = 0
//             self.direction = 0
//             self.number = 0
//             self.secs = 0
//             self.costume_name = None
//             self.backdrop_name = None

//         def move(self, x):
//             self.x += x
//             print(f"Moved to ({self.x})")
//             return f"Moved to ({self.x})"

//         def right(self, angle):
//             self.angle += angle
//             return f"Turned to {self.angle} degrees"

//         def left(self, angle):
//             self.angle -= angle
//             return f"Turned to {self.angle} degrees"

//         def gotoxy(self, x, y):
//             self.x = x
//             self.y = y
//             return f"Moved to ({self.x}, {self.y})"

//         def goto(self):
//             self.x = random.randint(0, 260)  # assuming the canvas is 260x260
//             self.y = random.randint(0, 260)
//             return f"Moved to ({self.x}, {self.y})"

//         def setx(self, x):
//             self.x = x 
//             return f"Set X position ({self.x})"

//         def sety(self, y):
//             self.y = y
//             return f"Set Y position ({self.y})"

//         def changex(self, distance):
//             self.x += distance * math.cos(math.radians(self.angle))
//             return f"Change X position ({self.x})"

//         def changey(self, distance):
//             self.y += distance * math.sin(math.radians(self.angle))
//             return f"Change X position ({self.y})"

//         def setdirection(self, angle):
//             self.angle = angle
//             return f"Turned to {self.angle} degrees"

//         def setsize(self, size):
//             self.size = size
//             return f"Set size to {self.size}"

//         def changesize(self, size):
//             self.size = size
//             return f"Set size to {self.size}"

//         def say(self, message):
//             for word in message.split():
//                 if hasattr(self, word):
//                     message = message.replace(word, str(getattr(self, word)))
//             print(f"{message}")
//             return message

//         def sayforsecs(self, message, secs):
//             self.secs = secs
//             return message

//         def show(self):
//             return "Show sprite"

//         def hide(self):
//             return "Hide sprite"

//         def think(self, message):
//             return message

//         def thinkforsecs(self, message, secs):
//             self.secs = secs
//             return message

//         def input(self, question):
//             return question

//         def switchcostume(self, costume_name):
//             new_costume_name = costume_name
//             self.costume_name = new_costume_name
//             return f"Switched to costume '{new_costume_name}'"

//         def nextcostume(self):
//             return f"Switching to a next costume"

//         def switchbackdrop(self, backdrop_name):
//             new_backdrop_name = backdrop_name
//             self.backdrop_name = new_backdrop_name
//             return f"Switched to backdrop '{new_backdrop_name}'"

//         def nextbackdrop(self):
//             return f"Switching to a next costume"

//         def iskeypresed(self, key_name):
//             self.key_name = key_name
//             return f"pressed a {key_name} key"

//         def setrotationstyle(self, style_name):
//             self.style_name = style_name
//             return f"rotate a {style_name} side"

//         def bounceonedge(self):
//             return f"sprite bounce on edge"

//         def X(self):
//             return f"sprite x position"

//         def Y(self):
//             return f"sprite y position"

//         def gotolayer(self, layer_name):
//             self.layer_name = layer_name
//             return f"sprite set a layer"

//         def changelayer(self, layer_name, layer_number):
//             self.layer_name = layer_name
//             self.layer_number = layer_number
//             return f"sprite layer has change according to a you change"

//         def changeeffect(self, effect_value, effect_name):
//             self.effect_name  = effect_name 
//             self.effect_value  = effect_value 
//             return f"sprite effect change"

//         def seteffect(self, effect_value, effect_name):
//             self.effect_name  = effect_name 
//             self.effect_value  = effect_value 
//             return f"sprite effect set"

//         def cleareffects(self):
//             return f"clear all sprite effect"

//         def answer(self):
//             return "Show answer"

//     sprites = []


//     for name in sprite_names:
//     sprite = Sprite(name)
//     sprites.append(sprite)
// `;

// export default spriteCode;

// // sprite = Sprite()
// import PropTypes from 'prop-types';
// import React from 'react';
// import bindAll from 'lodash.bindall';
// import ConnectionModalComponent, {PHASES} from '../components/connection-modal/connection-modal.jsx';
// import VM from 'blockzie-vm';
// import analytics from '../lib/analytics';
// import extensionData from '../lib/libraries/extensions/index.jsx';
// import {connect} from 'react-redux';
// import {closeConnectionModal} from '../reducers/modals';
// import {setConnectionModalPeripheralName, setListAll} from '../reducers/connection-modal';

// class ConnectionModal extends React.Component {
//     constructor (props) {
//         super(props);
//         bindAll(this, [
//             'handleScanning',
//             'handleCancel',
//             'handleConnected',
//             'handleConnecting',
//             'handleDisconnect',
//             'handleError',
//             'handleHelp',
//             'connectBluetoothDevice'
//         ]);
//         this.state = {
//             extension: extensionData.find(ext => ext.extensionId === props.deviceId) ||
//                 this.props.deviceData.find(ext => ext.deviceId === props.deviceId),
//             phase: props.vm.getPeripheralIsConnected(props.deviceId) ? PHASES.connected : PHASES.scanning,
//             peripheralName: null
//         };
//     }

//     componentDidMount () {
//         this.props.vm.on('PERIPHERAL_CONNECTED', this.handleConnected);
//         this.props.vm.on('PERIPHERAL_REQUEST_ERROR', this.handleError);
//     }

//     componentWillUnmount () {
//         this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
//         this.props.vm.removeListener('PERIPHERAL_REQUEST_ERROR', this.handleError);
//     }

//     async handleScanning () {
//         this.setState({phase: PHASES.scanning});

//         try {
//             await this.connectBluetoothDevice();
//         } catch (error) {
//             this.handleError(error);
//         }
//     }

//     async connectBluetoothDevice () {
//         try {
//             const device = await navigator.bluetooth.requestDevice({
//                 acceptAllDevices: true,
//                 optionalServices: ['battery_service', 'generic_access']
//             });

//             console.log('Bluetooth Device Selected:', device);
//             this.handleConnecting(device.id, device.name);
//         } catch (error) {
//             console.error('Bluetooth Connection Error:', error);
//             this.handleError();
//         }
//     }

//     // handleConnecting (peripheralId, peripheralName) {
//     //     console.log('Attempting to Connect to Peripheral:', peripheralId, peripheralName);
//     //     if (this.props.isRealtimeMode) {
//     //         this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
//     //     } else {
//     //         this.props.vm.connectPeripheral(this.props.deviceId, peripheralId, parseInt(this.props.baudrate, 10));
//     //     }
//     //     this.setState({
//     //         phase: PHASES.connecting,
//     //         peripheralName: peripheralName
//     //     });
        
//     //     analytics.event({
//     //         category: 'devices',
//     //         action: 'connecting',
//     //         label: this.props.deviceId
//     //     });

//     //     this.props.onConnected(this.state.peripheralName);
//     // }
    
//     async handleConnecting(peripheralId, peripheralName) {
//         console.log('Attempting to Connect to Peripheral:', peripheralId, peripheralName);
    
//         // Set a timeout for 10 seconds
//         const connectionTimeout = setTimeout(() => {
//             if (this.state.phase === PHASES.connecting) {
//                 console.error('Connection timed out. Please retry.');
//                 this.setState({ phase: PHASES.error });
//             }
//         }, 10000); // 10 seconds
    
//         try {
//             // Set phase to 'connecting' before attempting connection
//             this.setState({
//                 phase: PHASES.connecting,
//                 peripheralName: peripheralName // Store the name for display
//             });
    
//             let connectPromise;
    
//             // Check if the connection method supports async/await
//             if (this.props.isRealtimeMode) {
//                 connectPromise = this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
//             } else {
//                 connectPromise = this.props.vm.connectPeripheral(
//                     this.props.deviceId,
//                     peripheralId,
//                     parseInt(this.props.baudrate, 10)
//                 );
//             }
    
//             // Await the connection attempt
//             await connectPromise;
    
//             // Clear the timeout on successful connection
//             clearTimeout(connectionTimeout);
    
//             // Update phase to 'connected'
//             this.handleConnected();
//             console.log('Connected successfully.');
//         } catch (error) {
//             console.error('Connection Error:', error);
//             clearTimeout(connectionTimeout);
//             this.setState({ phase: PHASES.error });
//             this.handleError(error);
//         }
    
//         // Log the analytics event
//         analytics.event({
//             category: 'devices',
//             action: 'connecting',
//             label: this.props.deviceId
//         });
//     }

//     handleDisconnect () {
//         try {
//             this.props.vm.disconnectPeripheral(this.props.deviceId);
//         } finally {
//             this.props.onCancel();
//         }
//     }

//     handleCancel () {
//         try {
//             if (!this.props.vm.getPeripheralIsConnected(this.props.deviceId)) {
//                 this.props.vm.disconnectPeripheral(this.props.deviceId);
//             }
//         } finally {
//             this.props.onCancel();
//         }
//     }

//     handleError (error) {
//         console.error('Connection Error:', error);
//         if (this.state.phase === PHASES.scanning || this.state.phase === PHASES.unavailable) {
//             this.setState({
//                 phase: PHASES.unavailable
//             });
//         } else {
//             this.setState({
//                 phase: PHASES.error
//             });
//             analytics.event({
//                 category: 'devices',
//                 action: 'connecting error',
//                 label: this.props.deviceId
//             });
//         }
//     }
    
//     handleConnected() {
//         console.log('Device successfully connected.');
//         const peripheralName = this.state.peripheralName; // Save peripheralName
    
//         this.setState({ phase: PHASES.connected });
    
//         analytics.event({
//             category: 'devices',
//             action: 'connected',
//             label: this.props.deviceId
//         });
    
//         // Dispatch action to Redux to save the name
//         if (peripheralName) {
//             this.props.onConnected(peripheralName);
//         }
//     }
    

//     handleHelp () {
//         window.open(this.state.extension.helpLink, '_blank');
//         analytics.event({
//             category: 'devices',
//             action: 'device help',
//             label: this.props.deviceId
//         });
//     }

//     render () {
//         return (
//             <ConnectionModalComponent
//                 connectingMessage={this.state.extension && this.state.extension.connectingMessage}
//                 connectionIconURL={this.state.extension && this.state.extension.connectionIconURL}
//                 connectionSmallIconURL={this.state.extension && this.state.extension.connectionSmallIconURL}
//                 isSerialport={this.state.extension && this.state.extension.serialportRequired}
//                 isListAll={this.props.isListAll}
//                 connectionTipIconURL={this.state.extension && this.state.extension.connectionTipIconURL}
//                 extensionId={this.props.deviceId}
//                 name={this.state.extension && this.state.extension.name}
//                 phase={this.state.phase}
//                 title={this.props.deviceId}
//                 useAutoScan={this.state.extension && this.state.extension.useAutoScan}
//                 vm={this.props.vm}
//                 onCancel={this.handleCancel}
//                 onConnected={this.handleConnected}
//                 onConnecting={this.handleConnecting}
//                 onClickListAll={this.props.onClickListAll}
//                 onDisconnect={this.handleDisconnect}
//                 onHelp={this.handleHelp}
//                 onScanning={this.handleScanning}
//             />
//         );
//     }
// }

// ConnectionModal.propTypes = {
//     baudrate: PropTypes.string.isRequired,
//     deviceId: PropTypes.string.isRequired,
//     deviceData: PropTypes.instanceOf(Array).isRequired,
//     isRealtimeMode: PropTypes.bool,
//     isListAll: PropTypes.bool,
//     onCancel: PropTypes.func.isRequired,
//     onConnected: PropTypes.func.isRequired,
//     onClickListAll: PropTypes.func.isRequired,
//     vm: PropTypes.instanceOf(VM).isRequired
// };

// const mapStateToProps = state => ({
//     baudrate: state.scratchGui.hardwareConsole.baudrate,
//     deviceData: state.scratchGui.deviceData.deviceData,
//     deviceId: state.scratchGui.device.deviceId,
//     isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
//     isListAll: state.scratchGui.connectionModal.isListAll,
//     peripheralName: state.scratchGui.connectionModal.peripheralName
// });

// const mapDispatchToProps = dispatch => ({
//     onCancel: () => {
//         dispatch(closeConnectionModal());
//     },
//     onConnected: peripheralName => {
//         dispatch(setConnectionModalPeripheralName(peripheralName));
//     },
//     onClickListAll: state => {
//         dispatch(setListAll(state));
//     }
// });

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(ConnectionModal);


import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ConnectionModalComponent, { PHASES } from '../components/connection-modal/connection-modal.jsx';
import VM from 'blockzie-vm';
import analytics from '../lib/analytics';
import extensionData from '../lib/libraries/extensions/index.jsx';
import { connect } from 'react-redux';
import { closeConnectionModal } from '../reducers/modals';
import { setConnectionModalPeripheralName, setListAll } from '../reducers/connection-modal';

class ConnectionModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleScanning',
            'handleCancel',
            'handleConnected',
            'handleConnecting',
            'handleDisconnect',
            'handleError',
            'handleHelp',
            'connectBluetoothDevice'
        ]);
        this.state = {
            extension: extensionData.find(ext => ext.extensionId === props.deviceId) ||
                this.props.deviceData.find(ext => ext.deviceId === props.deviceId),
            phase: props.vm.getPeripheralIsConnected(props.deviceId) ? PHASES.connected : PHASES.scanning,
            peripheralName: null
        };
    }

    componentDidMount() {
        this.props.vm.on('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.on('PERIPHERAL_REQUEST_ERROR', this.handleError);
    }

    componentWillUnmount() {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.removeListener('PERIPHERAL_REQUEST_ERROR', this.handleError);
    }

    async handleScanning() {
        this.setState({ phase: PHASES.scanning });

        try {
            await this.connectBluetoothDevice();
        } catch (error) {
            this.handleError(error);
        }
    }

    async connectBluetoothDevice() {
        try {
            const device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: true,
                optionalServices: ['battery_service', 'generic_access']
            });

            console.log('Bluetooth Device Selected:', device);
            this.handleConnecting(device.id, device.name);
        } catch (error) {
            console.error('Bluetooth Connection Error:', error);
            this.handleError();
        }
    }

    handleConnecting (peripheralId, peripheralName) {
        if (this.props.isRealtimeMode) {
            this.props.vm.connectPeripheral(this.props.deviceId, peripheralId);
        } else {
            this.props.vm.connectPeripheral(this.props.deviceId, peripheralId, parseInt(this.props.baudrate, 10));
        }
        this.setState({
            phase: PHASES.connecting,
            peripheralName: peripheralName
        });
        
        analytics.event({
            category: 'devices',
            action: 'connecting',
            label: this.props.deviceId
        });

    }
    

    handleDisconnect() {
        try {
            this.props.vm.disconnectPeripheral(this.props.deviceId);
        } finally {
            this.props.onCancel();
        }
    }

    handleCancel() {
        try {
            if (!this.props.vm.getPeripheralIsConnected(this.props.deviceId)) {
                this.props.vm.disconnectPeripheral(this.props.deviceId);
            }
        } finally {
            this.props.onCancel();
        }
    }

    handleError(error) {
        console.error('Connection Error:', error);
        if (this.state.phase === PHASES.scanning || this.state.phase === PHASES.unavailable) {
            this.setState({
                phase: PHASES.unavailable
            });
        } else {
            this.setState({
                phase: PHASES.error
            });
            analytics.event({
                category: 'devices',
                action: 'connecting error',
                label: this.props.deviceId
            });
        }
    }
    

    handleConnected() {
        this.setState({ phase: PHASES.connected });

        analytics.event({
            category: 'devices',
            action: 'connected',
            label: this.props.deviceId
        });

        this.props.onConnected(this.state.peripheralName);
    }

    handleHelp() {
        window.open(this.state.extension.helpLink, '_blank');
        analytics.event({
            category: 'devices',
            action: 'device help',
            label: this.props.deviceId
        });
    }

    render() {
        return (
            <ConnectionModalComponent
                connectingMessage={this.state.extension && this.state.extension.connectingMessage}
                connectionIconURL={this.state.extension && this.state.extension.connectionIconURL}
                connectionSmallIconURL={this.state.extension && this.state.extension.connectionSmallIconURL}
                isSerialport={this.state.extension && this.state.extension.serialportRequired}
                isListAll={this.props.isListAll}
                connectionTipIconURL={this.state.extension && this.state.extension.connectionTipIconURL}
                extensionId={this.props.deviceId}
                name={this.state.extension && this.state.extension.name}
                phase={this.state.phase}
                title={this.props.deviceId}
                useAutoScan={this.state.extension && this.state.extension.useAutoScan}
                vm={this.props.vm}
                onCancel={this.handleCancel}
                onConnected={this.handleConnected}
                onConnecting={this.handleConnecting}
                onClickListAll={this.props.onClickListAll}
                onDisconnect={this.handleDisconnect}
                onHelp={this.handleHelp}
                onScanning={this.handleScanning}
            />
        );
    }
}

ConnectionModal.propTypes = {
    baudrate: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired,
    deviceData: PropTypes.instanceOf(Array).isRequired,
    isRealtimeMode: PropTypes.bool,
    isListAll: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConnected: PropTypes.func.isRequired,
    onClickListAll: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    baudrate: state.scratchGui.hardwareConsole.baudrate,
    deviceData: state.scratchGui.deviceData.deviceData,
    deviceId: state.scratchGui.device.deviceId,
    isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
    isListAll: state.scratchGui.connectionModal.isListAll
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeConnectionModal());
    },
    onConnected: peripheralName => {
        dispatch(setConnectionModalPeripheralName(peripheralName));
    },
    onClickListAll: state => {
        dispatch(setListAll(state));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionModal);
